.marker-cluster-small {
	background-color: rgba($c_main, 0.6);
}

.marker-cluster-small div {
	background-color: rgba($c_main, 0.6);
}

.marker-cluster-medium {
	background-color: rgba($c_main, 0.6);
}

.marker-cluster-medium div {
	background-color: rgba($c_main, 0.6);
}

.marker-cluster-large {
	background-color: rgba($c_main, 0.6);
}

.marker-cluster-large div {
	background-color: rgba($c_main, 0.6);
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
	background-color: $c_main;
}

.leaflet-oldie .marker-cluster-small div {
	background-color: $c_main;
}

.leaflet-oldie .marker-cluster-medium {
	background-color: $c_main;
}

.leaflet-oldie .marker-cluster-medium div {
	background-color: $c_main;
}

.leaflet-oldie .marker-cluster-large {
	background-color: $c_main;
}

.leaflet-oldie .marker-cluster-large div {
	background-color: $c_main;
}

.marker-cluster {
	background-clip: padding-box;
	border-radius: 20px;
}

.marker-cluster div {
	width: 30px;
	height: 30px;
	margin-left: 5px;
	margin-top: 5px;

	text-align: center;
	border-radius: 15px;
	color: #fff;
	font-size: 12px;
	font-weight: 700;
	//font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.marker-cluster span {
	line-height: 30px;
}


// style custom
.leaflet-top.leaflet-left,
.leaflet-top.leaflet-right {
	.leaflet-control-zoom {
		margin: 0;
		border: none;

		a {
			box-sizing: border-box;
			border: 1px solid $c_main;
			background-color: $c_main;
			border-radius: 0;
			width: 35px;
			height: 35px;
			color: $c_white;
			font-family: $f_txt;
			font-weight: 400;
			text-decoration: none !important;

			&:last-child {
				margin-top: 1px;
			}

			@include can-hover {
				background-color: lighten($c_main, 5%);
				border-color: lighten($c_main, 5%);
			}
		}
	}
}

.leaflet-touch .leaflet-bar a {
	line-height: 35px;
}