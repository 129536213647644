.single-sit {
	.barre-navigation {
		.btn-reserver {
			background: white;
			color: $c_txt;
			border: 1px solid $c_main;

			#widget-panier .entete {
				background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%231b2533'%3E%3Cpath d='M87.08 28.327H74.4v-.32c0-11.2-9.08-20.28-20.279-20.28H45.88c-11.2 0-20.28 9.08-20.28 20.28v.32H12.92A7.92 7.92 0 005 36.247v32.96c0 13.476 10.924 24.4 24.4 24.4h41.201C84.076 93.607 95 82.683 95 69.207v-32.96a7.92 7.92 0 00-7.92-7.92zm-53.88-.32c0-7.003 5.677-12.68 12.681-12.68h8.241c7.002 0 12.68 5.677 12.68 12.68v.32H33.2v-.32zm54.2 41.2c0 9.278-7.521 16.801-16.799 16.801H29.4c-9.279 0-16.801-7.522-16.801-16.801v-32.96a.32.32 0 01.32-.32H25.6v4.44a3.8 3.8 0 107.599 0v-4.44H66.8v4.44a3.8 3.8 0 107.6 0v-4.44h12.68a.32.32 0 01.32.32v32.96z'/%3E%3C/svg%3E");
			}

			&:hover {
				background: $c_main;
				color: white;

				#widget-panier .entete {
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23fff'%3E%3Cpath d='M87.08 28.327H74.4v-.32c0-11.2-9.08-20.28-20.279-20.28H45.88c-11.2 0-20.28 9.08-20.28 20.28v.32H12.92A7.92 7.92 0 005 36.247v32.96c0 13.476 10.924 24.4 24.4 24.4h41.201C84.076 93.607 95 82.683 95 69.207v-32.96a7.92 7.92 0 00-7.92-7.92zm-53.88-.32c0-7.003 5.677-12.68 12.681-12.68h8.241c7.002 0 12.68 5.677 12.68 12.68v.32H33.2v-.32zm54.2 41.2c0 9.278-7.521 16.801-16.799 16.801H29.4c-9.279 0-16.801-7.522-16.801-16.801v-32.96a.32.32 0 01.32-.32H25.6v4.44a3.8 3.8 0 107.599 0v-4.44H66.8v4.44a3.8 3.8 0 107.6 0v-4.44h12.68a.32.32 0 01.32.32v32.96z'/%3E%3C/svg%3E");
				}
			}
		}
	}

	// header de la single SIT
	.header-sit {
		padding-top: calc(60px + var(--header-height));

		@include mobile {
			padding-top: calc(40px + var(--header-height));
		}

		.header-sit-inner {
			display: flex;

			@include tablet-portrait {
				flex-direction: column;
			}
		}

		.header-sit-inner {
			.text-styles {
				padding-right: 40px;
				@include tablet-portrait {
					padding-right: 0;
				}
			}

			.container {
				margin-right: 10px;
			}
		}

		.text-styles {
			@include tablet-portrait {
				margin-bottom: 40px;
			}
		}

		.container {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			line-height: 1;
		}

		.surtitre {
			margin-top: 0;
			margin-bottom: 0;
		}

		.classement {
			color: $c_main;
			margin-left: 8px;
		}

		.price {
			color: $c_main;
			margin-top: 40px;

			.light-text {
				font-size: 14px;
			}

			.second-text {
				@include ft($f_hx, 36px, 300, "", 26px);
				@include mobile {
					span {
						display: none;
					}
				}
			}
		}

		.link-agenda-month {
			margin-top: 30px;
			@include tablet-portrait {
				display: none;
			}
		}

		.meta-sit {
			.meta {
				background-color: $c_white;
				border: 1px solid $c_main;
				color: $c_main;
				font-size: 12px;
			}
		}

		.date-event {
			color: $c_main;
			font-weight: 500;
		}

		//  Infos sous l'image
		// -----------------------------------------------
		.cover-bottom {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			padding: 40px 0 35px;

			@include mobile {
				padding: 30px 0 10px 0;
			}

			.breadcrumbs {
				margin: 0;
			}

			@include min-mobile {
				.breadcrumbs {
					margin-left: auto;
				}
			}

			.cover-bottom__info {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
			}
		}

		.meta-wrapper {
			margin-top: 5px; // pour aligner les meta à la localisation parce qu'il y a un padding de 5px par default
			.meta {
				&:last-of-type {
					margin-right: 25px;
				}

				a {
					text-decoration: none;
				}

				@include can-hover {
					a {
						color: rgba($c_txt, 0.80);
					}
				}
			}
		}
	}


	/* Styles spéciales pour la couverture */

	/* Début des règles spéciales pour améliorer l'affichage de la barre dockée */

	> .content {
		position: relative;
		margin-top: 41px;
		overflow: hidden; //ovh pour le bloc relation dans la page, pas le temps de changer du HTML ou quoi que ce soit d'autre
		@include tablet-landscape {
			top: auto;
		}
	}

	& + .footer {
		padding-bottom: 100px;
	}


	.bloc + .bloc {
		margin-top: 80px;
		@include mobile {
			margin-top: 60px;
		}
	}

	/* Fin des règles spéciales */


	// Styles pour la gestion du view more / view less au niveau du SIT
	// ==========================================================================
	[data-view-more-trigger] {
		display: none;
	}

	.view-more-active {
		&.is-expanded {
			[data-view-more-content] {
				max-height: none;

				&:before {
					opacity: 0;
					visibility: hidden;
				}
			}

			[data-view-more-trigger] {
				&:before {
					@include icomoon($icon-minus);
					transform: rotate(180deg);
				}
			}
		}

		[data-view-more-content] {
			position: relative;
			max-height: 220px;
			overflow-y: hidden;
			@include addTransition();

			&:before {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				z-index: 1;
				display: block;
				width: 100%;
				height: 60px;
				background: linear-gradient(to top, rgba($c_white, 1), rgba($c_white, 0));
				opacity: 1;
			}
		}

		[data-view-more-trigger] {
			display: flex;
			margin-left: auto;
		}
	}


	/* Styles générales à tous les types de bloc sit */
	.meta-wrapper {
		margin: 0;

		.meta {
			[class^="icon-"], [class*=" icon-"] {
				& + span {
					margin-left: 10px;
					margin-bottom: 10px;
				}
			}
		}
	}

	.small-title {
		display: block;
		@include ft($f_hx, 30px, 700, $c_txt, 28px);
		@include mobile {
			font-size: 24px;
		}

		& + span {
			display: block;
			margin-top: 10px;
		}
	}

	.texte-supplementaire {
		font-style: italic;
	}

	/* Liste verticale avec items séparés par des lignes */
	.list-rows {
		.item-row {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 20px 0;
			font-size: 14px;
			line-height: 1.42;

			& + .item-row {
				border-top: 1px solid $c_main;
			}

			.item-row__label {
				flex-grow: 1;

				> strong {
					@include ft($f_hx, 18px, 700, "", 1);
					@include mobile {
						font-size: 16px;
					}
				}

				> span {
					@include ft($f_txt, 16px, 400, "", 1);

					@include mobile {
						font-size: 14px;
					}
				}

				> strong + span {
					margin-top: 6px;
				}
			}

			.item-row__value {
				@include ft($f_hx, 18px, 700, "", 1);
				text-align: right;
				margin-left: 20px;

				@include mobile {
					font-size: 16px;
				}
			}
		}
		
		// item imbriqué
		.item-row .item-row {
			padding: 6px 0;

			& + .item-row {
				border-top: initial;
			}
		}

		span {
			display: block;
		}
	}

	.content {
		/* accordéons */
		.accordeon + .accordeon {
			margin-top: 20px;
		}

		.accordeon {
			border: 1px solid var(--border-color-accordeon);

			&.open {
				border-color: var(--bgcolor-accordeon-active);
				background-color: var(--color-accordeon-active);
			}

			.title-accordeon {
				padding: 30px 40px 30px 50px;
				@include tablet-portrait {
					padding: 25px 35px;
				}
				@include mobile {
					padding: 20px 30px;
				}

				&:before {
					right: 40px;
					@include mobile {
						right: 30px;
					}
				}

				.title {
					display: flex;
					flex-grow: initial;
					align-items: center;
					@include ft($f_hx, 30px, 700, "", 28px);
					@include mobile {
						font-size: 24px;
					}
				}

				[class^=icon-], [class*=" icon-"] {
					position: initial;
				}
			}
		}
	}

	// Styles pour les différents parties SIT dans la page
	// ==========================================================================

	// Sit label
	// -----------------------------------------------
	.sit-labels {
		.list-labels {
			--gap: 20px;

			margin-top: calc(var(--gap) * -1 + 20px);

			> * {
				margin-right: 80px;
				@include mobile {
					margin-right: 30px;
				}
				margin-top: var(--gap);
			}
		}

		.label-container {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			text-decoration: none;
			min-width: 100px;
			max-width: 200px;

			.content {
				position: relative;
				margin-top: 22px;
				padding-top: 22px;

				@include mobile {
					margin-top: 16px;
					padding-top: 16px;
				}

				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 40px;
					height: 1px;
					background-color: $c_main;
				}

				.title {
					@include ft($f_hx, 16px, 700, $c_txt, 1);
				}
			}

			figure {
				img {
					height: 80px;
					width: auto;
					object-fit: contain;
				}
			}
		}

		a.label-container {
			@include can-hover {
				.title {
					color: $c_main;
				}
			}
		}

	}


	// Sit galerie
	// -----------------------------------------------
	.sit-galerie {
		--image-height: 380px; // si slider, contrainte de hauteur variable en fonction des bp -> crop possible
		--image-max-height: 380px; // si une seule image, contrainte de hauteur fixe -> ratio naturel, pas de crop
		margin-left: auto;

		@include tablet-portrait {
			margin-left: initial;
			margin-right: auto;
		}

		@include tablet-small {
			--image-height: 380px;
		}

		@include mobile {
			--image-height: 240px;
		}

		.sit-galerie__inner {
			display: flex; // évite un overflow
			justify-content: center;

			@include tablet-portrait {
				position: relative;
				justify-content: flex-start;
				max-width: 450px;
			}
		}

		.galerie {
			border-radius: $border-radius-img;
			min-width: 450px;
			@include mobile {
				min-width: 100%;
			}

			.btn-show-galerie {
				position: absolute;
				bottom: 20px;
				right: 20px;
			}

			figure img {
				height: auto;
				border-radius: $border-radius-img;
				max-height: var(--image-max-height); // taille naturelle de l'image
			}
		}
	}

	/* Sit à propos */
	.sit-a-propos {
		.title {
			& + p:first-of-type {
				margin-top: 40px;
			}

			& + .liste-a-propos {
				margin-top: 0;
			}

			& + .meta-wrapper {
				@include mobile {
					margin-top: 0;
				}
			}
		}

		.meta-wrapper {
			margin: 40px 0 40px 0;
		}

		p {
			& + .liste-a-propos {
				margin-top: 0;
			}
		}

		.btn-view-more {
			z-index: 1;
			margin-top: -20px;
			position: relative;
		}

		.liste-a-propos-big {
			margin-top: 30px;
			@include mobile {
				margin-top: 20px;
			}

			li {
				padding-top: 30px;
				@include mobile {
					padding-top: 20px;
				}
				display: flex;
				align-items: center;

				@include mobile {
					align-items: self-start;
					flex-direction: column;
				}

				.info-content {
					display: flex;
					flex-direction: column;

					@include min-mobile {
						margin-left: 20px;
					}

					> *:last-child {
						margin-bottom: 0;
					}
				}

				.info-icon {
					position: relative;

					[class^="icon-"], [class*=" icon-"] {
						display: block;
						font-size: 100px;
						color: $c_sub;
						margin-left: 35px;

						@include mobile {
							font-size: 75px;
							margin-left: 20px;
						}
					}

					&:before {
						content: "";
						background-color: rgba($c_main, 0.20);
						width: 110px;
						height: 110px;
						position: absolute;
						left: 0;
						top: 0;
						border-radius: 50%;
						z-index: -1;
						@include addTransition();

						@include mobile {
							width: 80px;
							height: 80px;
						}
					}
				}

				.info-title {
					@include ft($f_hx, 30px, 700, "", 1);
					margin-bottom: 0;

					@include mobile {
						font-size: 20px;
						margin-top: 30px;
					}

					& + p {
						margin-top: 10px;
					}
				}
			}
		}

		.liste-a-propos {
			margin: 0;
			display: flex;
			flex-wrap: wrap;
			margin-top: 0;

			& + .labels {
				margin-top: 40px;
			}

			> * {
				display: flex;
				width: 50%;
				margin-top: 20px;
				align-items: center;
				@include mobile {
					justify-content: center;
					flex-direction: column;
					text-align: center;
				}

				.info-title {
					@include ft($f_hx, 18px, 700, "", 1);
					@include mobile {
						font-size: 16px;
						margin-bottom: 5px;
					}
				}

				[class^="icon-"], [class*=" icon-"] {
					flex-shrink: 0;
					margin-right: 20px;
					font-size: 75px;
					@include mobile {
						font-size: 40px;
						margin-right: 0;
						margin-bottom: 5px;
					}
				}

				p {
					margin: 0;
				}
			}
		}

		.labels {
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;
			margin-top: -20px;

			> figure {
				margin-right: $small-margin;
				margin-top: 20px;

				@include mobile {
					width: 90px;
				}
			}

			.title-2 {
				display: block;
				font-size: 20px;
				line-height: 1;
				font-weight: 700;
				width: 100%;
			}
		}
	}


	// Sit info pratiques
	// -----------------------------------------------
	.sit-info-pratiques {

		.col {
			font-size: 14px;
			line-height: 1.42;

			.small-title {
				margin-bottom: 20px;
			}
		}

		ul + ul {
			margin-top: 40px;
			@include mobile {
				margin-top: 20px;
			}
		}

		.meta-wrapper {
			.meta {
				margin-right: 10px;
				margin-bottom: 10px;
			}
		}

		// rows en deux colonnes pour les capacités
		.list-rows {
			row-gap: 0;

			.item-row {
				border-top: initial;
				border-bottom: 1px solid $c_main;
			}
		}
	}

	// SIT critères push
	// -----------------------------------------------

	.sit-criteres-push-sit {
		--border-color-accordeon: #{$c_sub};
		--bgcolor-accordeon-active: #{$c_white};
		--color-accordeon-active: #{$c_light};

		.criteres-push-sit {
			display: flex;
			padding: 20px 40px 20px 50px;

			@include mobile {
				padding: 0 30px 10px 30px;
				flex-direction: column;
			}

			.credits {
				display: none;
			}

			figure, img {
				border-radius: 10px;
				@include mobile {
					max-width: 100px;
				}

				@include min-mobile {
					min-width: 155px;
				}
			}

			.content {
				width: 100%;
				@include min-mobile {
					margin-left: 20px;
				}
				@include mobile {
					margin-top: 20px;
				}

				.content-bottom {
					@include min-mobile {
						text-align: right;
					}
				}
			}
		}
	}


	// SIT opensystem
	// -----------------------------------------------
	.sit-opensystem {
		// style accordeon
		--border-color-accordeon: #{$c_main};
		--bgcolor-accordeon-active: #{$c_main_light};
		--color-accordeon-active: #{$c_main_light};

		--padding-opensystem: 40px;
		@include tablet-portrait {
			--padding-opensystem: 30px;
		}
		@include mobile {
			--padding-opensystem: 15px;
		}

		ul {
			display: flex;
			// flex-wrap: wrap;
			padding: 0 var(--padding-opensystem);
			align-items: flex-start;
			flex-wrap: wrap;
			justify-content: space-between;

			li {
				position: relative;
				width: 170px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				text-align: center;
				padding: 0;

				&:before {
					content: none;
				}

				@include tablet-portrait {
					width: 50%;
					padding: 20px;

					> * {
						max-width: 170px;
					}
				}

				@include mobile-small {
					width: 100%;
					padding: 10px 0;
				}

				[class^="icon-"], [class*=" icon-"] {
					display: block;
					font-size: 80px;
					position: relative;
					margin-left: 20px;
					color: $c_sub;
					z-index: 1;

					@include mobile {
						font-size: 70px;
					}

					& + .title {
						margin-top: $small-margin;
					}

					&:after {
						content: "";
						background-color: rgba($c_main, 0.20);
						width: 80px;
						height: 80px;
						position: absolute;
						left: -20px;
						top: 0;
						border-radius: 50%;
						z-index: 0;
						@include addTransition();

						@include mobile {
							height: 70px;
							width: 70px;
						}
					}
				}

				.title {
					z-index: 1;
					@include ft($f_hx, $s_p, bold, $c_txt, 1);
					text-transform: uppercase;
					text-align: center;
					@include addTransition();
					margin-bottom: 10px;

					@include mobile {
						font-size: 14px;
					}
				}

				.text {
					margin-bottom: 0;
					@include mobile {
						font-size: 14px;
					}
				}
			}
		}

		.sit-opensystem-bottom {
			text-align: right;
			padding: 0 var(--padding-opensystem) 10px var(--padding-opensystem);
		}

		& + .sit-opensystem {
			margin-top: 15px;
		}
	}

	// Sit tarifs
	// -----------------------------------------------
	.sit-tarifs {
		.modes-paiements,
		.list-rows,
		.container,
		.texte-supplementaire {
			& + * {
				margin-top: 40px;

				@include mobile {
					margin-top: 20px;
				}
			}
		}

		.small-title {
			& + * {
				margin-top: 16px;
			}
		}

		.modes-paiements {
			display: flex;
			align-items: flex-start;
			font-size: 14px;
			margin-top: 40px;
			@include mobile {
				margin-top: 30px;
			}

			.icon-paiement {
				display: inline-block;
				margin-right: 10px;
				font-size: 20px;
			}

			.modes-paiements-small-title {
				@include ft($f_hx, 18px, bold, $c_txt, 1);

				@include mobile {
					font-size: 16px;
				}
			}

			.meta-wrapper {
				margin-top: 5px;
			}
		}

		.col {
			.title-section {
				@include ft('', $s_medium, 300, '', 1);
				text-transform: uppercase;

				& + * {
					margin-top: 20px;
				}
			}

			.single-meta {
				display: inline-block;
				border: 1px solid $c_sub;
				padding: 8px 10px;
				@include ft('', $s_link_small, 700, '', 1);

				& + * {
					margin-top: 15px;
				}
			}

			.text {
				font-weight: 300;
			}
		}


		.item-row__value {
			flex-shrink: 0;
		}
	}


	// Sit horaires
	// -----------------------------------------------
	.sit-horaires {
		position: relative;

		.btn-primary {
			@include min-mobile {
				position: absolute;
				right: 0;
				top: 0;
			}

			@include mobile {
				margin-bottom: 20px;
			}
		}

		.container {
			padding-top: 40px;
			@include mobile {
				padding-top: 0;
				margin-top: 30px;
			}
		}

		.nav-arrows {
			position: relative;
			width: 100%;

			@include desktop {
				position: absolute;
			}

			@include tablet-landscape {
				position: relative;
				margin-top: 30px;
			}

			@include tablet-small {
				position: absolute;
			}

			.btn-arrow {
				width: 30px;
				height: 30px;

				@include min-tablet-landscape {
					position: absolute;
					top: 40px;

					&.--prev {
						left: -20px;
						transform: translateX(-100%);
					}

					&.--next {
						right: -20px;
						transform: translateX(100%);
					}
				}

				@include tablet-landscape {
					// margin-bottom: 20px;

					&.--prev, &.--next {
						position: absolute;
					}
					&.--prev {
						left: 0;
					}
					&.--next {
						right: 0;
					}
				}

				&:before {
					font-size: 10px;
				}
			}
		}

		.splide__list {
			height: auto;
		}


		.slide-horaires {
			width: 100%;

			.small-title {
				margin-bottom: 16px;
				@include tablet-small {
					text-align: center;
					padding: 0 60px;
				}
			}

			.item-row {
				.disponible, .indisponible {
					display: flex;
					align-items: center;
					position: relative;
				}
			}
		}

		// Slider en 2 colonnes si plusieurs périodes d'horaires
		.splide__slide.slide-horaires {
			margin-right: 40px;

			@include min-tablet-small {
				width: calc(50% - 20px);
			}
		}

		.texte-supplementaire {
			margin-top: 40px;

			@include mobile {
				margin-top: 20px;
			}
		}
	}


	/* Sit documentation */
	.sit-documentation {
		.doc-info {
			position: relative;
			display: flex;
			justify-content: center;
			flex-direction: column;
			padding: 33px 80px 33px 30px;
			width: 100%;
			min-height: 110px;
			background-color: $c_main_light;
			text-decoration: none !important;
			border-bottom: none;
			@include addTransition();

			@include mobile {
				padding: 20px 60px 20px 20px;
			}

			&:after {
				@include icomoon($icon-download);
				border-radius: 50%;
				border: 1px solid $c_main;
				font-size: 18px;
				width: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 50px;
				color: $c_sub;
				position: absolute;
				right: 30px;
				@include addTransition();

				@include mobile {
					right: 20px;
				}
			}

			.doc-type-weight {
				@include ft($f_txt, 16px, 400, $c_txt, "");
				@include addTransition();

				@include mobile {
					font-size: 14px;

					& + .title {
						margin-top: 5px;
					}
				}
			}

			.title {
				@include ft($f_hx, 18px, 700, $c_txt, 1.2);
				@include addTransition();

				@include mobile {
					font-size: 16px;
				}
			}

			@include can-hover {
				background-color: $c_main;

				&:after {
					border-color: $c_main_light;
				}
				&:after {
					color: $c_white;
				}

				.doc-type-weight,
				.title {
					color: $c_white;
				}
			}
		}
	}

	// Sit contact
	// -----------------------------------------------
	.sit-contact {
		.contact-name,
		.contact-adress {
			display: flex;
			align-items: flex-start;
			font-size: 14px;
			line-height: 1.42;

			@include mobile {
				padding: 10px 0;
			}

			&:before {
				@include icomoon();
				display: inline-block;
				margin-right: 10px;
				font-size: 20px;
			}
		}

		.contact-name:before {
			//  content: $icon-person;
		}

		.contact-adress {
			color: $c_grey;
			margin-bottom: 20px;

			strong {
				color: $c_sub;
				font-family: $f_hx;
				font-size: 18px;
			}

			span {
				font-size: 16px;
			}

			&:before {
				content: $icon-pin;
				color: $c_grey;
			}
		}

		.container {
			display: flex;
			@include mobile {
				flex-direction: column;
			}

			.col:nth-child(1) {
				width: 30%;
				@include mobile {
					width: 100%;
				}
			}

			.col:nth-child(2) {
				margin-left: auto;
				width: 60%;
				@include mobile {
					width: 100%;
				}
			}
		}


		.btn {
			margin-top: 10px;
			&.btn-primary{
				position: relative;
				@include mobile{
					margin-bottom: 0;
				}
			}
		}

		.social-links {
			margin-top: 10px;
		}

		.maps {
			position: relative;
			z-index: 0;
			height: 360px;
			width: 100%;

			.leaflet-top.leaflet-right {
				top: 20px;
				right: 20px;
			}

			@include mobile {
				height: 360px;
				margin-top: 30px;
			}
		}
	}


	// Sit vidéo
	// -----------------------------------------------
	.sit-video {
		.container + .container {
			margin-top: 20px;
		}
	}


	// Sit relation
	// -----------------------------------------------
	.sit-relation {
		.bloc-relation {
			display: none;
		}

		.radio {
			display: none;
		}

		#autres-activites-radio:checked ~ #autres-activites, #vous-aimerez-radio:checked ~ #vous-aimerez {
			display: block
		}

		#autres-activites-radio:checked ~ .onglets #tab-autres-activites, #vous-aimerez-radio:checked ~ .onglets #tab-vous-aimerez {
			border-bottom: 3px solid rgba($c_txt, 1);
		}

		.splide__track {
			margin-top: 20px;
		}

		.splide__slide {
			--slide-width: 305px;
			--slide-gap: 20px;
			width: var(--slide-width);
			margin-right: var(--slide-gap);
			max-width: 100%;

			@include mobile {
				--slide-gap: 10px;
			}
		}

	}

	// Sit petits plus
	// -----------------------------------------------
	.sit-petits-plus {
		background-color: $c_light;
		padding: 50px;

		@include tablet-portrait {
			padding: 40px;
		}
		@include mobile {
			padding: 30px;
		}

		ul {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			li {
				width: calc(50% - 30px);

				&:nth-child(odd) {
					margin-right: 30px;
					@include tablet-small{
						margin-right: 0;
					}
				}

				&:nth-child(even) {
					margin-left: 30px;
					@include tablet-small{
						margin-left: 0;
					}
				}

				@include tablet-small {
					width: 100%;
				}
			}
		}
	}

}