$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts/icomoon/fonts" !default;

$icon-tiktok: "\e94e";
$icon-document-v: "\e94c";
$icon-ico-fleur: "\e94a";
$icon-linkedin: "\e948";
$icon-location: "\e946";
$icon-arrow-down-alt: "\e943";
$icon-arrow-up: "\e944";
$icon-puce: "\e93e";
$icon-commentaire: "\e93c";
$icon-carte: "\e93d";
$icon-grille-chateau: "\e93a";
$icon-grille-coeur: "\e903";
$icon-cadeau: "\e906";
$icon-visite-musee: "\e907";
$icon-terroir-vin: "\e90f";
$icon-bien-etre-spa: "\e912";
$icon-spot-photo: "\e913";
$icon-quizz: "\e914";
$icon-point-de-depart: "\e915";
$icon-patrimoine: "\e916";
$icon-noel: "\e917";
$icon-niveau: "\e918";
$icon-nature-faune: "\e919";
$icon-ludique: "\e91a";
$icon-montgolfiere: "\e91b";
$icon-transport-acces: "\e91c";
$icon-grille-itineraire: "\e91d";
$icon-activites-nature: "\e92a";
$icon-grille-budget: "\e92e";
$icon-grille-sablier: "\e92f";
$icon-grille-calendrier: "\e930";
$icon-grille-animaux: "\e931";
$icon-grille-fromage: "\e932";
$icon-grille-girafe: "\e934";
$icon-grille-lune: "\e937";
$icon-arrow-down: "\e947";
$icon-arrow-left: "\e92b";
$icon-arrow-right: "\e92c";
$icon-search: "\e92d";
$icon-close: "\e93b";
$icon-minus: "\e935";
$icon-plus: "\e936";
$icon-download: "\e929";
$icon-play: "\e910";
$icon-filters: "\e905";
$icon-refresh: "\e928";
$icon-credits: "\e939";
$icon-menu: "\e949";
$icon-checkmark: "\e94b";
$icon-document: "\e940";
$icon-quote: "\e938";
$icon-agenda: "\e923";
$icon-pin: "\e94d";
$icon-email: "\e900";
$icon-phone: "\e901";
$icon-link: "\e902";
$icon-social-share: "\e904";
$icon-tripadvisor: "\e945";
$icon-facebook: "\e927";
$icon-twitter: "\e926";
$icon-instagram: "\e925";
$icon-pinterest: "\e924";
$icon-youtube: "\e933";
$icon-etoile: "\e922";
$icon-cle: "\e921";
$icon-epis: "\e920";
$icon-toque: "\e91f";
$icon-langues: "\e941";
$icon-paiement: "\e942";
$icon-animal: "\e91e";
$icon-chef-hat: "\e93f";
$icon-velo: "\e911";
$icon-grille-famille: "\e908";
$icon-grille-couple: "\e909";
$icon-grille-bateau: "\e90a";
$icon-grille-rando: "\e90b";
$icon-grille-velo: "\e90c";
$icon-grille-dejeuner: "\e90d";
$icon-grille-logement: "\e90e";