.pagination {
  display: flex;
  align-items: center;
  margin-top: 90px !important;
  text-align: center;
  clear: both;
  background: $c_white;

  @include mobile {
    flex-wrap: wrap;
    padding-top: 40px;
    text-align: center;
    margin-top: 40px !important;
  }

  &:empty {
    display: none;
  }

  > a, strong, span {
    @include ft('', 10px, 400, $c_txt, 1.5);
    position: relative;
  }

  .btn-arrow {
    font-size: 0;
    z-index: 4;

    &.--prev {
      @include min-mobile {
        order: 1;
        margin-right: 30px !important;
      }
    }

    &.--next {
      @include min-mobile {
        order: 4;
      }
    }

    &:first-of-type {
      margin-right: 0;
    }
  }

  .pagination-number {
    position: relative;
    z-index: 10;
    margin-left: auto;
    margin-right: 25px;

    @include min-mobile {
      order: 3;
    }
    @include mobile {
      margin-right: auto;
    }

    > * {
      margin: 0 14px;
      font-size: 18px;
      font-weight: 300;
      color: $c_sub;
      font-family: $f_hx;
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      border-bottom: 3px solid rgba($c_sub, 0);

      @include mobile {
        margin: 0 7px;
      }
      @include mobile-small {
        margin: 0 2px;
      }

      &.active {
        color: $c_sub;
        font-weight: 700;
        border-color: $c_sub;
      }
    }

    a {
      @include addTransition();

      @include can-hover {
        color: $c_main;
      }
    }

    > span {
      vertical-align: bottom;
    }
  }


  .resultats {
    font-size: 16px;
    line-height: 1.5;
    text-align: left;

    @include min-mobile {
      order: 2;
      //  margin-left: 20px;
    }
    @include mobile {
      display: none;
    }

    span {
      font-size: 16px;
      line-height: 1.5;
      font-weight: 700;
    }
  }
}