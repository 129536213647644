// ==============================================

//        VARIABLES SASS & CSS

// ==============================================


// Fonts
// ==========================================================================
$f_hx: 'Teen', Sans-Serif;
$f_txt: 'montserrat', Sans-Serif;
//$f_spe: 'GT-Walsheim', Sans-Serif;
$font_path: "./../../../adt41/assets/fonts/";

// Colors
// ================================================ ==========================
$c_main: #F09900;
$c_sub: #1B2533;
$c_txt: #1B2533;
$c_black: #000000;
$c_white: #FFFFFF;

$c_main_light: #FEF5E6;
$c_light: #EFF9FA;

$c_border: rgba($c_sub, 0.10);
$c_grey: #757B84;

$c_red: #F21D4E;
$c_error: #DE0156;
$c_green: #00C754;

// Sizes
// ==========================================================================
$s_h1: 55px;
$s_h2: 45px;
$s_h3: 40px;
$s_small_title: 20px;
$s_medium: 18px;
$s_p: 16px;
$s_link: 14px;
$s_link_small: 12px;

// Gestion des valeurs des z-index
// ==========================================================================
$z-layers: (
        'mega-menu': 10001,
        'overlay' : 200,
        'overlay-shadow': 100,
        'strate-info': 90,
        'barre-navigation': 80,
        'toolbar': 70,
        'barre-sticky' : 50,
        'social-share' : 40,
        'couverture' : 30,
        'btn-slider' : 20,
        'credits' : 10,
        'default' : 1,
        'below' : -1,
);


// Width / Height / Spacing
// ==========================================================================

$small-margin: 20px;
$small-margin-mobile: 10px;
$medium-margin: 30px;
$medium-margin-mobile: 15px;


// Border Radius
// ==========================================================================

$border-radius-img: 15px;

// Wrappers
// ==========================================================================

$wrapper-width: 1120px; // /!\ anciennement $max-width, taille par défaut quand classe .wrapper seule
$wrapper-medium-width: 1200px; // /!\ anciennement $grid-medium
$wrapper-small-width: 960px; // /!\ anciennement $grid-small
$wrapper-extra-small-width: 710px; // /!\ anciennement $grid-extra-small
$wrapper-large-width: 1440px; // /!\ anciennement $max-width-large ($max-width-large: 1600px - $border-gutter - $border-gutter;)


// ---- nouvelles variables pour gérer le layout général -----

$border-gutter: 80px; // marges gauche et droite minimum autour des wrappers
$border-gutter-tablet: 40px;
$border-gutter-mobile: 20px;

$grid-gap: 40px; // /!\ anciennement $content-gutter
$grid-gap-small: 20px;
$grid-gap-mobile: 20px;

$section-spacing: 100px;
$section-spacing-mobile: 60px;

$bloc-spacing: 50px; // /!\ anciennement $content-spacing
$bloc-spacing-mobile: 30px;

$strate-spacing: 80px;
$strate-spacing-mobile: 50px;

$header-height: 80px;
$header-height-mobile: 70px;

$toolbar-height: 70px;
$toolbar-height-mobile: 70px;


// Breakpoints
// ==========================================================================

$breakpoints: (
        mobile-small : 320px,
        mobile : 599px,
        tablet-small : 768px,
        tablet-portrait : 980px,
        tablet-landscape: 1024px,
        desktop : 1280px,
        wide : 1600px
);

// Breakpoints (Ancien système sans map)
// ==========================================================================
$breakpoint_mo_small: 320px;
$breakpoint_mo: 599px;
$breakpoint_tablet-small: 768px;
$breakpoint_portrait: 980px;
$breakpoint_paysage: 1024px;
$breakpoint_desktop: 1280px;
$breakpoint_wd: 1600px;


// Transition
// ==========================================================================
$transition-overlay-easing: cubic-bezier(0.86, 0, 0.07, 1);


// CSS Variables
// ==========================================================================

// Changement dynamiques des CSS vars en fonction des breakpoints
:root {
  --border-gutter: #{$border-gutter};

  --section-spacing: #{$section-spacing};
  --bloc-spacing: #{$bloc-spacing};
  --strate-spacing: #{$strate-spacing};

  --grid-gap: #{$grid-gap};
  --grid-gap-small: #{$grid-gap-small};

  --header-height: #{$header-height};
  --toolbar-height: #{$toolbar-height};

  --small-margin: #{$small-margin};
  --medium-margin: #{$medium-margin};

  @media screen and (max-width: map-get($breakpoints, 'tablet-landscape')) {
    --border-gutter: #{$border-gutter-tablet};
  }

  @media screen and (max-width: map-get($breakpoints, 'mobile')) {
    --border-gutter: #{$border-gutter-mobile};

    --section-spacing: #{$section-spacing-mobile};
    --bloc-spacing: #{$bloc-spacing-mobile};
    --strate-spacing: #{$strate-spacing-mobile};

    --grid-gap: #{$grid-gap-mobile};

    --header-height: #{$header-height-mobile};
    --toolbar-height: #{$toolbar-height-mobile};


    --small-margin: #{$small-margin-mobile};
    --medium-margin: #{$medium-margin-mobile};
  }
}

