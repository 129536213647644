.strate-footer-newsletter {
  position: relative;
  background-color: $c_sub;

  &:after {
    content: '';
    position: absolute;
    top: -225px;
    left: 0;
    bottom: -40px;
    right: 0;
    background-color: $c_sub;
    z-index: -1;
  }

  .text-styles {
    .h2 {
      color: $c_white;
    }
  }

  .frm_message {
    color: $c_white;
  }

  form {
    position: relative;
    width: 100%;

    // ordre sur mobile
    @include mobile {
      .frm_fields_container {
        display: flex;
        flex-direction: column;
      }

      .input-is-email {
        order: 1;
      }

      .frm_submit {
        order: 2;
      }

      .input-is-checkbox {
        order: 3;
      }
    }


    .frm_error {
      @include ft($f_txt, 12px, 400, $c_white, 18px);
      background: $c_error;
      position: relative;
      display: inline-block;
      //  margin: 0 55px;
      padding: 4px 10px;
      border-radius: 4px;
    }

    .frm_error_style {
      display: none;
    }

    .frm_hidden, label {
      display: none;
    }

    input[type="email"] {
      @include reset-appearance;
      @include ft($f_txt, 18px, 300, $c_txt, 22px);
      display: inline-block;
      width: 100%;
      padding: 39px 220px 39px 40px;
      white-space: nowrap;
      overflow: hidden;
      border-radius: 6px;
      text-overflow: ellipsis;
      vertical-align: top;
      background: $c_white;
      @include addTransition();

      @include mobile {
        font-size: 16px;
        padding: 19px 30px;
        text-align: center;
      }

      &::placeholder {
        color: $c_txt;
      }

      @include can-hover {
        outline: none;
        box-shadow: 0 30px 60px rgba($c_sub, 0.05);
      }

      & + .frm_error {
        margin-top: 10px;
      }
    }

    /*Change text in autofill textbox*/
    input:-webkit-autofill {
      -webkit-text-fill-color: $c_txt;
    }

    /* Change the white to any color ;) */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 100px $c_white inset !important;
    }

    .frm_top_container {
      position: relative;
    }

    .frm_submit {
      position: absolute;
      top: 25px;
      right: 40px;
      @include mobile {
        position: relative;
        margin-top: 20px;
        right: 0;
        top: 0;
      }

      .frm_button_submit {
        @extend .btn;
        width: auto;

        @include mobile {
          width: 100%;
          min-height: 60px;
          color: $c_white;
          text-align: center;
        }
      }
    }

    /* Checkbox */
    .frm_opt_container {
      position: relative;
      margin-top: 32px;
      margin-left: 40px;

      @include mobile {
        margin-left: 0;
      }

      .frm_primary_label {
        display: none;
      }

      label {
        @include ft($f_txt, $s_link_small, 300, $c_white, 1.5);
        position: relative;
        display: block;
        padding: 0 0 10px 40px;
        text-align: left;
        user-select: none;
        @include addTransition();

        @include can-hover {
          cursor: pointer;
          color: $c_main;
        }

        a {
          position: relative;
          display: inline-block;
          text-decoration: underline;
          font-weight: 700;
        }
      }

      /* Style for Checkbox */
      .frm_checkbox {
        position: relative;

        input {
          display: none;

          &:checked {
            ~ span:after {
              opacity: 1;
              transform: scale(1);
            }
          }
        }

        label {
          > span {
            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              border-radius: 3px;
              width: 20px;
              height: 20px;
              //background-color: $c_white;
              border: 1px solid $c_white;
              //  border-radius: 2px;
              cursor: pointer;
              @include addTransition();
            }

            &:after {
              @include icomoon();
              content: $icon-checkmark;
              position: absolute;
              display: flex;
              align-items: center;
              border-radius: 3px;
              justify-content: center;
              top: 0;
              left: 0;
              width: 20px;
              height: 20px;
              background: $c_main;
              opacity: 0;
              color: white;
              font-size: 10px;
              transform: scale(0);
              @include addTransition();
            }
          }
        }
      }
    }
  }
}