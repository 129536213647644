/* ------------------
--- CSS GLOBAL + DES CLASSES UTILES ---
------------------ */

html, body {
	height: 100%;
}

html {
	font-size: 100%;
	margin-top: 0 !important;
}

body {
	font-family: $f_txt;
	font-size: 16px;
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: $c_txt;
	overflow-x: hidden;

	&.no-scroll {
		overflow: hidden;
	}

	&.fixed {
		@include absolute-stretch();
		position: fixed;
	}
}


main {
	position: relative;
}

[class^="icon-"], [class*=" icon-"] {
	@include addTransition();
}

.v-tablet-p {
	display: none !important;
	@include tablet_portrait {
		display: block !important;
	}
}

.hide-tablet-p {
	display: block;
	@include tablet_portrait {
		display: none !important;
	}
}

.custom-scrollbar {
	--custom-scrollbar-width: 10px;

	@include mobile {
		--custom-scrollbar-width: 8px;
	}

	/* Firefox */
	scrollbar-width: auto; /* can also be normal, or none, to not render scrollbar */
	scrollbar-color: rgba($c_black, 0.7) transparent; /* foreground background */

	/* Webkit / Blink */
	&::-webkit-scrollbar {
		width: var(--custom-scrollbar-width);
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgba($c_black, 0.5);
		border-radius: 9999px; /* always round */
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}
}