.slider-menu {
  position: initial;
  // height: 100vh;

  .splide__slide {
    height: auto !important;
    //  position: initial;
  }

  .splide__track {
    //   height: 100% !important;
    @include min-tablet-landscape {
      min-height: calc(100vh - (180px + 35px)); // padding + hauteur titre + flèche
      padding-bottom: 100px !important;
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    &:before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 0;
      pointer-events: none;
      background-image: linear-gradient(to top, rgba($c_sub, 1), rgba($c_sub, 0));
    }
  }

  .container-nav {
    padding: 40px;
    margin: 0;

    .nav-buttons {
      position: relative;
      flex-direction: column;
      display: flex;
      @include min-tablet-small {
        flex-direction: row;
        .--prev {
          transform: rotate(90deg);
        }

        .--next {
          transform: rotate(90deg);
        }
      }
    }
  }

  // style card sur fond foncé
  .card {
    padding-left: 0;
    padding-right: 0;
    @include tablet-small {
      margin-right: 20px;
    }

    .caption {
      .title-card {
        color: $c_white;
      }
    }

    .image {
      max-width: 100px;
      max-height: 100px;
    }
  }
}