$c_rgpd_red: #E74C3C;
$c_rgpd_green: #45B172;
$c_rgpd_darken: #3C3C3C;
$c_rgpd_border: rgba(#3C3C3C, .1);

#tarteaucitronIcon {
	display: none !important;
}

#tarteaucitronBackModal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000000;
	background: rgba($c_sub, 0.5) !important;
	display: none;
}

#tarteaucitron {
	position: fixed;
	top: 0;
	height: 100% !important;
	max-height: 100%;
	width: 100% !important;
	background: none;
	margin: 0 auto !important;
	overflow: auto;
	-webkit-overflow-scrolling: touch;

	.tarteaucitronH3 {
		font-family: $f_txt;
		font-size: 12px;
		font-weight: 700;
		color: $c_rgpd_darken;
		line-height: 14px;
		text-transform: uppercase;
		margin-bottom: 3px;
	}

	.tarteaucitronListCookies{
		font-style: italic;
	}

	#tarteaucitronServices {
		position: relative;
		margin: 0 !important;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		height: 100%;
		overflow: hidden;

		&:after {
			content: '';
			content: none;
			position: relative;
			display: block;
			width: 100%;
			height: 50px;
		}

		#tac-scroller {
			box-sizing: border-box;
			max-width: 800px;
			position: relative;
			z-index: 1000;
			margin: 0;
			background: $c_white;
			box-shadow: 0 50px 50px rgba(#000, 0.1);
			overflow: hidden;
			pointer-events: auto;
			height: 75%;
			overflow: auto;
			-webkit-overflow-scrolling: touch;
			padding-bottom: 0;

			&:after{
				content: '';
				display: block;
				height: 100px;
				width: 100%;
			}

			@media screen and (max-width: 880px) {
				width: calc(100% - 80px);
				margin: 0 40px 0;
			}
			@media screen and (max-width: 600px) {
				width: 100%;
				height: 100%;
				margin: 0 0 0;
			}
		}

		#tarteaucitronMainLineOffset, .tarteaucitronBorder {
			position: relative;
			box-sizing: border-box;
			background: white;
			width: 100%;
			max-width: 800px;
			margin: 0 auto;
		}

		#tarteaucitronMainLineOffset{
			position: static;
			
			.tarteaucitronName{
				display: none;
			}

			#tarteaucitronScrollbarAdjust{
				box-sizing: border-box;
				position: fixed;
				bottom: 12.5%;
				left: 50%;
				margin-left: -400px;
				right: auto;
				height: 100px;
				text-align: center;
				background-color: white;
				width: 100%;
				max-width:800px;
				padding: 0 30px;
				z-index: 100;
				display: flex;
				justify-content:center;
				align-items:center;
				border-top:1px solid $c_rgpd_border;

				> * {
					position: relative;
					height: auto;
					width: 100%;
					font-family: $f_txt;
					font-size: 14px !important;
					font-weight: 400;
					color: $c_sub;
					line-height: 20px;
					-webkit-appearance: none;
					resize: none;
					box-shadow: none;
					border-radius: 0;
					border: none;
					outline: none;
					color: white;
					box-sizing: border-box;
					padding: 10px 20px;
					border-radius: 2px;
					border: 1px solid $c_rgpd_border;
					margin: 0 5px;
					opacity: 1 !important;
					background: none;
					cursor: pointer;
					@include addTransition();

					&::-ms-clear {
						display: none;
					}

					> span,
					&:before {
						display: none;
					}

					&#tarteaucitronAllAllowed {
						background-color: $c_rgpd_green;
						border-color: $c_rgpd_green;
						order:3;
						margin-right: 0;
						margin-left:5px;
						&:hover, &:focus {
							body:not(.no-hover) & {
								background-color: transparent;
								border-color: $c_rgpd_border;
								color: $c_rgpd_green;
							}
						}
					}

					&#tarteaucitronAllDenied {
						background-color: rgba($c_rgpd_red,.15);
						border-color: rgba($c_rgpd_red,.15);
						color: $c_rgpd_red;
						order:1;
						margin-left: 0;
						margin-right: 5px;
						&:hover, &:focus {
							body:not(.no-hover) & {
								background-color: transparent;
								border-color: $c_rgpd_border;
								color: $c_rgpd_red;
							}
						}
					}

					&#tarteaucitronClosePanel{
						background-color: $c_rgpd_darken;
						border-color: $c_rgpd_darken;
						order:2;
						margin: 0 5px;
						&:hover, &:focus {
							body:not(.no-hover) & {
								background-color: transparent;
								border-color: $c_rgpd_border;
								color: $c_rgpd_darken;
							}
						}
					}

					@media screen and (max-width: 600px){
						font-size: 12px;
						line-height: 15px;
					}
				}


				@media screen and (max-width: 880px){
					margin-left: 0;
					width: auto;
					left: 40px;
					right: 40px;
				}
				@media screen and (max-width: 600px){
					//display: none;
					width: 100%;
					left:0;
					right: 0;
					padding:0 15px;
					bottom: 0;
				}
			}
		}

		#matomoiframe{
			display: flex;
			box-sizing: border-box;
			width: 100%;

			> div{
				flex-shrink: 0;
				padding-right: 20px;
				width: auto;
				padding-top: 11px;
				font-size: 18px;
				line-height: 24px;
				font-weight: bold;
			}

			iframe{
				width: 100%;
			}

			@media screen and (max-width: 600px){
				display: block;
			}
		}
	}

	a {
		font-style: italic;
		text-decoration: underline;
		font-size: 12px;
	}
}

#tarteaucitronRoot {
	&.tarteaucitronBeforeVisible {
		#tarteaucitronBackModal {
			display: block;
		}
	}

	#tarteaucitronBack {
		padding: 0;
		background: rgba($c_sub, 0.5);
		z-index: 2147483645;
	}

	button#tarteaucitronBack {
		background: rgba($c_rgpd_darken, 0.6);
	}

	// BARRE BOTTOM
	#tarteaucitronAlertBig {
		box-sizing: border-box;
		padding: 20px;
		background: $c_white;
		box-shadow: 0 -10px 50px rgba($c_sub, 0.1);
		text-align: right;

		@media screen and (max-width: 1200px) {
			text-align: center;
		}

		&::after {
			display: block;
			content: "";
			clear: both;
		}

		// Si Modale Centrer
		&.tarteaucitronAlertBigTop {
			//z-index: 2147483641;
			top: 50%;
			left: 50%;
			width: 460px;
			transform: translate(-50%, -50%);
			transform: translate3d(-50%, -50%, 0);
			padding: 30px;
			padding-bottom:80px;

			@media screen and (max-width: 600px) {
				width: calc(100% - 40px);
				padding: 20px 20px 15px 20px;
				display: flex!important;
				flex-flow:column;

				&[style*="display: none;"]{
					display: none !important;
				}
				.tarteaucitronTitleModal{order:1;}
				.tarteaucitronDisclaimerAlert{order:2;}
				.tarteaucitronAllow{order:5;}
				.tarteaucitronDeny{order:3;}
				#tarteaucitronCloseAlert{order:4;}
			}

			#tarteaucitronTitleModal {
				font-family: $f_txt;
				font-size: 24px;
				font-weight: 700;
				color: $c_rgpd_darken;
				line-height: 28px;
				position: relative;
				display: block;
				text-align: left;
				margin: 0 0 20px 0;

				@media screen and (max-width: 600px) {
					font-size: 20px;
					line-height: 1.2;
					margin: 0 0 15px 0;
				}
			}

			#tarteaucitronDisclaimerAlert {
				padding: 0;
				text-align: left;
				margin-bottom: 25px;

				@media screen and (max-width: 600px) {
					margin-bottom: 20px;
				}
			}

			> button {
				position: relative;
				float: left;
				width: calc(50% - 3px);
				margin: 0 5px 0 0;

				@media screen and (max-width: 600px) {
					width: 100%;
					margin: 5px 0;
				}

				&#tarteaucitronCloseAlert {
					background: $c_rgpd_darken;
					border-color: $c_rgpd_darken;
					margin-right: 0;
					color: white;

					&:hover, &:focus {
						body:not(.no-hover) & {
							background: $c_white;
							border-color: $c_rgpd_border;
							color: $c_rgpd_darken;
						}
					}
				}

				&.tarteaucitronAllow {
					background: $c_rgpd_green;
					border-color: $c_rgpd_green;
					min-width: 190px;
					font-weight: 700;

					@media screen and (max-width: 980px) {
						min-width: 120px;
					}

					&:hover, &:focus {
						body:not(.no-hover) & {
							background: $c_white;
							border-color: $c_rgpd_border;
							color: $c_rgpd_green;
						}
					}
				}

				&.tarteaucitronDeny {
					background: rgba($c_rgpd_red, .15);
					border-color: rgba($c_rgpd_red, .15);
					color: $c_rgpd_red;
					min-width: 190px;

					@media screen and (max-width: 980px) {
						min-width: 120px;
					}

					&:hover, &:focus {
						body:not(.no-hover) & {
							background: $c_white;
							border-color: $c_rgpd_border;
							color: $c_rgpd_red;
						}
					}
				}
			}
		}

		&.tarteaucitronAlertBigBottom{
			display: flex;
			align-items:center;

			> button {
				position: relative;
				float: right;

				@media screen and (max-width: 1200px){
					float: none;
					width: 100% !important;
					max-width: 500px;
					margin-top: 10px;
				}

				&#tarteaucitronCloseAlert {
					background: $c_rgpd_darken;
					border-color: $c_rgpd_darken;
					color: white;
					order: 3;

					&:hover, &:focus {
						body:not(.no-hover) & {
							background: $c_white;
							border-color: $c_rgpd_border;
							color: $c_rgpd_darken;
						}
					}
				}

				&#tarteaucitronPersonalize2 {
					position: relative;
					width: auto;
					left: auto;
					right:auto;
					bottom:auto;
					background: $c_rgpd_green;
					border-color: $c_rgpd_green;
					min-width: 190px;
					font-weight: 700;
					order: 4;

					@media screen and (max-width: 980px) {
						min-width: 120px;
					}

					&:hover, &:focus {
						body:not(.no-hover) & {
							background: $c_white;
							border-color: $c_rgpd_border;
							color: $c_rgpd_green;
						}
					}
				}

				&.tarteaucitronDeny {
					background: rgba($c_rgpd_red, .15);
					border-color: rgba($c_rgpd_red, .15);
					color: $c_rgpd_red;
					min-width: 190px;
					order:2;

					@media screen and (max-width: 980px) {
						min-width: 120px;
					}

					@media screen and (max-width: 600px){
						margin-bottom: 0;
					}

					&:hover, &:focus {
						body:not(.no-hover) & {
							background: $c_white;
							border-color: $c_rgpd_border;
							color: $c_rgpd_red;
						}
					}
				}
			}

			@media screen and (max-width: 1200px){
				display: flex !important;
				flex-flow: column;
				flex-wrap: wrap;

				&[style*="display: none;"]{
					display: none !important;
				}
			}
			@media screen and (max-width: 600px){
				display: flex !important;
				flex-flow: column;
			}
		}

		#tarteaucitronDisclaimerAlert {
			font-family: $f_txt;
			font-size: 14px;
			font-weight: 400;
			color: $c_sub;
			line-height: 16px;
			position: relative;
			display: block;
			float: left;
			text-align: center;
			padding: 12px 0;

			@media screen and (max-width: 1200px) {
				float: none;
				padding: 0;
				margin-bottom: 20px;
			}
			@media screen and (max-width: 600px) {
				font-size: 14px;
				line-height: 1.28;
			}
		}

		> button {
			font-family: $f_txt;
			font-size: 14px !important;
			font-weight: 400;
			color: $c_white;
			line-height: 16px !important;
			-webkit-appearance: none;
			resize: none;
			box-shadow: none;
			border-radius: 0;
			border: none;
			outline: none;

			&::-ms-clear{
				display: none;
			}

			position: relative;
			box-sizing: border-box;
			padding: 11px 10px;
			margin: 0 5px;
			border: 1px solid rgba($c_rgpd_darken, 0.5);
			border-radius: 4px;
			transition: all 0.3s ease;

			&:hover, &:focus {
				body:not(.no-hover) & {
					cursor: pointer;
					background: $c_white;
					color: $c_sub;
				}
			}

			@media screen and (max-width: 600px) {
				display: block;
				text-align: center;
				width: 100%;
				box-sizing: border-box;
				margin: 10px auto;
				&:last-child {
					margin-bottom: 0;
				}
			}

			span {
				&:before {
					content: none;
				}
			}
		}

		#tarteaucitronPersonalize2{
			position: absolute;
			bottom: 30px;
			right: 30px;
			left: 30px;
			width:calc(100% - 60px);
			margin-bottom: 0;

			@include mobile{
				position: relative;
				width: 100%;
				bottom:auto;
				right:auto;
				left:auto;
			}
		}
	}

	// MODALE SERVICES
	#tarteaucitronServices {
		border: none;
		padding: 0;
		.tarteaucitronMainLine {
			border: none;
			background: $c_white;
			padding: 30px 30px 0 30px;

			&:hover, &:focus {
				background: $c_white;
			}

			.tarteaucitronAsk {
				margin: 0;

				@media screen and (max-width: 600px) {
					width: 100%;
				}

				button {
					//min-width: 190px;
					&:first-child {
						margin-left: 0;
					}
					&:last-child {
						margin-right: 0;
					}
				}
			}

			@include mobile{
				padding-left: 15px;
				padding-right: 15px;
			}
		}

		.tarteaucitronH1 {
			font-family: $f_txt;
			font-size: 24px;
			font-weight: 700;
			color: $c_rgpd_darken;
			line-height: 28px;
			margin: 0 0 10px 0;
			text-align: left;

			@media screen and (max-width: 600px) {
				margin: 15px 0 10px 0;
				font-size: 20px;
				line-height: 1.2;
			}
		}

		#tarteaucitronInfo {
			font-family: $f_txt;
			font-size: 14px;
			font-weight: 400;
			color: $c_rgpd_darken;
			line-height: 16px;
			border: none;
			background: none;
			text-align: left !important;
			padding: 0 !important;
			margin: 0 0 0 0 !important;
			max-width:none!important;
			padding-bottom: 25px!important;
			border-bottom:1px solid $c_rgpd_border;

			@media screen and (max-width: 600px) {
				margin: 0 0 15px 0 !important;
				width: 100%;
				max-width: 100% !important;
			}
		}
		.tarteaucitronName {
			margin: 0;

			@media screen and (max-width: 600px) {
				width: 100% !important;
				float: none;
				margin: 0 !important;
			}

			.tarteaucitronH2 {
				font-family: $f_txt;
				font-size: 18px;
				font-weight: 700;
				color: $c_rgpd_darken;
				line-height: 20px;
				margin: 0 !important;
				padding: 10px 0;

				@media screen and (max-width: 600px) {
					width: 100%;
					max-width: 100%;
					display: block;
					padding: 0;
					margin: 0 !important;
					text-align: left;
				}
			}
		}

		.tarteaucitronHidden {
			background: $c_white;
		}

		.tarteaucitronTitle {
			button {
				font-family: $f_txt;
				font-size: 14px;
				font-weight: 700;
				color: $c_rgpd_darken;
				line-height: 20px;
				background: #E0E0E0;
				padding: 9px 20px;
				border-radius: 4px;
				margin: 0;

				span {
					display: none;
				}
			}
		}

		.tarteaucitronBorder {
			border: none;

			> ul {
				margin: 10px;

				@include mobile{
					margin-left: 0;
					margin-right: 0;
				}

				> li {
					padding: 20px;
					position: relative;
					background: none;

					@include mobile{
						padding-left: 15px;
						padding-right: 15px;
					}

					&:first-child {
						&:before {
							content: none;
						}
					}

					&:before {
						content: '';
						position: absolute;
						left: 20px;
						right: 20px;
						top: 0;
						width: calc(100% - 40px);
						height: 1px;
						background: rgba($c_rgpd_darken, 0.1);
					}

					.tarteaucitronTitle{
						margin-bottom: 12px;

						button{
							background-color: transparent;
							font-size: 18px;
							line-height: 24px;
							font-weight: bold;
							padding: 0;
						}
					}

					>ul>li{
						border:1px solid $c_rgpd_border !important;
						padding: 20px;
						margin-bottom: 10px;
						border-radius:4px;

						@include mobile{
							padding: 10px;
							display: flex;
							align-items:center;

							.tarteaucitronName{
								margin-right: 15px !important;
							}

							.tarteaucitronAsk{
								flex-shrink:0;
							}
						}
					}
				}
			}
		}

		li.tarteaucitronLine {
			border: none;
			background: none;
			padding: 0 0 0 0;

			&:hover {
				background: none;
			}

			.tarteaucitronAsk {
				position: relative;
				margin: 0;

				button {
					visibility: visible !important;
				}

				//Ici on est dans le cas ou les cookies sont à WAIT ou FALSE
				button{
					box-sizing: border-box;
					background-color: transparent !important;
					border: none;
					padding: 0 !important;
					margin: 0;
					transition:none;
					height: 20px;
					display: block !important;
					text-align: right !important;
					padding-right: 25px !important;
					float: right;
					clear: right;
					font-size: 12px !important;
					line-height: 16px;
					font-weight: 400;
					color: rgba($c_rgpd_darken, .4);

					&:before,&:after, >span{
						content:none;
						display: none;
					}

					&.tarteaucitronAllow{
						opacity: 1 !important;
						color: rgba($c_rgpd_darken, .4) !important;
						position: relative;
						z-index: 20;
						font-weight: 400;

						&:after {
							content: '';
							position: absolute;
							top: 0;
							right: 0;
							width: 20px;
							height: 40px;
							z-index: 20;
							display: block;
						}
					}

					&.tarteaucitronDeny {
						opacity: 1 !important;
						color: $c_rgpd_red !important;
						pointer-events: none;
						position: relative;
						z-index: 5;
						font-weight: 700;

						&:after{
							content: '';
							position: absolute;
							bottom: 0;
							right: 0;
							width: 20px;
							height: 40px;
							z-index: 20;
							display: block;
						}
					}
				}

				&:after{
					content: '';
					position: absolute;
					display: block;
					top: 0;
					right: 0;
					height: 40px;
					width: 20px;
					border-radius:50px;
					background-color:rgba($c_rgpd_red, .25);
					box-sizing: border-box;
					z-index: 10;
					clear: both;
				}

				&:before{
					content: '';
					position: absolute;
					display: block;
					top: 24px;
					right: 4px;
					height: 12px;
					width: 12px;
					border-radius:50px;
					background-color:$c_rgpd_red;
					z-index: 15;
					@include addTransition();
				}
			}

			.tarteaucitronName {
				margin: 0;
			}

			//Si le cookie en question est passé à validé
			&.tarteaucitronIsAllowed{
				.tarteaucitronAsk {
					//Ici on est dans le cas ou les cookies sont à TRUE
					button{
						&.tarteaucitronAllow{
							opacity: 1 !important;
							color: $c_rgpd_green !important;
							pointer-events: none;
							z-index: 5;
							font-weight: 700;
						}

						&.tarteaucitronDeny{
							z-index: 20;
							margin-right: 0;
							pointer-events: auto;
							font-weight: 400;
							color: rgba($c_rgpd_darken, .4) !important;
						}
					}

					&:after{
						background-color:rgba($c_rgpd_green, .25);
					}

					&:before{
						content: '';
						top: 4px;
						background-color:$c_rgpd_green;
					}
				}
			}
		}


		//Pour les cookies mandatory, on est toujours activé
		#tarteaucitronServices_mandatory{
			li.tarteaucitronLine{
				padding: 0;
				border: none !important;

				.tarteaucitronAsk {
					//Ici on peut juste cache le bouton car les cookies seront toujours à true
					display: none;
				}
				.tarteaucitronName{
					max-width: 500px;
					width: 100%;
				}
			}

			.tarteaucitronH3 {
				text-transform: none;
				font-weight: 400;
			}
		}
	}

	#tarteaucitronClosePanel {
		font-size: 0;
		-webkit-appearance: none;
		resize: none;
		box-shadow: none;
		border-radius: 0;
		border: none;
		outline: none;
		&::-ms-clear{
			display: none;
		}
		padding: 0;
		top: 0;
		background: none;
		z-index: 1000;
		width: 75px;
		height: 75px;
		transition: all 0.3s ease;

		@media screen and (max-width: 600px) {
			width: 50px;
			height: 50px;
		}
		&:before {
			content: '';
			background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNC44NDkiIGhlaWdodD0iMTQuODQ5IiB2aWV3Qm94PSIwIDAgMTQuODQ5IDE0Ljg0OSI+CiAgPGcgaWQ9Ikdyb3VwZV82IiBkYXRhLW5hbWU9Ikdyb3VwZSA2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtOTk1LjE1MSAtMTM4KSI+CiAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlXzQiIGRhdGEtbmFtZT0iUmVjdGFuZ2xlIDQiIHdpZHRoPSIxIiBoZWlnaHQ9IjIwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMDA5LjI5MyAxMzgpIHJvdGF0ZSg0NSkiLz4KICAgIDxyZWN0IGlkPSJSZWN0YW5nbGVfNSIgZGF0YS1uYW1lPSJSZWN0YW5nbGUgNSIgd2lkdGg9IjEiIGhlaWdodD0iMjAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEwMTAgMTUyLjE0Mikgcm90YXRlKDEzNSkiLz4KICA8L2c+Cjwvc3ZnPgo=");
			background-repeat: no-repeat;
			background-position: center;
			background-size: 15px;
			display: block;
			height: 100%;
		}
		&:hover, &:focus {
			body:not(.no-hover) & {
				cursor: pointer;
			}
		}
	}

	div#tarteaucitron{
		top: 0 !important;
		max-height:none!important;
	}

}