.bloc-temps-forts-manuel {
  position: relative;
  padding-top: var(--section-spacing);

  .wrapper:first-of-type {
    .container-nav {
      justify-content: start;
    }
  }

  &:after {
    content: "";
    background-color: $c_light;
    height: 520px;
    width: 100%;
    position: absolute;
    left: 0;
    z-index: -1;
    top: -0;
  }

  // container-nav
  .container-nav {
    margin-top: var(--bloc-spacing);
    @include mobile {
      margin-bottom: 30px;
    }
  }

  // onglet
  .onglets {
    > a {
      &:not(.btn) {
        text-transform: uppercase;
        @include ft($f_hx, 16px, bold, $c_sub, 28px);
        border-bottom: 3px solid $c_sub;
        padding-bottom: 5px;

        @include can-hover {
          color: $c_main;
          border-bottom: 3px solid $c_main;
        }

        @include mobile {
          font-size: 14px;
        }
      }

      &:not(:first-child) {
        margin-left: 60px;

        @include mobile {
          margin-left: 30px;
        }
      }
    }

    .btn {
      border: 1px solid $c_sub;
    }
  }


  // slider
  .slide-temps-forts {
    display: flex;
    position: relative;
    width: 820px;
    margin-right: $small-margin;
    @include addTransition();

    @include tablet-portrait {
      width: 300px;
      flex-direction: column;
    }

    .image {
      --aspect-ratio: 380/380;
      @include mobile {
        --aspect-ratio: 300/180;
      }
      width: 380px;
      min-height: 380px;
      position: relative;
      flex-shrink: 0;
      @include aspect-ratio(var(--aspect-ratio), '> figure, > .no-photo');
      @include addTransition();

      @include tablet-portrait {
        width: 300px;
        min-height: initial;
        height: 180px;
      }


      img, .no-photo {
        border-radius: $border-radius-img 0 0 $border-radius-img;
        @include tablet-portrait {
          border-radius: $border-radius-img $border-radius-img 0 0;
        }
      }
    }

    .caption {
      padding: 40px;
      width: 100%;
      background-color: $c_white;
      border-radius: 0 $border-radius-img $border-radius-img 0;

      @include mobile {
        padding: 20px 30px 30px 30px;
        border-radius: 0 0 $border-radius-img $border-radius-img;
      }
    }

    .date {
      @include ft($f_hx, 36px, 300, $c_main, 45px);
      @include tablet-portrait {
        font-size: 26px;
      }

      & + * {
        margin-top: 10px;

        @include mobile {
          margin-top: 5px;
        }
      }
    }

    .surtitre {
      margin-bottom: 3px;

    }

    .title {
      @include ft($f_hx, 24px, bold, $c_txt, 28px);
      display: block;
      margin-bottom: 5px;

      @include tablet-portrait {
        font-size: 20px;
        line-height: 22px;
      }
    }

    .location {

    }

    .texte {
      color: $c_txt;
    }

    .btn {
      margin-top: 0;
    }


    &.is-active {
      @include addTransition();
      @include shadow();
      border-radius: $border-radius-img;
    }
  }

  // quand le slider a un lien
  a.slide-temps-forts {
    @include can-hover {
      .btn {
        background-color: $c_main;
        color: $c_white;
      }
    }
  }

}
