$blue_light: #009DA2;
$rose: #DE0156;

.comments-wrapper{
	margin-top: $section-spacing;
	@include mobile{
		margin-top: $section-spacing-mobile;
	}
}
.container-comments {
	background-color: $c_main_light;
	padding: 40px;
	border-radius: $border-radius-img;
	width: 100%;

	@include mobile {
		padding: 20px 30px;
	}

	.comments-caption {
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;

		.h3 {
			max-width: 278px;
			font-weight: 300;
		}

		.btn {
			@include mobile {
				margin-top: 30px;
				+ .btn {
					margin-top: 15px;
				}
			}

			@include can-hover {
				.comment-count {
					background-color: $c_white;
					color: $c_main;
				}
			}
		}
	}
}

.comment-count {
	background-color: $c_main;
	border-radius: 50%;
	min-width: 12px;
	min-height: 12px;
	display: flex;
	align-items: center;
	margin-left: 11px;
	justify-content: center;
	@include ft($f_hx, 8px, 700, $c_white, 1);
}

.comment {
	border: 1px solid rgba($c_txt, 0.10);
	padding: 30px;
	margin-top: 15px;
	display: flex;
	border-radius: 6px;
	min-height: 125px; //65px + padding 30*2

	@include mobile {
		padding: 20px;
		min-height: 105px; //65px + padding 20*2
	}

	&.child {
		margin-left: 30px;

		@include mobile {
			margin-left: 15px;
		}

		.author-image {
			background: transparent !important;

			&:after {
				background-color: $c_main !important;
			}
		}
	}

	.rappel-reponse {
		@include ft("", 12px, 600, rgba($c_txt, 0.30), 1);
		font-style: italic;
	}

	.author-name {
		margin-right: 15px;
		@include ft("", 14px, 600, "", 26px);
	}

	.date {
		@include ft("", 12px, 600, rgba($c_txt, 0.30), 1);
	}

	.comment-content {
		@include ft("", 12px, 400, "", 18px);
	}

	.author-image {
		background-color: rgba($blue_light, 0.20);
		color: $blue_light;
		border-radius: 50%;
		display: flex;
		width: 50px;
		height: 50px;
		position: relative;
		align-items: center;
		justify-content: center;
		text-transform: uppercase;
		flex-shrink: 0;
		@include ft($f_hx, 24px, 700, "", 1);

		@include mobile {
			width: 40px;
			height: 40px;
			font-size: 20px;
		}

		@include mobile-small {
			width: 30px;
			height: 30px;
		}

		&:after {
			content: "";
			background-color: $blue_light;
			height: 65px;
			width: 3px;
			position: absolute;
			right: -30px;
			top: 0;
		}
	}

	.caption {
		margin-left: 60px;
	}


	&:nth-of-type(odd) {
		.author-image {
			background-color: rgba($rose, 0.20);
			color: $rose;

			&:after {
				background-color: $rose;
			}
		}
	}
}