.cover-edito {
	position: relative;
	margin-bottom: 80px;

	@include tablet-portrait {
		margin-bottom: 40px;
	}


	//  Styles commun
	// -----------------------------------------------
	.general-container {
		position: relative;
		padding-top: var(--header-height);
	}


	//  Infos sous l'image
	// -----------------------------------------------
	.cover-bottom {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding: 40px 0 35px;

		@include mobile {
			padding: 30px 0 10px 0;
		}

		.breadcrumbs {
			margin: 0;
		}

		@include min-mobile {
			.breadcrumbs {
				margin-left: auto;
			}
		}

		.cover-bottom__info {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}
	}

	.meta-wrapper + .location {

	}

	.meta-wrapper {
		margin-top: 5px; // pour aligner les meta à la localisation parce qu'il y a un padding de 5px par default
		.meta {
			&:last-of-type {
				margin-right: 25px;
			}

			a {
				text-decoration: none;
			}

			@include can-hover {
				a {
					color: rgba($c_txt, 0.80);
				}
			}
		}
	}

	.location {
		color: $c_txt;
		font-size: 12px;

		&:before {
			color: $c_main;
		}
	}

	//  Intro sous l'image
	// -----------------------------------------------
	.cover-intro {
		padding: 60px 0 35px;

		@include mobile {
			padding: 10px 0;
		}

		p {
			font-weight: bold;
		}
	}


	// Styles générales aux deux types de header
	.header-edito-wrapper {
		position: relative;
		z-index: 10;
		display: flex;
		flex-direction: column;
		height: 100%;

		> .container {
			display: flex;
			align-items: center;
			margin-bottom: 7px;

			.surtitre {
				margin: 0;
			}

			.classement {
				margin-left: 10px;
			}
		}

		.localisation {
			font-weight: 600;
		}

		.container-info-supp {
			margin-left: auto;
			margin-top: auto;

			@include tablet-portrait {
				margin-left: 0;
			}

			> * {
				display: block;
				color: $c_sub;
				text-align: right;
			}

			.surtitre {
				margin-bottom: 0;

				@include mobile {
					display: none;
					font-size: $s_link;
					line-height: 1;
					margin-top: 0;
				}
			}

			.prix {
				font-size: 35px;
				line-height: 1;
				font-weight: 300;

				@include mobile {
					display: none;
					font-size: $s_link;
					line-height: 1;
				}

				strong {
					font-weight: 700;
				}

			}
		}
	}


	//  Version avec vidéo
	// -----------------------------------------------

	&.--has-video {
		.cover-video {
			@include absolute-stretch();

			video {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}


	//  Version avec image fullscreen
	// -----------------------------------------------

	&.--has-full-image {
		.general-container {
			// height: calc(100vh - var(--header-height));
			max-height: -webkit-fill-available;
			min-height: 570px;
			overflow: hidden;
			padding-top: 300px;

			@include tablet-portrait {
				// height: calc(100vh - var(--header-height) - var(--toolbar-height));
				min-height: 480px;
				padding-top: 200px;
			}

			&:before {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				right: 0;
				z-index: 1;
				// background: linear-gradient(45deg, rgba(27, 37, 51, 1), rgba(27, 37, 51, 0));
				background: linear-gradient(45deg, rgba($c_sub, 1), rgba($c_sub, 0.40), rgba($c_sub, 0), rgba($c_sub, 0));
				display: block;
				height: 100%;
				pointer-events: none;
				opacity: 0.80;
			}

			&:after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				z-index: 1;
				background: linear-gradient(180deg, rgba(27, 37, 51, 1), rgba(27, 37, 51, 0));
				display: block;
				height: 200px;
				pointer-events: none;
				opacity: 0.80;
			}
		}

		.cover-image {
			@include absolute-stretch();

			.credits{
				top: auto;
				bottom: 20px;
				right: auto;
				left: 20px;
				@include mobile{
					bottom: 20px;
					right: 20px;
					left: auto;
					z-index: 15;
				}
			}
			figure {
				position: relative;
				width: 100%;
				height: 100%;

				// gradient
				/*   &:before {
					 content: "";
					 position: absolute;
					 top: 0;
					 left: 0;
					 bottom: 0;
					 z-index: 1;
					 display: block;
					 width: calc(var(--border-gutter) + 62.5%);
					 height: 100%;
					 background-image: linear-gradient(to right, rgba(27, 37, 51, 0.50) var(--border-gutter), rgba(27, 37, 51, 0.50) var(--border-gutter), rgba(27, 37, 51, 0));
					 pointer-events: none;
				   } */
			}
		}

		.header-edito-wrapper {
			padding-top: 80px;
			//  padding-bottom: 150px;
			padding-bottom: 100px;

			@include mobile {
				bottom: 40px;
				padding-bottom: 0;
			}

			> * {
				@include min-tablet-portrait {
					max-width: 60%;
				}
			}

			> .container {
				[class^="icon-"], [class*=" icon-"] {
					color: $c_white;
				}
			}

			.meta-wrapper {
				.meta {
					color: $c_white;
					background-color: $c_sub;
					border: 1px solid transparent;

					.tooltiptext {
						background-color: $c_white;
						color: $c_sub;

						&:after {
							border-color: $c_white transparent transparent transparent;
						}
					}
				}
			}
		}

		.container-info-supp {
			@include tablet-landscape {
				margin-top: -40px;
			}
			@include tablet-portrait {
				margin-top: 0;
			}

			> * {
				color: $c_white;
			}
		}


		& + .content {
			.barre-single-sit {
				& + .bloc {
					padding-top: 60px;
				}
			}

			> div:last-of-type {
				@include min-tablet-landscape {
					padding-bottom: 150px;
				}
			}
		}
	}


	//  Version sans image
	// -----------------------------------------------

	&:not(.--has-full-image) {
		.general-container {
			height: auto;

			figure {
				display: none;
			}
		}

		.header-edito-wrapper {
			padding-top: 80px;
			padding-bottom: 0;

			@include tablet-portrait {
				padding-top: 110px;
			}

			.cover-title {
				@include min-tablet-portrait {
					max-width: 80%;
				}
			}

			.container-info-supp {
				@include tablet-portrait {
					margin-left: 0;
				}

				.surtitre, .prix {
					@include tablet-portrait {
						text-align: left;
					}
				}

				.surtitre {
					@include tablet-portrait {
						margin-top: 20px;
					}
				}
			}
		}

		& + .content {
			top: auto;

			.barre-single-sit {
				height: 0;
			}
		}
	}
}