.bloc-relation-card-horizontales {
  .card {
    .caption {
      &::before {
        display: none;
        content: attr(data-letter);
        position: absolute;
        height: 20px;
        width: 20px;
        @include ft($f_txt, 14px, 700, $c_white, 1);
        top: 50%;
        transform: translate(0, -50%);
        border-radius: 50%;
        left: 0;
        align-items: center;
        justify-content: center;
        background: $c_main;
        z-index: 2;
        color: $c_white;
        text-align: center;
      }
    }
  }
  
  .nav-arrows {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .splide__track {
    margin-top: 20px;

    @include mobile {
      margin-top: 0;
    }
  }

  .splide__slide {
    --slide-width: 345px;
    width: var(--slide-width);
    max-width: 100%;
  }
}
