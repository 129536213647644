// Le header petit décoratif avec une hauteur limité et juste le texte header
.small-header {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;
  background-color: $c_sub;

  a {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    color: $c_white;
  }
}

// Header sans image
.header-no-photo {
  padding-top: var(--header-height);
}