.cover-home {
  position: relative;
  // --cover-height: calc(100vh - var(--header-height));
  --cover-height: 100vh;
  --cover-min-height: 570px;
  min-height: calc(var(--cover-min-height));
  padding-top: calc(var(--header-height) + 45px);
  height: calc(var(--cover-height));
  margin-bottom: var(--section-spacing);
  //  background-color: rgba($c_sub, 0.70);

  @include tablet-portrait {
    //  --cover-height: calc(100vh - var(--toolbar-height) / 2);
    --cover-height: auto;
    --cover-min-height: 647px;
    padding-bottom: 40px;
  }

  // Baseline
  .baseline {
    @include min-desktop {
      @media screen and (max-height: 750px) {
        top: 70px;
      }
    }
  }


  //  Version avec image
  // -----------------------------------------------
  .one-slide {
    height: calc(100% - var(--header-height));
    display: flex;
    align-items: center;
  }


  //  Version avec vidéo
  // -----------------------------------------------

  &.--has-video {
    .cover-video {
      @include absolute-stretch();

      video {
        width: 100%;
        height: 100%;
        border-radius: $border-radius-img;
        object-fit: cover;
      }
    }

    .slide-video {
      height: calc(100% - var(--header-height));
      display: flex;
      align-items: center;
    }
  }


  //  Mode slider
  // -----------------------------------------------
  .splide__track {
    height: calc(100% - var(--header-height));
    display: flex;
    margin: auto auto;
  }

  --height-slide-cover: 650px;
  --width-slider-cover: 1230px;

  @include wide {
    --height-slide-cover: 500px;
    --width-slider-cover: 940px;
  }

  .splide__list {
    align-items: center;
    position: relative;

    .slide-cover {
      // height: auto;
      position: relative;
      margin-top: 0;
      margin-right: var(--slide-gap);
      @include addTransition();
      opacity: 0.60;
      --slide-gap: 70px;

      @include desktop {
        --slide-gap: 50px;
      }

      @include tablet-portrait {
        --slide-gap: 50px;
      }

      @include tablet-small {
        --slide-gap: 35px;
      }

      &:not(.is-active) {
        max-height: var(--height-slide-cover);
        height: calc(var(--height-slide-cover) - 20%);
        @include addTransition();
        @media screen and (max-height: 750px) {
          height: 100%;
          max-height: calc(500px - 20%);
        }

        @include tablet-portrait {
          height: 300px;
        }
      }

      &.is-active {
        opacity: 1;
        @include addTransition();

        .caption {
          opacity: 1;
          @include addTransition();
        }
      }

      .caption {
        opacity: 0;
        top: auto;
      }
    }
  }

  .container-nav {
    position: absolute;
    bottom: 40px;
    right: 40px;
    z-index: 10;
    display: flex;
    width: auto;
    align-items: flex-end;
    flex-direction: column;

    @include tablet-portrait {
      right: 35px;
      bottom: 0;
      position: relative;
    }
  }

  // Les styles suivants vont s'occuper de positionner le composant nav-dots et nav-buttons
  .nav-dots {
    button {
      height: 22px;
    }
  }

  .nav-buttons {
    .btn-arrow {
      position: absolute;
      transform: translateY(50%);
      top: auto;
      bottom: 50%;
      margin: 0;
      border: 1px solid $c_white;

      @include tablet-portrait {
        bottom: 100px;
      }

      &:first-of-type {
        left: var(--border-gutter);
        margin-left: 0;

        &:before {
          color: $c_white;
        }
      }

      &:last-of-type {
        right: var(--border-gutter);
        margin-right: 0;

        &:before {
          color: $c_white;
        }
      }
    }
  }

  .slide-cover {
    margin: 0 auto;
    @include addTransition();
    position: relative;
    max-width: var(--width-slider-cover);
    max-height: var(--height-slide-cover);
    height: 100%;
    // width: calc(100% - 380px);
    width: 100%;

    @media screen and (max-height: 750px) {
      max-height: 500px;
    }

    @include desktop {
      width: calc(100% - 180px);
      margin-top: 80px;
      height: calc(100% - var(--header-height) - 80px);
    }
    @include tablet-portrait {
      height: 480px;
    }

    @include tablet-small {
      height: 362px;
    }

    @include mobile {
      width: calc(100% - 100px);
    }
  }


  // style de la photo en fond
  .thumbnails {
    display: flex;
    margin: 1rem auto 0;
    padding: 0;
    justify-content: center;

    &:after {
      content: "";
      @include absolute-stretch();
      z-index: -1;
      background-color: rgba($c_sub, 0.70);
    }
  }

  .thumbnail {
    list-style: none;
    z-index: -2;
    cursor: pointer;
    @include absolute-stretch();
    display: none;

    .credits {
      display: none;
    }

    &.is-active {
      display: block;
    }

    .fit-cover {
      height: 100%;
    }

    img {
      width: 100%;
      filter: blur(20px);
      height: 100%;
      object-fit: cover;
    }
  }

  // Contenu de la slide
  .image-wysiwyg {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;

    &:after {
      @include absolute-stretch();
      z-index: 1;
      content: "";
      border-radius: $border-radius-img;
      background-image: linear-gradient(to top, rgba($c_sub, 1), rgba($c_sub, 0.5), rgba($c_sub, 0), rgba($c_sub, 0), rgba($c_sub, 0));
      opacity: 0.9;
      pointer-events: none;
    }

    &.full-image.alignleft {
      figure {
        &:after {
          content: none;
        }
      }
    }
  }

  .caption {
    position: absolute;
    bottom: 0;
    left: calc(var(--slide-gap) * -1 + 5px);
    z-index: 2;
    display: flex;
    align-items: flex-end;
    width: 100%;
    //@include bd(green,1);


    @include desktop {
      justify-content: space-between;
      //left: calc(30px * -1);
      //max-width: 60%;
    }

    @include tablet-portrait {
      flex-direction: column;
      align-items: flex-start;
    }

    @include mobile {
      bottom: -70px;
      left: -20px;
    }

    .caption-texts {

      @include min-tablet-portrait {
        min-width: 551px;
        padding-right: 40px;
        max-width: 70%;
      }
    }

    .surtitre {
      margin-top: initial;
      margin-bottom: 6px;
    }

    h1, .h1 {
      margin-bottom: 0;
      line-height: 1;
    }

    .btn {
      margin-top: 40px;

      @include mobile {
        margin-top: 20px;
      }
    }
  }
}

