.overlay-menu {
	z-index: z-index('mega-menu');
	max-width: 100%;
	opacity: 1;
	visibility: visible;
	pointer-events: none;
	background-color: transparent;
	@include addTransition();

	> * {
		pointer-events: auto;
	}

	.overlay-menu__top {
		position: absolute;
		right: 40px;
		top: 25px;
		z-index: 11;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		@include tablet-small {
			top: auto;
			bottom: 0;
			right: 0;
		}

		> * {
			margin-left: 40px;
		}
	}

	.btn-search {
		color: $c_white;
		display: flex;
		align-items: center;
	}

	//Close mega menu
	.btn-close {
		@include reset-appearance;
		position: initial;
		text-transform: uppercase;
		color: $c_main;
		font-size: 12px;
		height: auto;
		background: none;
		width: auto;
		font-weight: 600;

		&:before {
			color: $c_white;
			font-size: 14px;
			margin-right: 7.5px;
		}

		@include tablet-small {
			height: var(--toolbar-height);
			@include flex-center;
			padding: 28px 25px;
			border-radius: 8px 0 0 0;
		}

		@include can-hover {
			background: none;
			border: none;
			&:before {
				color: $c_main;
			}
		}
	}

	.btn-reserver{
		display: none;
		min-width: auto;
		width: auto;
		padding: 10px 25px;
		position: absolute;
		top: 25px;
		right: 115px;
		z-index: 15;
		@include tablet-portrait{
			display: flex;
			top: 5px;
			right: 145px;
		}
		@include tablet-small{
			top: 20px;
			right: 20px;
		}

		.single-sit & {
			background-color: transparent;
			border-color: white;
		}

		#widget-panier{
			margin-left: 10px;
			.entete{
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23fff'%3E%3Cpath d='M87.08 28.327H74.4v-.32c0-11.2-9.08-20.28-20.279-20.28H45.88c-11.2 0-20.28 9.08-20.28 20.28v.32H12.92A7.92 7.92 0 005 36.247v32.96c0 13.476 10.924 24.4 24.4 24.4h41.201C84.076 93.607 95 82.683 95 69.207v-32.96a7.92 7.92 0 00-7.92-7.92zm-53.88-.32c0-7.003 5.677-12.68 12.681-12.68h8.241c7.002 0 12.68 5.677 12.68 12.68v.32H33.2v-.32zm54.2 41.2c0 9.278-7.521 16.801-16.799 16.801H29.4c-9.279 0-16.801-7.522-16.801-16.801v-32.96a.32.32 0 01.32-.32H25.6v4.44a3.8 3.8 0 107.599 0v-4.44H66.8v4.44a3.8 3.8 0 107.6 0v-4.44h12.68a.32.32 0 01.32.32v32.96z'/%3E%3C/svg%3E");
			}
		}
	}


	//Partie main menu
	&__left {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		// width: 440px;
		width: 33%;
		padding: 120px 40px 40px 115px;
		overflow-x: initial;
		overflow-y: auto;
		display: flex;
		background-color: $c_sub;
		z-index: 10;
		@media screen and (max-height: 680px) and (min-width: 981px) {
			padding: 120px 40px 40px 100px;
		}
		@include desktop {
			padding-left: 55px;
		}
		@include tablet-portrait {
			padding: 100px var(--gutter-menu) 25px var(--gutter-menu);
			width: 50%;
			overflow: auto;
		}

		@include tablet-small {
			width: 100%;
		}

		@include mobile_small {
			padding: 100px var(--gutter-menu) 40px;
		}

		.container {
			margin: 0;
			width: 100%;
			flex-direction: column;
			display: flex;
		}
	}

	//Partie sub menu
	&__right {
		position: absolute;
		top: 0;
		bottom: 0;
		width: 100%;
		left: 33%;
		background-color: $c_sub;
		border-left: 1px solid rgba($c_white, 0.30);
		pointer-events: none;
		@include tablet-portrait {
			width: 50%;
			left: 50%;
		}
		@include tablet-small {
			width: 100%;
			left: 0;
			z-index: 20;
			background-color: transparent;
		}
	}


	--gutter-menu: 60px;
	@include tablet-small {
		--gutter-menu: 40px;
	}
	@include mobile {
		--gutter-menu: 35px;
	}

	// Nav primary
	// ==========================================================================
	.nav-primary {
		display: block;
		//a et label
		&__link {
			@include ft($f_hx, 40px, 300, $c_white, 1);
			@include addTransition();
			position: relative;
			display: block;
			padding: 20px 0;
			cursor: pointer;
			@media screen and (max-height: 650px) and (min-width: 981px) {
				font-size: 35px;
				line-height: 30px;
				padding: 10px 0;
			}
			@include mobile {
				font-size: 35px;
				line-height: 30px;
				padding: 15px 0;
			}

			@include can-hover {
				color: $c_main;
			}


			@include min-tablet-small {
				&:after {
					content: "";
					position: absolute;
					right: -40px;
					@include tablet-portrait {
						right: calc(var(--gutter-menu) * -1);
					}
					top: 50%;
					transform: translate(0, -50%);
					background-color: $c_main;
					opacity: 0;
					height: 4px;
					z-index: 1;
					width: 35px;
				}

			}

			&.actif {
				color: $c_main;

				&:after {
					opacity: 1;
				}
			}
		}


		&__input {
			display: none;

			&:checked {
				+ .nav-primary__submenu-wrapper {
					opacity: 1;
					pointer-events: auto;
				}
			}
		}

		//Sous-menus
		&__submenu-wrapper {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background-color: $c_txt;
			opacity: 0;
			display: flex;
			max-width: 67%;
			pointer-events: none;
			padding: 0 0 0 0;
			overflow: auto;
			@include addTransition();
			@media screen and (max-height: 680px) and (min-width: 981px) {
				//   padding: 80px 60px;
			}
			@media screen and (max-width: 1150px) {
				//   padding-left: 80px;
			}
			@include tablet-portrait {
				//   padding: 60px 40px;
				max-width: 100%;
				flex-direction: column;
			}
			@include mobile {
				//  padding: 60px 20px;
			}

			// div link et slider
			> * {
				width: 50%;
				max-width: 50%;
				flex-grow: 1;
				padding-top: 120px;

				@include tablet-portrait {
					width: 100%;
					max-width: 100%;
					padding-top: 80px;
				}

				@include tablet-small {
					padding-top: 45px;
				}

			}

			&__submenu-back {
				position: relative;
				text-transform: uppercase;
				@include ft($f_hx, 20px, 700, $c_white, 1);
				margin-bottom: 20px;
				cursor: pointer;
				justify-content: center;
				text-align: center;

				span {
					font-size: 15px;
					margin-right: 5px;
					margin-top: 1px;
				}

				@include can-hover {
					color: $c_main;
				}

				@include tablet-small {
					display: flex;
				}

				@include mobile {
					font-size: 14px;
					span {
						margin-top: 0;
						font-size: 12px;
					}
				}
			}

			&__submenu-title {
				@include ft($f_hx, 35px, 300, $c_main, 1);
				margin-bottom: 30px;
				padding-bottom: 15px;
				position: relative;

				&:after {
					content: '';
					display: block;
					height: 3px;
					width: 45px;
					background-color: $c_main;
					position: absolute;
					left: 0;
					bottom: 0;
				}
			}

			&__child {
				display: flex;
				flex-direction: column;
				padding-left: 100px;
				padding-right: 120px;
				overflow-y: auto;
				padding-bottom: var(--gutter-menu);
				@include desktop {
					padding-left: var(--gutter-menu);
					padding-right: var(--gutter-menu);
				}

				@include tablet-portrait {
					height: 100%;
				}

				@include tablet-small {
					align-items: flex-start;
				}
			}

			&__link {
				position: relative;
				display: block;
				@include ft($f_txt, 18px, 400, $c_white, 28px);
				padding: 12.5px 0;
				@include addTransition();
				@media screen and (max-height: 680px) and (min-width: 981px) {
					font-size: 18px;
					line-height: 24px;
					padding: 10px 0;
				}
				@include mobile {
					font-size: 16px;
					line-height: 28px;
					padding: 12.5px 0;
				}
				@include can-hover {
					color: $c_main;
				}
			}


			// bloc slider
			&__slider {
				padding-left: var(--gutter-menu);
				padding-right: var(--gutter-menu);
				padding-bottom: var(--gutter-menu);
				background-image: url("../images/deco/radial-menu.jpg");
				background-size: cover;
				height: 100vh;
				position: relative;

				@include min-tablet-small {
					overflow: hidden;
				}

				@include tablet-small {
					justify-content: center;
					display: flex;
					flex-direction: column;
					&:before {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						height: 100px;
						z-index: 0;
						content: "";
						transform: translate(0, -100%);
						background-image: linear-gradient(to top, rgba($c_sub, 1), rgba($c_sub, 0));
						pointer-events: none;
					}
				}

				> * {
					color: $c_white;
					// position: relative;
					z-index: 1;
				}

				@include tablet-portrait {
					padding-top: 35px;
				}

				@include tablet-small {
					max-height: 211px;
				}

				@include mobile {
					max-height: 230px;
				}

				@include mobile-small {
					max-height: 260px;
					padding: 20px;
				}

				&__title {
					position: relative;
					@include ft($f_hx, 18px, 400, "", 20px);
					margin-bottom: 30px;
					padding-bottom: 15px;
					@include tablet-small {
						@include ft("", $s_p, "", "", "");
						margin-bottom: 20px;
						padding-bottom: 10px;
					}

					&:after {
						content: '';
						display: block;
						height: 3px;
						width: 60px;
						background-color: $c_main;
						position: absolute;
						left: 0;
						bottom: 0;

						@include mobile {
							width: 40px;
						}
					}
				}

				.splide__slide {

					@include mobile {
						max-width: 280px;
						margin-right: 20px;
						width: calc(100% - 40px);
					}
				}
			}
		}
	}


	// Nav secondary
	// ==========================================================================
	.nav-secondary {
		display: block;
		//ul
		&__menu {
			padding: 0;
			margin-top: 35px;
			@media screen and (max-height: 650px) and (min-width: 981px) {
				margin-top: 15px;
			}
		}

		&__link {
			@include ft($f_txt, 14px, 600, $c_white, 28px);
			@include addTransition();
			position: relative;
			text-transform: uppercase;
			display: block;
			padding: 10px 0;
			@media screen and (max-height: 650px) and (min-width: 981px) {
				font-size: 16px;
				line-height: 22px;
				padding: 5px 0;
			}
			@include mobile {
				font-size: 14px;
				line-height: 28px;
				padding: 10px 0;
			}

			@include can-hover {
				color: $c_main;
			}
		}
	}

	// social
	// ==========================================================================
	.social-links-lang{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 30px;
	}
	.social-links {
		a {
			&:not(:last-child) {
				margin-right: 30px;
			}
		}
	}
	.lang-mobile{
		display: none;
		flex-shrink: 0;
		border-bottom: 1px solid $c_main;
		align-items: center;
		justify-content: space-between;
		font-size: 12px;
		text-transform: uppercase;
		color: white;
		padding-bottom: 3px;
		@media screen and (max-width: 1280px){
			display: flex;
		}
		&:after{
			position: relative;
			@include icomoon($icon-arrow-down);
			margin-left: 6px;

		}
		select{
			font-size: 12px;
			text-transform: uppercase;
			color: white;
			height: 23px;
			font-weight: 700;
			display: block;
			padding: 0;
		}
	}

	// baseline
	.baseline {
		padding-top: 50px;
		margin-top: auto;
	}
}
