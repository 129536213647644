.bloc-images {
  background-color: $c_sub;
  padding-top: var(--section-spacing);
  padding-bottom: var(--section-spacing);

  .wrapper {
    @include mobile {
      margin-left: 35px;
      margin-right: 35px;
    }
  }

  .slider-images {
    @include mobile {
      display: flex;
      padding-bottom: 100px;
      flex-direction: column-reverse;
    }
  }

  // Mode slider
  .splide__slide {
    //  max-width: calc(920px - var(--border-gutter));
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: var(--slide-gap);
    @include addTransition();

    --slide-gap: 70px;
    @include tablet-portrait {
      --slide-gap: 50px;
    }
    @include tablet-small {
      --slide-gap: 35px;
    }

    .image-wysiwyg {
      height: calc(100% - 190px);
      opacity: 0.60;
      @include addTransition();

      @include tablet-portrait {
        height: calc(100% - 60px);
      }
    }

    .caption-wrapper {
      opacity: 0;
      @include addTransition();

      &:after {
        opacity: 0;
      }
    }

    &.is-active {
      @include addTransition();
      height: 100%;

      .image-wysiwyg {
        @include addTransition();
        margin: 0 auto;
        height: 100%;
        opacity: 1;
        @include tablet-portrait {
          height: 100%;
        }
      }

      .caption-wrapper {
        opacity: 1;
        @include addTransition();
      }
    }
  }

  .container-nav {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: left;
    justify-content: flex-start;
    margin-bottom: 0;
    @include mobile {
      margin-bottom: var(--medium-margin);
    }

    @include min-mobile {
      margin-top: var(--medium-margin);
    }
  }

  .nav-buttons {
    @include absolute-stretch();

    .btn-arrow {
      position: absolute;
      top: 50%;

      &.--prev {
        left: 0;
        transform: translate(0, -50%);
      }

      &.--next {
        right: 0;
        transform: translate(0, -50%);
      }
    }
  }

  .image-wysiwyg {
    margin-right: 0;
    --aspect-ratio: 920/610;

    @include tablet-portrait {
      --aspect-ratio: 350/470;
      img {
        //  min-height: 470px;
      }
    }

    figure {
      // supprime le voile par défaut de la classe image-wysiwyg
      &:after {
        display: none;
      }
    }
  }

  .img-wrapper {
    height: 100%;
  }

  figcaption {
    display: none;
  }

  .caption-wrapper {
    .caption {
      position: relative;
      right: initial;
      left: initial;
      bottom: initial;
    }

    .btn-primary {
      background-color: $c_main;
      color: $c_white;
    }

    .btn {
      border-color: $c_main;
    }
  }

  // gradient sur le caption wrapper
  .caption-wrapper {
    // @include aspect-ratio(var(--aspect-ratio), null);
    position: absolute; // Surcharge de la position relative définie par défaut dans le mixin d'aspect-ratio
    //  top: 0;
    bottom: -25px;
    width: 100%;
  }

  .image-wysiwyg {
    &:after {
      @include absolute-stretch();
      z-index: 1;
      content: "";
      border-radius: $border-radius-img;
      background-image: linear-gradient(45deg, rgba($c_sub, 1), rgba($c_sub, 0), rgba($c_sub, 0));
      opacity: 0.8;
      pointer-events: none;
    }
  }

  .image-wysiwyg.alignleft {
    .caption-wrapper {
      left: calc(var(--border-gutter) * -1);

      @include desktop {
        left: calc((var(--border-gutter) / 2) * -1);
      }

      @include mobile {
        left: calc(var(--border-gutter) * -1);
      }
    }

    &:after {
      background-image: linear-gradient(45deg, rgba($c_sub, 1), rgba($c_sub, 0), rgba($c_sub, 0));
    }
  }

  .image-wysiwyg.alignright {
    .caption-wrapper {
      right: calc(var(--border-gutter) * -1);
      @include desktop {
        right: calc((var(--border-gutter) / 2) * -1);
      }

      @include mobile {
        right: calc(var(--border-gutter) * -1);
      }
    }

    &:after {
      background-image: linear-gradient(-45deg, rgba($c_sub, 1), rgba($c_sub, 0), rgba($c_sub, 0));
    }
  }

  .image-wysiwyg.aligncenter {
    &:after {
      background-image: linear-gradient(to top, rgba($c_sub, 1), rgba($c_sub, 0), rgba($c_sub, 0));
    }
  }
}