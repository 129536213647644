.bloc-form {
	legend.frm_screen_reader {
		display: none;
	}

	.frm_error_style,
	.frm_success_style,
	.frm_message {
		padding: 16px;
		margin-bottom: $bloc-spacing;
		border: 1px solid $c_green;
		font-size: 14px;
		color: $c_green;
		background-color: rgba($c_green, 0.1);
		border-radius: initial;

		p:last-child {
			margin-bottom: 0;
		}
	}

	.frm_error_style { // message d'erreur global au formulaire. Différent des messages sous chaque champs
		border-color: $c_error;
		color: $c_error;
		background-color: rgba($c_error, 0.1);
	}

	.frm_success_style {
		border-color: $c_green;
		color: $c_green;
		background-color: rgba($c_green, 0.2);
	}

	.form-field { // wrapper autour de chaque "bloc" / champs défini dans le BO
		position: relative;
		margin-bottom: 25px;
		width: 100%;

		@include mobile {
			margin-bottom: 20px;
		}

		// Style par défaut des labels
		.frm_primary_label {
			@include ft('', 14px, 600, $c_txt, 1.42);
			margin-bottom: 10px;

			.frm_required {
				// color: $c_red;
			}
		}

		// style par défaut des champs de saisie
		input[type="text"],
		input[type="email"],
		input[type="password"],
		input[type="number"],
		input[type="date"],
		input[type="tel"],
		textarea {
			@include ft('', 14px, 300, $c_txt, 1.42);
			width: 100%;
			padding: 20px 30px;
			border-radius: 6px;
			border: 1px solid rgba($c_sub, 0.10);
			@include addTransition();

			&:hover, &:focus {
				body:not(.no-hover) & {
					border-color: $c_main;
				}
			}

			&:focus {
				body:not(.no-hover) & {
					color: $c_txt;
				}
			}

			&::placeholder {
				color: rgba $c_txt;
			}
		}

		//  non supporté dans nos maquettes et donc masqué au cas ou le client le renseignerait quand même
		.frm_description {
			display: none;
		}

		// message d'erreur sous chaque champ (champ obligatoire, mail non valide...)
		.frm_error {
			padding: 8px 0;
			@include ft('', 12px, 500, $c_error, "");
		}


		// --- wrapper des radios et checkbox ---
		// --------------------------------------
		&[class*="radio"],
		&.frm_two_col,
		&.frm_three_col,
		&.frm_four_col {
			margin-bottom: $bloc-spacing;
		}

		// Corrections sur le comportement de la grille des radios et checkbox
		&.frm_two_col,
		&.frm_three_col,
		&.frm_four_col {
			// par défaut, elles font (par exemple) 50% de leur propore colonne, soit au final que 25% de large.
			.frm_radio {
				width: 100%;
			}
		}

		// absence par défaut de réorganisation des colonnes en responsive
		// un affichage 4 colonnes restait sur 4 colonnes en mobile... pas ouf
		&.frm_four_col .frm_opt_container,
		&.frm_three_col .frm_opt_container {
			@include tablet_portrait {
				grid-template-columns: repeat(2, 1fr);
			}
		}

		&.frm_four_col .frm_opt_container,
		&.frm_three_col .frm_opt_container,
		&.frm_two_col .frm_opt_container {
			@include mobile {
				grid-template-columns: repeat(1, 1fr);
			}
		}

		/* Style for Radio & Checkbox */
		.frm_opt_container {

			/* Style for Checkbox */
			.frm_checkbox {
				position: relative;

				input {
					display: block;

					&:checked {
						~ span:after {
							opacity: 1;
							transform: scale(1);
						}
					}
				}

				label {
					> span {
						&:before {
							content: '';
							position: absolute;
							top: 0;
							left: 0;
							width: 20px;
							height: 20px;
							border-radius: 3px;
							//   border: 1px solid rgba($c_sub, 0.20);
							//  border-radius: 2px;
							cursor: pointer;
							@include addTransition();
						}

						&:after {
							@include icomoon();
							content: $icon-checkmark;
							position: absolute;
							display: flex;
							align-items: center;
							border-radius: 3px;
							justify-content: center;
							top: 0;
							left: 0;
							width: 20px;
							height: 20px;
							background: $c_main;
							opacity: 0;
							color: white;
							font-size: 10px;
							transform: scale(0);
							@include addTransition();
						}
					}
				}
			}
		}

		// *** Style des champs email et mot de passe (icône)
		&.input-is-email,
		&.input-is-tel,
		&.input-is-password,
		&.input-is-date {
			position: relative;

			&:after {
				@include icomoon();
				position: absolute;
				right: 20px;
				top: 50px;
				font-size: 20px;
				color: $c_txt;
				pointer-events: none;
			}

			input {
				padding-right: 50px;
			}
		}

		&.input-is-email:after {
			content: $icon-email;
		}

		&.input-is-tel:after {
			content: $icon-phone;
		}

		&.input-is-password:after {
			//content: $icon-soleil;
		}

		&.input-is-date:after {
			content: $icon-agenda;
		}


		// *** Style du champ toggle
		&.input-is-switch {

			// label qui englobe le switch
			.frm_switch_block {
				display: inline-grid;
				grid-template-columns: auto auto;
				align-items: center;
				cursor: pointer;
			}

			.frm_switch_opt {
				grid-column: 2;
				grid-row: 1;
				@include ft('', 16px, 300, rgba($c_txt, 0.7));
				display: block;
				padding: 0 10px;
				white-space: normal;

				&.frm_on_label {
					color: $c_txt;
					display: none;
				}
			}

			.frm_switch_block input {
				position: absolute;
				opacity: 0;
			}

			.frm_switch {
				grid-column: 1;
				grid-row: 1;
				position: relative;
				display: block;
				width: 32px;
				height: 18px;
			}

			.frm_slider {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				border: 1px solid rgba($c_sub, 0.20);
				cursor: pointer;
				background-color: $c_white;
				border-radius: 50px;
				transition: .25s;
			}

			.frm_slider:before {
				content: '';
				position: absolute;
				left: 3px;
				top: 3px;
				width: 10px;
				height: 10px;
				background-color: $c_sub;
				border-radius: 50%;
				box-shadow: initial;
				transition: .25s;
			}

			input:checked + .frm_switch .frm_slider:before {
				transform: translateX(14px);
				background-color: $c_main;
			}

			.frm_switch_block.is-checked {
				.frm_off_label {
					display: none;
				}

				.frm_on_label {
					display: block;
				}
			}
		}
	}


	.frm_verify {
		display: none;
	}

	& .form-field .frm_radio,
	& .form-field .frm_checkbox, // wrapper de chaque option
	& .group-checkbox .frm_checkbox { // styles également appliqués aux facettes
		position: relative;
		width: 100%;

		& + .frm_radio,
		& + .frm_checkbox {
			margin-top: 10px;
		}

		label {
			position: relative;
			display: inline-block;
			padding-left: 30px;
			padding-right: 10px;
			padding-top: 2px;
			@include ft('', 14px, 400, $c_txt, "");
			@include addTransition();
			text-indent: 0;
			cursor: pointer;

			// Styles spécifiques aux facettes
			&.facette-checkbox {
				padding-top: 1px;
				font-size: 16px;
				font-weight: 400;
			}
		}

		input {
			position: absolute;
			opacity: 0;
			left: 0;
			top: 0;
			width: 100%;
			cursor: pointer;
		}

		span { // faux radio input stylisable à souhait. Ajouté en JS.
			position: absolute;
			left: 0;
			top: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 20px;
			height: 20px;
			border-radius: 3px;
			box-shadow: inset 0 0 0 1px rgba($c_sub, 0.20); // box-shadow plutôt que border pour avoir le :after aux mêmes dimensions que le parent
			@include addTransition();
			pointer-events: none;

			&:after {
				content: '';
				position: relative;
				width: 10px;
				height: 10px;
				background-color: $c_main;
				opacity: 0;
				@include addTransition();
			}
		}

		input:checked ~ span {
			box-shadow: inset 0 0 0 1px $c_main !important;

			&:after {
				opacity: 1;
			}
		}

		// Classe injecté en js sur le parent pour pouvoir stylisé le label actif
		&.is-checked {
			label {
				color: $c_txt;
			}
		}

		label:hover {
			body:not(.no-hover) & {
				color: $c_txt;

				span {
					box-shadow: inset 0 0 0 1px $c_main;
				}
			}
		}
	}


	.form-field .frm_radio {
		span { // faux radio input stylisable à souhait. Ajouté en JS.
			border-radius: 50%;

			&:after {
				border-radius: 50%;
			}
		}
	}


	.form-field.horizontal_radio { // version horizontale (radio comme checkbox)
		.frm_radio,
		.frm_checkbox { // wrapper de chaque option
			margin-right: 20px;
			width: auto;
		}
	}

	div[class*="frm_"][aria-hidden="true"], .frm_none_container{
		display: none;
	}
}

//cacher le badge recaptcha qui se ballade
.grecaptcha-badge{
	visibility: hidden !important;
	pointer-events: none !important;
}