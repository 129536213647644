// Les styles des facettes sont appliqués singulièrement au niveau de chaque facette,
// afin de pouvoir les avoir bien affichés dans l'overlay filtres mais aussi dehors


// Classe utilisée pour mettre en forme les select fields gérés par la librairie Selectric
.group-select {
  .selectric-wrapper {
    max-width: 280px;

    .selectric-items {
      .selectric-scroll {
        ul {
          li {
            max-width: none;
          }
        }
      }
    }

    .selectric {
      min-height: 60px;
      border: 1px solid rgba($c_sub, 0.25);
      width: 100%;
      display: flex;
      align-items: center;
      padding: 20px;

      .label {
        font-size: 16px;
        line-height: 1.25;
        height: auto;
        padding: 0;

        &:after {
          right: 20px;
        }
      }
    }
  }
}

// Styles appliqués aux labels et des fois à la balise legend dans les fieldset
.title-facette {
  @include ft($f_hx, 20px, 700, $c_txt, 1.2);
  display: block;

  & + * {
    margin-top: 20px;
  }
}

// Styles pour les facettes checkbox
// -> styles communs dans _bloc-form
.facette-checkbox {

}


// Styles avec les labels actif / inactif
// label / titre pour la facette switch
.facette-switch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  // div qui englobe le switch
  .frm_switch_block {
    display: inline-grid;
    grid-template-columns: auto auto;
    grid-template-areas: "label switch";
    align-items: center;
    //@include bd(red,1);
  }

  // Labels actifs / inactifs
  .frm_switch_opt {
    grid-area: label;
    @include ft('', 14px, 400, rgba($c_txt, 0.3));
    font-style: italic;
    display: block;
    padding: 0 10px;
    white-space: normal;

    &.frm_on_label {
      color: $c_txt;
      display: none;
    }
  }

  .frm_switch_block input {
    position: absolute;
    opacity: 0;
  }

  // Toggle
  .frm_switch {
    grid-area: switch;
    position: relative;
    display: block;
    width: 34px;
    height: 20px;
    border: 1px solid rgba($c_txt,.2);
    border-radius: 25px;
  }

  .frm_slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    border-radius: 50px;
    transition: .25s;
  }

  .frm_slider:before {
    content: '';
    position: absolute;
    left: 4px;
    top: 4px;
    width: 10px;
    height: 10px;
    background-color: $c_txt;
    border-radius: 50%;
    box-shadow: initial;
    transition: .25s;
  }

  input:checked + .frm_switch .frm_slider {

    &:before {
      transform: translateX(13px);
      background-color: $c_main;
    }
  }

  .frm_switch_block.is-checked {
    .frm_off_label {
      display: none;
    }

    .frm_on_label {
      display: block;
    }
  }
}


// Facette classement
.wrapper-facettes-classement {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
}

.facette-classement {
  display: flex;
  flex-direction: column;

  .title-group {
    display: block;
    margin-bottom: 5px;
  }

  // @todo : à supprimer si plus besoin d'indiquer le nombre sélectionné
  .total {
    @include ft('', 13px, '', $c_white);
    margin-left: 10px;
    padding: 4px 10px 5px 10px;
    background-color: $c_sub;
    display: none;
  }

  .classement-chk {
  }

  .classement-row {
    display: grid;
    grid-template-columns: 20px auto;
    gap: 10px;

    & + .classement-row {
      margin-top: 5px;
    }
  }

  .custom-checkbox { // faux radio input stylisable à souhait
    grid-column: 1;
    grid-row: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 1px $c_sub; // box-shadow plutôt que border pour avoir le :after aux mêmes dimensions que le parent
    pointer-events: none;
    @include addTransition();

    &:after {
      content: '';
      position: relative;
      width: 10px;
      height: 10px;
      background-color: $c_main;
      opacity: 0;
      @include addTransition();
    }
  }

  label {
    grid-column: 1 / 3;
    grid-row: 1;
    display: inline-flex;
    align-items: center;
    width: max-content;
    padding-left: 30px;
    padding-right: 10px;
    font-size: 14px;
    cursor: pointer;

    span {
      color: rgba($c_txt, 0.2);

      &:first-child {
        margin-left: 5px;
      }

      & + span {
        margin-left: 4px;
      }
    }
  }

  label:hover ~ .custom-checkbox {
    box-shadow: inset 0 0 0 1px $c_main;
  }


  input:checked ~ .custom-checkbox {
    box-shadow: inset 0 0 0 1px $c_main !important;

    &:after {
      opacity: 1;
    }
  }

  input:checked + label span {
    &.icon-etoile {
      color: $c_txt;
    }

    &.icon-epis {
      color: $c_txt;
    }

    &.icon-cle {
      color: $c_txt;
    }

    &.icon-toque {
      color: $c_txt;
    }
  }

  input:not(:checked):hover + label span {
    &.icon-etoile {
      color: rgba($c_txt, 0.4);
    }

    &.icon-epis {
      color: rgba($c_txt, 0.4);
    }

    &.icon-cle {
      color: rgba($c_txt, 0.4);
    }

    &.icon-toque {
      color: rgba($c_txt, 0.4);
    }
  }

  input.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    height: auto;
  }
}


.group-date {
  display: flex;
  @include mobile {
    flex-direction: column;
  }

  > div.wrapper-date {
    padding-left: 20px;
    position: relative;
    width: auto;
    flex-basis: auto;
    flex-shrink: 0;
    display: flex;
    align-items: center;

    &:first-of-type {
      border-radius: 6px 0 0 6px;
      border: 1px solid $c_main;

      label.date-label, input:not(.fakeinput) {
        //	color: white;
      }

      @include mobile {
        border-radius: 6px 6px 0 0;
      }
    }

    &:not(:first-of-type) {
      border: 1px solid $c_main;
      border-radius: 0 6px 6px 0;
      border-left: 0;
      @include mobile {
        border-top: 0;
        border-left: 1px solid $c_main;
        border-radius: 0 0 6px 6px;
      }
    }

    [class^="icon-"], [class*=" icon-"] {
      font-size: 14px;
      margin-right: 10px;
    }

    label {
      display: inline-block;
      margin-bottom: 0;
      position: relative;
      top: 0;
      left: 0;
      flex-shrink: 0;
      font-size: 1rem;
      line-height: 20px;

      @include tablet-small {
        font-size: 14px;
      }
    }
  }


  input:not(.fakeinput) {
    @include ft($f_txt, $s_link, 700, $c_txt, 1);
    padding: 16px 15px 16px 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    -webkit-appearance: none;
    resize: none;
    -webkit-box-shadow: none;
    outline: none;
    margin: 0;
    box-shadow: none;
    border: none;
    border-radius: 0;
    background: none;
    text-align: left;

    @include mobile {
      width: 100%;
      min-height: 56px;
    }

    &::-webkit-date-and-time-value {
      text-align: left;
    }
  }

  .fakeinput {
    opacity: 0;
    position: absolute;
    left: 0;
    //width: 390px;
    margin-top: 20px;
    height: 60px;
    padding: 0;
  }
}

