// Fil d'ariane
// ==========================================================================
.breadcrumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 34px 0 30px 0;
  text-overflow: ellipsis;

  @include tablet-portrait {
    margin-top: 20px;
  }
  @include mobile {
    margin: 15px 0;
  }

  .breadcrumbs__item {
    position: relative;
    padding: 0;

    &:not(:last-of-type) {
      &:after {
        @include icomoon();
        content: $icon-arrow-right;
        position: absolute;
        right: 16px;
        top: 50%;
        font-size: 10px;
        color: $c_txt;
        transform: translateY(-50%);
        @include mobile {
          right: 5px;
        }
      }
    }

    &:first-of-type {
      .breadcrumbs__link, span {
        padding-left: 0;
      }
    }

    &:last-of-type {
      .breadcrumbs__link, span {
        padding-right: 0;
      }
    }

    .breadcrumbs__link,
    span {
      position: relative;
      @include ft('', $s_link_small, 500, $c_txt, $s_link_small+15);
      display: inline-block;
      padding: 0 40px 0 0;
      white-space: nowrap;


      @include mobile {
        padding: 0 20px 0 0;
      }
    }

    .breadcrumbs__link {
      @include addTransition();

      @include can-hover {
        color: $c_main;
      }
    }

    span {
      color: rgba($c_txt, 0.50);
    }
  }


  // --modifiers
  &.--text-white {
    .breadcrumbs__item {
      &:after {
        background-color: $c_white;
      }

      .breadcrumbs__link {
        color: $c_white;

        @include can-hover {
          color: rgba($c_white, 0.75);
        }
      }

      span {
        color: rgba($c_white, 0.5);
      }
    }
  }
}
