a {
	text-decoration: none;
	color: $c_main;
	@include addTransition();
}

li {
	list-style-type: none;
}


// Styles de textes globaux
// ==========================================================================

h1, .h1 {
	display: block;
	position: relative;
	padding-bottom: 30px;
	@include ft($f_hx, $s_h1, 300, $c_txt, 65px);

	@include mobile {
		padding-bottom: 15px;
	}

	&:after {
		content: '';
		display: block;
		height: 4px;
		width: 200px;
		background-color: $c_main;
		position: absolute;
		left: 0;
		bottom: 0;

		@include mobile {
			width: 100px;
		}
	}

	@include tablet_portrait {
		font-size: 50px;
		line-height: 0.8;
	}
	@include mobile {
		font-size: 40px;
		line-height: 0.88;
	}
	@include mobile-small {
		font-size: 30px;
		line-height: 0.93;
	}
}

h2, .h2 {
	display: block;
	@include ft($f_hx, $s_h2, 300, $c_txt, 1);
	position: relative;
	padding-bottom: 20px;

	&:after {
		content: '';
		display: block;
		height: 3px;
		width: 100px;
		background-color: $c_main;
		position: absolute;
		left: 0;
		bottom: 0;
	}

	@include tablet_portrait {
		font-size: 40px;
		line-height: 0.95;
	}
	@include mobile {
		font-size: 34px;
		line-height: 35px;
	}
	@include mobile-small {
		font-size: 28px;
		line-height: 0.82;
	}
}

h3, .h3 {
	display: block;
	@include ft($f_hx, $s_h3, 400, $c_txt, 1);

	@include tablet_portrait {
		font-size: 35px;
	}
	@include mobile {
		font-size: 30px;
	}
	@include mobile-small {
		font-size: 20px;
	}

	&.small {
		@include ft($f_hx, 30px, bold, $c_txt, 1);

		@include tablet-portrait {
			font-size: 20px;
		}

		@include mobile {
			font-size: 22px;
		}

		@include mobile-small {
			font-size: 16px;
		}
	}

	&.--with-pattern {
		position: relative;

		&:after {
			content: '';
			display: block;
			height: 3px;
			@include addTransition();
			width: 100px;
			background-color: $c_main;
			position: absolute;
			left: 0;
			bottom: -20px;
		}
	}
}


// Styles de textes additionnels
// ==========================================================================

.surtitre {
	display: block;
	@include ft($f_txt, 12px, 600, $c_main, 15px);
	text-transform: uppercase;
}

.location {
	@include ft($f_txt, 12px, 600, $c_txt, 1.15);
	letter-spacing: 0.4px;
	position: relative;
	display: block;
	padding-left: 19px;
	@include addTransition();

	&:before {
		@include addTransition();
		@include icomoon($icon-pin);
		font-size: 14px;
		color: $c_main;
		position: absolute;
		top: 0;
		left: 0;
	}
}


// Styles et positionnement au sein d'un bloc de texte
// ==========================================================================

.text-styles {
	// Couleurs par défaut des styles de textes si fond clair
	--color-text: #{$c_txt};
	--color-heading: #{$c_txt};
	--color-surtitre: #{$c_main};
	--color-link: #{$c_main};
	--color-link-hover: #{$c_main};

	--color-btn: #{$c_txt};
	--color-btn-hover: #{$c_white};
	--border-color-btn: #{$c_main};

	--border-color-btn-primary: #{$c_main};
	--color-btn-primary: #{$c_white};
	--bg-color-btn-primary: #{$c_main};

	--color-btn-underline: #{$c_txt};
	--border-color-btn-underline: #{$c_main};

	// Alignement
	&.aligncenter {
		margin-left: auto;
		margin-right: auto;

		h2, .h2 {
			&:after {
				left: 50%;
				transform: translate(-50%, 0);
			}
		}

		> * {
			margin-left: auto;
			margin-right: auto;
		}
	}

	&.alignright {
		margin-left: auto;
		margin-right: 0;


		h2, .h2 {
			&:after {
				right: 0;
				left: auto;
			}
		}

		> * {
			margin-left: auto;
			margin-right: 0;
		}
	}

	// Gestion des alignements et du max-width des éléments
	> * {
		position: relative;

		&:first-child {
			margin-top: 0 !important;
		}

		&:last-child {
			margin-bottom: 0 !important;

			.btn {
				margin-bottom: 0;
			}
		}

		&.aligncenter, &[style*="text-align: center"] {
			margin-left: auto;
			margin-right: auto;
		}

		&.alignright, &[style*="text-align: right"] {
			margin-left: auto;
			margin-right: 0;
		}

		&[style*="text-align: justify"] {
			margin-left: 0;
			margin-right: 0;
			max-width: 100% !important;
		}
	}

	.surtitre {
		color: var(--color-surtitre);
		margin-top: 35px;
		margin-bottom: 12px;

		& + h2,
		& + .h2 {
			margin-top: -3px;

			@include mobile {
				margin-top: -5px;
			}
		}

		& + h3,
		& + .h3 {
			margin-top: -10px;

			@include tablet-portrait {
				margin-top: -5px;
			}
		}
	}

	// Styles de textes
	h1, .h1,
	h2, .h2,
	h3, .h3 {
		color: var(--color-heading);
	}

	h1, .h1 {
		margin-bottom: 20px;

		& + p {
			margin-top: 10px;
		}

		@include mobile {
			margin-bottom: 10px;
		}
	}

	h2, .h2 {
		margin-top: 35px;
		margin-bottom: 40px;

		& + .surtitre {
			margin-top: -5px;
			margin-bottom: 25px;

			@include mobile {
				margin-bottom: 20px;
			}
		}

		& + h3,
		& + .h3 {
			margin-top: -10px;

			@include tablet-portrait {
				margin-top: 0;
			}
		}

		& + .btn-primary,
		& + .btn {
			margin-top: 10px;
		}
	}

	h3, .h3 {
		margin-top: 35px;
		margin-bottom: 16px;

		& + .surtitre {
			margin-top: -15px;
			margin-bottom: 25px;

			@include tablet-portrait {
				margin-top: -10px;
			}
			@include mobile {
				margin-bottom: 20px;
			}
		}

		& + .btn-primary, & + .btn {
			margin-top: 10px;
		}
	}

	p:not([class]) {
		@include ft($f_txt, $s_p, 400, var(--color-text), 26px);
		display: block;
		margin-bottom: 15px;

		@include mobile {
			font-size: 14px;
			line-height: 24px;
		}

		& + .btn-primary,
		& + .btn {
			margin-top: 10px;
		}
	}

	li:not([class]) {
		position: relative;
		@include ft($f_txt, $s_p, 400, var(--color-text), 1.5);

		&[style*="text-align: center"] {
			margin-left: auto;
			margin-right: auto;
		}

		&[style*="text-align: right"] {
			margin-left: auto;
			margin-right: 0;
		}
	}

	ul:not([class]) {
		padding: 0;
		margin-bottom: 15px;
		list-style-type: none;

		--ul-padding: 8px;
		@include mobile {
			--ul-padding: 10px;
		}

		li {
			padding: var(--ul-padding) 0 var(--ul-padding) 20px;

			&[style*="text-align: right"] {
				padding: var(--ul-padding) 20px var(--ul-padding) 0;

				&:before {
					left: auto;
					right: 0;
				}
			}

			&[style*="text-align: center"] {
				padding: var(--ul-padding) 0 var(--ul-padding) 0;

				&:before {
					display: none;
				}

				&:after {
					@include icomoon($icon-puce);
					position: relative;
					display: block;
					font-size: 5px;
					color: $c_main;
					bottom: -5px;
				}

				&:last-of-type {
					&:after {
						display: none;
					}
				}
			}

			&:before {
				content: '';
				@include icomoon($icon-puce);
				position: absolute;
				left: 0;
				top: 17px;
				font-size: 5px;
				color: $c_main;
			}
		}
	}

	ol:not([class]) {
		padding: 0;
		margin-bottom: 15px;
		list-style-type: decimal;
		list-style-position: inside;

		--ol-padding: 8px;
		@include mobile {
			--ol-padding: 10px;
		}

		li {
			list-style-type: decimal;
			padding: var(--ol-padding) 0;

			&::marker {
				color: $c_main;
			}
		}
	}

	a:not([class]) {
		color: var(--color-link);
		text-decoration: underline;

		@include can-hover {
			color: var(--color-link-hover);
			opacity: 0.7;
		}
	}

	blockquote:not([class]) {
		margin: 35px 0 35px 0;
		position: relative;
		padding-top: 20px;

		@include mobile {
			margin: 30px 0;
		}

		&:before {
			content: '';
			display: block;
			height: 3px;
			width: 100px;
			background-color: $c_main;
			position: absolute;
			left: 0;
			top: 0;
		}

		p {
			@include ft($f_txt, 20px, 400, var(--color-text), 28px);
			position: relative;

			@include mobile {
				font-size: 18px;
				line-height: 26px;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.btn {
		color: var(--color-btn);
		border-color: var(--border-color-btn);
		margin-top: 15px;
		margin-bottom: 20px;

		@include mobile {
			margin-bottom: 5px;
		}

		@include can-hover {
			color: var(--color-btn-hover);
		}

		&.btn-primary {
			border-color: var(--border-color-btn-primary);
			color: var(--color-btn-primary);
			background-color: var(--bg-color-btn-primary);
		}
	}

	.btn-underline {
		color: var(--color-btn-underline);
		border-color: var(--border-color-btn-underline);
	}

	// Gestion iFame
	iframe:not(.instagram-media) {
		width: 100%;
		height: 430px;

		@include mobile {
			height: 100%;
		}
	}

	.instagram-media {
		margin: 30px auto !important;
	}

	// Gestion des éléments dans le Wysiwyg
	p, .wp-caption {
		margin-bottom: 20px;

		iframe {
			margin-bottom: 0;
		}

		&.alignleft {
			float: left;
			margin: 15px auto;
			margin-right: 40px;

			@include mobile {
				float: none;
				margin: 10px auto;
			}
		}

		&.aligncenter {
			display: block;
			margin: 30px auto;
		}

		&.alignright {
			float: right;
			margin: 30px auto;
			margin-left: 40px;

			@include mobile {
				float: none;
				margin: 10px auto;
			}
		}

		&.alignnone {
			margin: 30px 0;
		}

		img {
			margin: 15px auto;

			&.alignleft {
				float: left;
				padding-right: 40px;

				@include mobile {
					float: none;
					margin: 10px 0;
					padding: 0;
				}
			}

			&.aligncenter {
				display: block;
				margin: 30px auto;
			}

			&.alignright {
				float: right;
				padding-left: 40px;

				@include mobile {
					float: none;
					margin: 10px 0;
					padding-left: 0;
				}
			}
		}
	}

	.wp-caption {
		img {
			width: 100%;
			margin: 0;
		}
	}


	// -- modifiers
	// Theming des couleurs de textes sur fond sombre (superposition sur image...)
	&.--theme-dark {
		--color-text: #{$c_white};
		--color-heading: #{$c_white};
		--color-surtitre: #{$c_main};
		--color-link: #{$c_white};
		--color-link-hover: #{$c_main};

		--color-btn: #{$c_white};
		--border-color-btn: #{$c_white};

		--color-btn-primary: #{$c_txt};
		--bg-color-btn-primary: #{$c_white};
		--border-color-btn-primary: #{$c_white};

		--color-btn-underline: #{$c_white};
		--border-color-btn-underline: #{$c_main};

	}
}

.onglets {
	padding-bottom: 20px;

	label {
		@include reset-appearance();
		display: inline-flex;
		@include ft($f_hx, $s_p, 700, $c_txt, 1.5);
		border-bottom: 3px solid rgba($c_txt, 0);
		padding-bottom: 5px;
		cursor: pointer;
		text-transform: uppercase;
		margin-right: 30px;

		@include mobile {
			margin-right: 15px;
			font-size: 14px;
		}
	}
}