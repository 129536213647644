// ==============================================

//        IMPORT DES FONTS

// ==============================================

// Version regular
@font-face {
  font-family: 'Teen';
  src: url('#{$font_path}Teen/Teen.woff2') format('woff2'),
  url('#{$font_path}Teen/Teen.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// Version bold
@font-face {
  font-family: 'Teen';
  src: url('#{$font_path}Teen/Teen-Bold.woff2') format('woff2'),
  url('#{$font_path}Teen/Teen-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

// Version light
@font-face {
  font-family: 'Teen';
  src: url('#{$font_path}Teen/TeenLight.woff2') format('woff2'),
  url('#{$font_path}Teen/TeenLight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}




