.accordeon {
	position: relative;
	text-align: left;
	margin: initial;
	border-bottom: 1px solid rgba($c_sub, 0.10);
	@include addTransition();

	@include tablet-portrait {
		margin: 0;
	}

	&:first-of-type {
		border-top: 1px solid rgba($c_sub, 0.10);
	}

	&:nth-of-type(even) {
		//  border-top: none;
		// border-bottom: none;
	}

	&:last-of-type {
		border-bottom: 1px solid rgba($c_sub, 0.10);
	}

	.title-accordeon {
		@include reset-appearance;
		@include ft($f_txt, $s_small_title, 700, $c_txt, 1.2);
		position: relative;
		z-index: 1;
		display: flex;
		align-items: center;
		width: 100%;
		//  min-height: 95px;
		padding: 30px 0;
		text-align: left;
		background: none;
		@include addTransition();

		@include mobile {
			font-size: 14px;
			line-height: 1.14;
		}

		[class^="icon-"], [class*=" icon-"] {
			color: $c_sub;
			font-size: 40px;
			position: absolute;
			left: 30px;

			@include mobile {
				font-size: 20px;
				left: 20px;
			}
		}

		.container-custom {
			max-width: none;
			padding-left: 95px;
			padding-right: 95px;
			margin: 0 auto;

			@include mobile {
				padding-left: 55px;
				padding-right: 55px;
			}
		}

		&:before {
			@include icomoon($icon-plus);
			font-size: 15px;
			color: $c_sub;
			position: absolute;
			right: 0;
		}

		span {
			flex-grow: 1;
			max-width: 70%;
		}
	}


	.item {
		position: relative;
		max-height: 0;
		overflow: hidden;
		font-size: 0;

		&.container-custom {
			max-width: none;
			padding-left: 95px;
			margin: 0 auto;

			@include mobile {
				padding-left: 20px;
				padding-right: 20px;
			}
		}

		&.anim {
			@include addTransition();
		}

		> * {
			position: relative;
			margin-top: 0 !important;
			margin-bottom: 20px !important;

			@include mobile {
				margin-bottom: 20px !important;
			}

			.title {
				@include mobile {
					font-size: 25px;
					line-height: 0.8;
				}
			}
		}
	}

	.strate {
		padding: 0;
	}

	&.open {
		border-bottom: 1px solid rgba($c_sub, 0.10);

		.title-accordeon {
			color: $c_sub;

			[class^="icon-"], [class*=" icon-"] {
				color: $c_sub;
			}

			&:before {
				@include icomoon($icon-minus);
				color: $c_sub;
			}
		}
	}

	@include can-hover {
		.title-accordeon {
			&:before {
				cursor: pointer;
			}
		}
	}
}