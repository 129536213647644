.bloc-mise-en-avant {


  /// Crée un aspect-ratio avec un pseudo-element
  /// @param {Number} $aspect-ratio - Ratio Largeur / Hauteur
  /// @param {String} $selector - Sélecteur(s) enfant(s) à remonter dans le container en position absolue, par défaut 1er enfant direct
  /// @param {Length} $padding-bottom - Padding-bottom optionnel sur le pseudo-element
  /// @example @include aspect-ratio();  -> Pas d'arguments -> ratio 1/1 par défaut
  /// @example @include aspect-ratio(16/9);  -> Largeur et hauteur
  @mixin aspect-ratio($aspect-ratio: 1/1, $selector: "> :first-child") {
    position: relative;
    @if ($selector) {
      $selector: unquote($selector)
    };

    &:before {
      content: '';
      display: block;
      padding-top: calc(100% / (#{$aspect-ratio}));
    }

    // Remonte les enfants dans le container
    @if $selector {
      #{$selector} {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }
  }


  @mixin aspect-ratio-featured($aspect-ratio: 1/1, $selector: "> :first-child") {
    aspect-ratio: $aspect-ratio;

    // Fallback (current, using padding hack)
    @supports not (aspect-ratio: 1 / 1) {
      opacity: 0.5;
      position: relative;
      @if ($selector) {
        $selector: unquote($selector)
      };

      &:before {
        content: '';
        display: block;
        padding-top: calc(100% / (#{$aspect-ratio}));
      }

      // Remonte les enfants dans le container
      @if $selector {
        #{$selector} {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  .fit-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  a.item {
    @include can-hover {
      .caption {
        transform: translate(0, calc($small-margin * -1));
        @include addTransition();

        .h3.small {
          color: $c_main;
          @include addTransition();


          &:after {
            opacity: 1;
            @include addTransition();
          }
        }
      }
    }
  }


  .item {
    --aspect-ratio: 1/1; // par défaut
    position: relative;
    @include aspect-ratio-featured(var(--aspect-ratio), 'span');

    .image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      > .no-photo,
      > figure {
      }
    }

    .caption {
      bottom: var(--medium-margin);
      left: var(--medium-margin);
      position: absolute;
      right: var(--medium-margin);
      @include addTransition();

      @include tablet-portrait {
        bottom: 20px;
        right: 20px;
        left: 20px;
      }

      > * {
        @include min-tablet-portrait {
          max-width: 100% !important;
        }
      }

      .h3 {
        &:after {
          opacity: 0;
        }
      }
    }
  }

  .container-mosaic {
    display: grid;
    grid-template-columns: 780fr 400fr;
    gap: var(--small-margin);

    // styles wrapper
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    width: 100%;
    max-width: 1200px;
    border: 1px dotted white;

    @include tablet-small {
      grid-template-columns: auto;
    }
  }


  .col-1 {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-columns: 400fr 360fr;
    grid-template-areas: "item1 item1"
                       "item2 item3";
    gap: var(--small-margin);
    align-items: start;

    @include tablet-small {
      grid-template-columns: auto;
    }
  }


  /////
  .col-2 {
    display: grid;
    grid-template-columns: auto;
    grid-template-areas: "item4"
                       "item5";
    gap: var(--small-margin);
    align-items: start;

    @include tablet-small {
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "item4 item5";
    }
  }


  // items
  .item-1 {
    grid-area: item1;
    --aspect-ratio: 780/400;
    @include tablet-small {
      --aspect-ratio: 380/248;
    }
  }

  .item-2 {
    grid-area: item2;
    --aspect-ratio: 400/460;
    @include tablet-small {
      --aspect-ratio: 183/270;
    }
  }

  .item-3 {
    grid-area: item3;
    --aspect-ratio: 360/460;
    @include tablet-small {
      --aspect-ratio: 183/300;
    }
  }

  .item-4 {
    grid-area: item4;
    --aspect-ratio: 400/460;
    @include tablet-small {
      --aspect-ratio: 183/304;
      margin-top: -16%;
    }
  }

  .item-5 {
    grid-area: item5;
    --aspect-ratio: 400/400;
    @include tablet-small {
      --aspect-ratio: 183/275;
    }
  }

}