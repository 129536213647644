// Style General - Overlay - All site
// ==========================================================================
.overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: z-index('overlay');
	overflow: hidden;
	width: 100vw;
	max-width: 640px;
	background-color: $c_white;
	transition: transform 400ms $transition-overlay-easing, visibility 400ms $transition-overlay-easing;
	visibility: hidden;
	transform: translateX(100%);

	@include mobile {
		max-width: 100%;
	}

	&:not(.overlay-absolute) {
		.overlay-load & {
			display: block;
		}
	}

	&.open {
		visibility: initial;
		transform: initial;
	}

	// Arrivée du haut de l'écran
	&.--from-top {
		transform: translateY(-100%);

		&.open {
			transform: initial;
		}
	}

	// Arrivée du bas de l'écran
	&.--from-bottom {
		transform: translateY(100%);

		&.open {
			transform: initial;
		}
	}

	.error-container {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 75%;
		transform: translate(-50%, -50%);
		text-align: center;
		z-index: 999999;

		@include mobile {
			width: 100%;
		}
	}



	.scroller {
		padding: 40px 80px 80px 80px;
		height: 100%;
		overflow-y: auto;

		@include tablet-landscape {
			padding: 40px;
		}

		@include mobile {
			padding: 40px;
		}

		@include mobile {
			padding: 40px 20px 40px 20px;
		}
	}

	.h3 {
		margin-bottom: 40px !important;
		font-weight: 300;
	}

	.btn-close {
		position: absolute;
		top: 40px;
		right: 40px;
		z-index: 10;

		@include mobile {
			top: 20px;
			right: 20px;
		}
	}
}


.overlay-opensystem {
	.overlay__heading {
		position: relative;

		h2 {
			margin-top: 0;
			padding-right: 40px;

			@include tablet-landscape {
				padding-right: 90px;
			}
		}
	}

	.js-panier-overlay-resa {
		position: absolute;
		top: 0;
		right: -5px;

		@include tablet-landscape {
			right: 35px;
		}

		@include mobile {
			top: -20px;
		}
	}
}


//Overlay commentaire
// ==========================================================================
.overlay-commentaires {
	.bloc-form {
		margin-top: 40px;
	}

	.comments {
		margin-top: 60px;
		@include mobile {
			margin-top: 30px;
		}
	}
}

//Overlay agenda
// ==========================================================================
.overlay-agenda {
	.container {
		overflow-y: auto;
		height: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
	}

	.scroller {
		padding: 40px 40px 40px 80px;
		@include mobile {
			padding: 40px;
		}
	}

	.text-styles {
		.titre {
			@extend .h3;
			@extend .--with-pattern;
			margin-bottom: 50px;
			font-weight: 300;
		}

		p, address {
			font-size: 14px;
			line-height: 24px;
		}

		.h3 {
			margin-bottom: 40px;
			font-weight: 300;
		}
	}

	.liste-a-propos {
		display: flex;
		margin: 20px 0;
		flex-wrap: wrap;


		> * {
			display: flex;
			width: 50%;
			margin-top: 20px;
			align-items: center;
			@include mobile {
				justify-content: center;
				flex-direction: column;
				text-align: center;
			}

			.info-title {
				@include ft($f_hx, 16px, 700, "", 1);
				@include mobile {
					font-size: 16px;
					margin-bottom: 5px;
				}
			}

			[class^="icon-"], [class*=" icon-"] {
				flex-shrink: 0;
				margin-right: 20px;
				font-size: 60px;
				@include mobile {
					font-size: 40px;
					margin-right: 0;
					margin-bottom: 5px;
				}
			}

			p {
				margin: 0;
			}
		}
	}

	.contacts {
		ul {
			padding: 0;
			display: inline-flex;

			li {
				&:first-child {
					padding-left: 0;
				}

				&:before {
					content: none;
				}
			}
		}
	}

	.location {
		margin-top: 10px;
		margin-bottom: 30px;
	}

	figure {
		margin-bottom: 20px;
		margin-top: 20px;
	}

	figure, img {
		border-radius: $border-radius-img;
	}

	.date {
		@include ft($f_hx, 26px, 300, $c_main, 1);
		padding-bottom: 20px;
	}

	.bottom-part {
		background-color: $c_white;
		text-align: right;
		padding: 15px 40px 15px 80px;
		@include shadow;
		@include mobile {
			padding: 15px 40px 15px 40px;
		}

		a {
			//width: 100%;
			//  margin-left: auto;
		}
	}
}

// Post type overlay
// ==========================================================================
.overlay-post {
	.scroller {
		overflow-x: hidden;
	}


	.wrapper {
		margin-left: auto;
		margin-right: auto;
	}


	.bloc-infos-cles {
		.container-elements {
			justify-content: flex-start;

			& > * {
				width: calc(50% - var(--col-gap));
			}
		}

		.inner-container {
			.caption {
				.title {
					font-size: 18px;
					@include mobile {
						font-size: 16px;
					}
				}
			}
		}
	}


	// style des blocs pour l'overlay
	.bloc-focus {
		.container-focus {
			display: flex;
			flex-direction: column;
			@include min-mobile {
				padding: 50px;
			}

			[class^="icon-"], [class*=" icon-"] {
				position: initial;
				margin-bottom: 20px;
				font-size: 60px;
				transform: initial;
			}
		}
	}
}

// Overlay - focus SIT
// ==========================================================================
.overlay-focus-sit {
	.barre {
		position: sticky;
		bottom: 0;
		left: 0;
		right: 0;
		padding-right: 40px;
		display: flex;
		align-items: center;
		min-height: 100px;
		background-color: $c_white;
		box-shadow: 0 -30px 60px rgba($c_sub, 0.10);

		@include mobile {
			padding-right: 20px;
			min-height: 50px;
		}

		[data-content] {
			position: relative;
			width: 60px;
			height: 60px;
			border: 1px solid $c_sub;
			margin-right: 10px;
			display: flex;
			justify-content: center;
			align-items: center;

			@include mobile {
				width: 30px;
				height: 30px;
			}

			&:before {
				content: "";
				@include icomoon();
				display: block;
				color: $c_sub;
				font-size: 20px;

				@include mobile {
					font-size: 12px;
				}
			}

			@include can-hover {
				.tooltiptext {
					visibility: visible;
				}
			}

			&[data-content="telephone"] {
				&:before {
					content: $icon-phone;
				}
			}

			&[data-content="email"] {
				&:before {
					content: $icon-email;
				}
			}

			&[data-content="site"] {
				&:before {
					content: $icon-link;
				}
			}
		}

		.lien {
			max-width: 248px;
			padding: 42px 70px 42px 80px;
			font-size: 16px;
			white-space: nowrap;
			margin-right: auto;

			@include tablet-portrait {
				padding: 42px 40px 42px 40px;
			}
			@include mobile {
				font-size: 12px;
				padding: 22px 20px 22px 20px;
			}
		}

		.btn, .btn-primary, .btn {
			max-width: 142px;

			@include mobile {
				position: absolute;
				bottom: 56px;
				width: 100%;
				max-width: none;
				height: auto;
				font-size: 12px;
			}
		}
	}
}

// Overlay - Filtres
// ==========================================================================
.overlay-filtres {
	overflow: initial; // pour corriger un bug sur les classements et les switch

	// styles communs des groupes de filtres
	.group-filtres, .group-date {
		padding-bottom: 40px;
		margin-bottom: 40px;
		border-bottom: 1px solid rgba($c_main, 0.30);

		@include mobile {
			padding-bottom: 20px;
			margin-bottom: 20px;
		}

		&:last-of-type {
			margin-bottom: 0;
			border-bottom: none;
		}
	}

	.group-switch {
		+ .group-switch, & {
			border-bottom: 0;
			padding: 0;

			@include min-mobile {
				margin-bottom: 30px;
			}
		}
	}

	.group-dates {
		position: relative;

		.group-checkbox {
			display: none;
		}

		.date-picker-wrapper {
			position: absolute;
			left: 0;
			top: 80px;
			margin-right: 0;
			box-shadow: 5px 5px 20px rgba($c_txt, 0.2);
			padding: 0 20px 20px;

			@include mobile {
				transform: scale(0.65);
			}


			// règles pour forcer la largeur à rentrer dans l'overlay filtres
			.gap {
				visibility: hidden !important;
			}

			.month-wrapper {
				width: 660px !important;
				display: flex;

				.time {
					display: none;
				}
			}

			.month-wrapper table {
				width: calc(50% - 15px);
			}

			.month-wrapper table .day {
				padding: 8px;
			}
		}

	}

	.group-checkbox {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}


	/* Facette Select */
	.group-select {
		.selectric-wrapper {
			max-width: 100%;
		}

		.select {
			.selectric-open {
				z-index: 10;
			}
		}
	}


	// layout général des overlays
	.overlay__inner {
		--padding-horizontal: 80px;
		display: flex;
		flex-direction: column;
		height: 100%;

		@include mobile {
			--padding-horizontal: 20px;
		}
	}

	.overlay__body {
		flex-grow: 1;
		overflow: auto;
		padding: 40px var(--padding-horizontal) 80px;
	}

	.overlay__footer {
		display: flex;
		justify-content: space-between;
		padding: 20px var(--padding-horizontal) 20px;
		@include shadow;
	}

	.btn-reset {
		@include ft('', $s_p, 300, $c_txt, 1.25);
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 10px 15px 10px 0;
		@include addTransition();

		@include mobile {
			font-size: 13px;
		}

		@include can-hover {
			&:before {
				transform: rotate(180deg);
			}
		}

		&:before {
			@include icomoon($icon-refresh);
			display: block;
			margin-right: 10px;
			font-size: 20px;
			color: $c_sub;
			@include addTransition();
		}
	}

	// styles communs des groupes de filtres
	.group-filtres {
		padding-bottom: 40px;
		margin-bottom: 40px;
		border-bottom: 1px solid rgba($c_sub, 0.10);

		@include mobile {
			padding-bottom: 20px;
			margin-bottom: 20px;
		}

		&:last-of-type {
			margin-bottom: 0;
			border-bottom: none;
		}
	}


	.group-checkbox {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}


	/* Facette Select */
	.group-select {
		.selectric-wrapper {
			max-width: 100%;
		}

		.select {
			.selectric-open {
				z-index: 10;
			}
		}
	}
}

// Overlay - Disponibilités
// ==========================================================================
.overlay-disponibilites {
	//position: absolute;
	opacity: 0;
	visibility: hidden;
	display: flex !important;
	align-items: center;
	justify-content: center;
	max-width: 100%;
	transform: translate3d(0, 200%, 0);
	transition: all 400ms cubic-bezier(0.86, 0, 0.07, 1);
	background-color: transparent;

	&.combobox-is-open {
		@include tablet_landscape {
			min-height: 400px;
			max-height: none;
		}
	}

	> .inner-container {
		transform: translate3d(0, 0, 0);
		-webkit-backface-visibility: hidden;
		max-width: 1060px;
		max-height: 700px;
		width: 100%;
		padding: 50px 60px 60px 60px;
		border-radius: 5px;
		background-color: $c_white;

		@include tablet_portrait {
			padding: 40px 20px;
			width: calc(100vw - 80px);
		}
		@include mobile {
			width: 100vw;
			height: 100%;
			transform: none !important;
			max-height: 100%;
			padding: 30px 20px;
			border-radius: 0;
		}

		.inner-container {
			.btn.btn-tertiary {
				&:hover, &:focus {
					body:not(.no-hover) & {
						border-color: transparent;
					}
				}
			}
		}
	}

	&.open {
		opacity: 1;
		visibility: visible;
	}

	.btn-close {
		border: none;
		@include tablet_portrait {
			top: 20px;
			right: 20px;
		}
		@include mobile {
			background-color: $c_main;
			color: $c_white;
		}
	}

	legend.title {
		position: relative;
		@include ft($f_txt, 26px, 500, $c_txt, 26px);
		margin-bottom: 50px;
		@include tablet_portrait {
			margin-bottom: 20px;
		}
		@include mobile {
			font-size: 20px;
			line-height: 20px;
			max-width: 80%;
		}

		&:after {
			content: "";
			position: absolute;
			background: $c_main;
			width: 120px;
			height: 2px;
			bottom: -25px;
			left: 0;
		}
	}

	.group-date {
		position: relative;

		@include tablet_portrait {
			width: 100%;
		}

		&:before {
			content: "\e923";
			@include icomoon();
			font-size: 16px;
			color: $c_main;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 30px;
			pointer-events: none;
			@include tablet_portrait {
				display: none;
			}
		}

		.wrapper-date {
			position: relative;
			z-index: 1;
			@include tablet_landscape {
				display: flex;
				align-items: center;
				flex-grow: 1;
			}
			@include tablet_portrait {
				width: calc(50% - 10px);
				border: 2px solid $c_sub;
				border-radius: 25px;
				padding-left: 30px;
				padding-right: 60px;
				height: 50px;
				display: flex;
				align-items: center;

				&:before {
					content: "\e923";
					@include icomoon();
					font-size: 16px;
					color: $c_main;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					right: 30px;
					pointer-events: none;
				}
			}

			@include mobile {
				width: 100%;
			}

			&:first-of-type {
				padding-left: 60px;

				@include mobile {
					padding-left: 30px;
				}

				&:after {
					content: "";
					display: block;
					width: 1px;
					height: 24px;
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					@include tablet_portrait {
						display: none;
					}
				}
			}

			label {
				font-weight: 700;
			}

			input {
				font-weight: 500;
				padding-left: 5px;

				@include tablet_landscape {
					margin-left: 5px;
					height: 100%;
					z-index: 99999999;
					pointer-events: all;
					width: 80px;
				}
				@include tablet_portrait {
					max-width: none;
					width: auto;
					flex-grow: 1;
					margin-left: 5px;
				}

				&:focus-visible {
					border: 1px dashed $c_txt;
				}
			}
		}
	}

	.group-people {
		display: flex;
		align-items: center;
		margin-bottom: 0;
		text-align: center;
		text-decoration: none;
		border-radius: 6px;
		border: 1px solid $c_main;
		padding: 13px 20px;
		flex-shrink: 0;
		justify-content: space-between;
		width: 200px;
		position: relative;

		label {
			@include ft($f_txt, $s_link, bold, $c_txt, 18px);
		}

		.input-type-number {
			all: unset;
			width: 30px;
			margin-right: 2px;
			text-align: left;
			@include ft($f_hx, $s_link, 700, $c_main, calc($s_link + 6px));
		}

		.cta-quantity {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			@include ft($f_hx, 9px, 700, $c_main, 9px);
			cursor: pointer;
		}

		.dec {
			right: 65px;
		}

		.inc {
			right: 20px;
		}

		// Sur tout les inputs de type nombre, on cache les flèches par défaut du navigateur
		/* Chrome, Safari, Edge, Opera */
		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* Firefox */
		input[type=number] {
			-moz-appearance: textfield;
		}

	}

	fieldset {
		.inner-container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			@include tablet_landscape {
				flex-wrap: wrap;
				align-items: flex-start;
			}
			@include mobile {
				margin-top: 20px;
			}

			> *:not(.btn-tertiary) {
				@include tablet_portrait {
					margin-bottom: 10px;
					margin-right: 0;
				}
			}

			> * {
				@include tablet_landscape {
					width: calc(50% - 10px);
					margin-bottom: 10px;
				}


				@include mobile {
					width: 100%;
				}

				&.group-date {
					@include tablet_portrait {
						width: 100%;
					}
				}
			}

			.btn-reserver {
				margin-right: 0;
			}

			.btn:not(.btn-tertiary) {
				@include mobile {
					text-align: left;
				}
			}
		}
	}

	#calendar-container {
		padding-top: 35px;
		@include tablet_landscape {
			display: none;
		}

		.date-picker-wrapper {
			width: 100%;
			max-width: none;
			padding: 0;
			transform: scale(1);
			box-shadow: none;

			.month-wrapper {
				width: 100% !important;
				display: flex;
			}

			.gap {
				visibility: visible !important;
				background: transparent;
				width: 200px;

				.gap-lines {
					display: none;
				}

				&:after {
					content: "";
					background: $c_main;
					position: absolute;
					top: 0;
					left: 50%;
					transform: translateX(-50%);
					width: 1px;
					height: 100%;
					display: block;
				}
			}

			.month-name .select-wrapper {
				text-transform: capitalize;
				color: $c_main;
			}

			.month-wrapper {
				table {
					tbody:before {
						content: "@";
						display: block;
						line-height: 20px;
						text-indent: -99999px;
					}

					.caption {
						.next, .prev {
							position: relative;

							&:after {
								content: "";
								position: absolute;
								background: transparent;
								border-radius: 50%;
								width: 50px;
								height: 50px;
								top: -24px;
								border: 1px solid $c_txt;
								@include addTransition();
								z-index: 1;
							}

							&:before {
								position: relative;
								z-index: 2;
								color: $c_txt;
								@include addTransition();
							}

							&:hover {
								&:after {
									border: 1px solid transparent;
									background: $c_txt;
								}

								&:before {
									color: white;
								}
							}
						}

						.next:after {
							left: 8px;
						}

						.prev:after {
							left: -50px;
						}
					}

					th {
						height: 40px;
					}

					tr td {
						.day {
							font-size: 16px;
							border-radius: 14px;

							&:nth-last-of-type(-n+2) {
								color: $c_txt;
							}

							&:hover {
								background: $c_main;
								color: white;
							}

							&.invalid {
								opacity: .5;
							}

							&.checked, &.hovering {
								background: $c_light;
								color: $c_txt !important;
							}

							&.real-today {
								background: $c_main;
								color: white !important;
								font-weight: 700;
							}
						}
					}
				}

				.week-name {
					display: table-row;

					th {
						vertical-align: bottom;
						height: 60px;
						@include ft($f_txt, 12px, 800, $c_txt, 18px);
					}
				}
			}
		}

		.time {
			display: none;
		}
	}

	.combobox-communes {
		position: absolute;
		top: 87px;
		background-color: $c_white;
		border-radius: 6px;
		border: 1px solid $c_main;
		max-width: 230px;
		max-height: 295px;
		padding: 20px 10px 10px;
		box-sizing: content-box;
		z-index: 9999999;
		visibility: hidden;
		transform: scale(0);
		@include addTransition();

		.combobox-label {
			display: block;
			@include ft($f_txt, 10px, 300, rgba($c_main, 0.80), 13px);
			font-style: italic;
			text-align: center;
			margin-bottom: 5px;
		}

		input {
			all: unset;
			width: 100%;
			height: 50px;
			padding: 16px 30px;
			border-radius: 6px;
			background-color: transparent;
			@include ft($f_hx, 12px, 800, $c_main, 18px);
			text-transform: uppercase;
			box-sizing: border-box;
			border: 1px solid $c_main;

			&:focus {
				body:not(.no-hover) & {
					outline: 1px dashed $c_txt;
				}
			}

		}

		.communes {
			margin-top: 10px;
			max-height: 66px;
			overflow: auto;
			padding-left: 30px;

			span {
				@include ft($f_txt, 14px, 400, rgba($c_main, 0.80), 22px);
				display: block;
			}

		}

		.title {
			@include ft($f_hx, 12px, 800, $c_main, 18px);
			display: inline-block;
			text-transform: uppercase;
			margin: 10px 0;
		}

		.btn {
			width: 100%;
		}

		.btn-reserver {
			width: auto;
			min-height: auto;
		}

		.rayon {
			display: inline-block;
			margin-bottom: 10px;
			padding-left: 30px;

			.distance-container {
				display: flex;
				align-items: center;
				justify-content: space-between;

				input {
					border: none;
				}

				button {
					@include ft($f_hx, 20px, 800, $c_white, 30px);
					width: 30px;
					height: 30px;
					flex-shrink: 0;
					border-radius: 50%;
					background-color: $c_main;
					cursor: pointer;
					@include addTransition();

					&.btn-dec {
						&:before {
							content: "-";
						}
					}

					&.btn-inc {
						&:before {
							content: "+";
						}
					}

					&:hover, &:focus {
						body:not(.no-hover) & {
							background-color: $c_sub;
							color: $c_main;
						}
					}

				}

				.distance {
					@include ft($f_txt, 14px, 700, $c_main, 22px);
					background-color: transparent;
					text-align: center;
					-moz-appearance: textfield;
					border-radius: 0;
					padding: 20px 0 20px 15px;
					outline: none;

					&::-webkit-outer-spin-button,
					&::-webkit-inner-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}

					& + span {
						padding-right: 20px;
					}
				}

			}
		}

		div.cta-quantity {
			display: none;
		}

		&.show-combobox {
			visibility: visible;
			transform: scale(1);
		}
	}

	.result-row {
		padding: 5px 0;

		&:focus, &.focused {
			font-weight: 800;
			background-color: $c_sub;
		}

		&:focus {
			outline: 1px dashed $c_main;
		}

		.result-cell {
			cursor: pointer;

			&:focus {
				background-color: $c_main;
			}
		}
	}

	.select-wrapper {
		select {
			@include ft($f_hx, 20px, 800, $c_main, 20px);
		}
	}

	input[type="date"]::-webkit-inner-spin-button,
	input[type="date"]::-webkit-calendar-picker-indicator {
		display: none !important;
		-webkit-appearance: none !important;
		opacity: 0 !important;
	}

}




// Overlay - map (SIT)
// ==========================================================================
.overlay-map {
	display: flex;
	flex-direction: column;
	max-width: initial;

	&:not(.overlay-absolute) {
		.overlay-load & {
			display: flex;
		}
	}

	.maps {
		flex-grow: 1;
		position: relative;
		z-index: 1;
	}

	.nav-arrows {
		@include absolute-stretch();

		.btn-arrow {
			position: absolute;
			top: 65px;
			background-color: $c_white;

			&.--prev {
				left: 40px;
				transform: translate(-50%, -50%);
			}

			&.--next {
				right: 40px;
				transform: translate(50%, -50%);
			}
		}
	}

	.results {
		position: relative;
		min-height: 160px;
		padding: 10px 40px;

		@include mobile {
			min-height: 110px;
			padding: 10px 10px;
		}

		// gradient pour le slider
		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			z-index: 1;
			width: 40px;
			pointer-events: none;
		}

		&:before {
			left: 0;
			background-image: linear-gradient(to right, rgba(white, 1), rgba(white, 0));

			@include mobile {
				display: none;
			}
		}

		&:after {
			right: 0;
			background-image: linear-gradient(to left, rgba(white, 1), rgba(white, 0));
		}

		.splide__slide {
			--slide-gap: 10px;
			--slide-width: 390px;
			width: var(--slide-width);
			margin-right: var(--slide-gap);

			@include mobile {
				--slide-width: 320px;
			}

			@media screen and (max-width: 420px) {
				--slide-width: calc(100% - 20px);
			}
		}

		.card {
			@include card-horizontale();
			align-items: flex-start;
			padding: 0;

			.caption {
				margin-top: auto;
				margin-bottom: auto;
			}

			.image {
				@include mobile {
					margin-right: 10px;
				}
			}
		}
	}
}

// Overlay - Shadow
// ==========================================================================
.shadow-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: z-index('overlay-shadow');
	background-color: rgba($c_sub, 0.5);
	opacity: 0;
	visibility: hidden;
	transition: opacity 400ms $transition-overlay-easing, visibility 400ms $transition-overlay-easing;

	&.open {
		opacity: initial;
		visibility: initial;
	}
}