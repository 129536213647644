
.bloc-image-texte {
	display: grid;
	grid-template-areas: "text image";
	grid-template-columns: repeat(2, minmax(0, 1fr));
	align-items: start;
	gap: var(--grid-gap);

	@include tablet-small {
		gap: var(--grid-gap-small);
	}

	// -- modifiers
	// Texte à droite et photo à gauche -> inversion par rapport au DOM
	&.--text-right {
		grid-template-areas: "image text";
	}

	// Toujours image en premier et texte aligné à gauche sur mobile
	&.--text-right,
	&.--text-left {
		@include mobile {
			grid-template-areas: "image"
								 "text";
			grid-template-columns: 1fr;
		}
	}

	.col-text {
		grid-area: text;
		margin-top: auto; // texte centré verticalement seulement si plus petit que l'image
		margin-bottom: auto;

		@include mobile {
			padding-bottom: var(--grid-gap);
		}
	}

	.col-image {
		grid-area: image;
	}

	.image {
		img {
			width: 100%;
		}
	}
}
