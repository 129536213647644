.meta-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;

  .meta {
    @include ft($f_txt, 10px, 600, $c_txt, 13px);
    background-color: $c_light;
    padding: 3px 8px;
    // max-height: 20px;
    border-radius: 3px;
    text-transform: uppercase;
    margin-bottom: 5px;

    &:not(:last-of-type) {
      margin-right: 5px;
    }

    &:before {
      display: none;
    }

    [class^="icon-"], [class*=" icon-"] {
      font-size: 14px;
    }

    &.with-icon {
      padding: 7px;
    }

    a {
      color: $c_txt;
    }
  }
}