.baseline {
  width: 100%;
  max-width: 150px;

  &.--baseline-dark {
    display: none;
  }
}

.cover-edito, .cover-home {
  // baseline
  .baseline {
    position: absolute;
    right: 30px;
    z-index: 2;
    top: calc(var(--header-height) + 45px);

    @include tablet-portrait {
      left: 35px;
      right: auto;
    }

    figure {
      display: block !important;
    }

    &.--baseline-dark {
      display: block;
    }

    &.--baseline-light {
      display: none;
    }
  }

  &.--has-full-image, &.--has-video {
    .baseline {
      &.--baseline-dark {
        display: none;
      }

      &.--baseline-light {
        display: block;
      }
    }
  }
}