.strate-footer-widgets {
	background-color: $c_sub;
	position: relative;
	padding-bottom: 50px;

	@include mobile {
		padding-bottom: 40px;
	}

	&:before {
		content: '';
		position: absolute;
		top: -40px;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: $c_sub;
		z-index: -1;
	}

	&:after {
		content: "";
		position: absolute;
		top: 50px;
		left: 0;
		bottom: -40px;
		right: 0;
		z-index: -1;
		background-color: $c_sub;
	}

	.basic-grid {
		@include tablet-small {
			display: flex;
			flex-flow: column;
		}

	}


	// Désactivation de l'accordéon au-dessus de tablet-small
	@include min-tablet-small {
		.accordeon {
			border: none !important;

			.title-accordeon {
				pointer-events: none;
				padding: 20px 0;

				&:after, &:before {
					content: none;
				}
			}

			.item {
				max-height: initial !important; // fix pour surcharger la lib th_accordeon
				overflow: initial !important;
				height: auto !important; // pour que la div tienne compte du contenu
			}
		}
	}

	.accordeon {
		border-bottom: 1px solid rgba($c_white, 0.10);

		&:first-of-type {
			border-top: 1px solid rgba($c_white, 0.10);
		}

		&:last-of-type {
			border-bottom: 1px solid rgba($c_white, 0.10);
		}

		&.open {
			border-bottom: 1px solid rgba($c_white, 0.10);

			.title-accordeon {
				color: $c_white;

				[class^="icon-"], [class*=" icon-"] {
					color: $c_white;
				}

				&:before {
					@include icomoon($icon-arrow-up);
					color: $c_white;
				}
			}
		}

		.title-accordeon {
			&:before {
				@include icomoon($icon-arrow-down);
				color: $c_white;
				display: none;

				@include tablet-small {
					display: block;
				}
			}

			span {
				@media screen and (max-width: 460px) {
					max-width: none;
					padding-right: 25px;
				}
			}
		}
	}


	.title {
		@include ft($f_hx, $s_p, bold, $c_white, 1.25);
		text-transform: uppercase;

		& + * {
			margin-top: $small-margin;
		}
	}

	// pour que les widgets ne touchent la baseline
	.widget-menu, .widget-contact {
		@include min-tablet-small {
			--padding-bottom: 50px;
			height: calc(100% - var(--padding-bottom));
			padding-bottom: var(--padding-bottom);
		}
	}

	.widget-menu {
		a {
			display: inline-block;
			@include ft($f_txt, $s_link, 400, $c_white, 18px);
			padding: 7px 0;
			@include can-hover {
				color: $c_main;
			}
		}

		// exemple de menu niv2
		ul ul {
			padding-left: 20px;

			a {
				font-size: 14px;
			}
		}
	}

	.widget-contact {
		p {
			@include ft($f_txt, $s_link, 400, $c_white, 20px);
		}

		.text-styles {
			margin-bottom: 20px;
		}

		.btn {
			display: flex;
			width: max-content;
		}
	}


	// baseline
	.logos-baseline {
		width: 100%;
		position: relative;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		grid-column-start: 1;
		grid-column-end: 4;
		@include tablet-small {
			flex-wrap: wrap;
			padding-top: 40px;
			justify-content: flex-start;
		}

		&:after {
			content: '';
			clear: both;
			display: block;
		}
	}

	.logos {
		display: flex;
		flex-wrap: wrap;
		@include tablet-small {
			width: 100%;
			flex-flow: column;
			margin-bottom: 10px;
		}

		figure {
			height: 40px;
			width: auto;
			margin: 10px 20px 10px 0;

			img {
				height: 100%;
				width: auto;
			}

			@include mobile {
				height: 50px;
			}
		}
	}

	.baseline {
		flex-shrink: 0;

		&.--baseline-dark {
			display: none;
		}
	}
}
