.bloc-focus {
  background-color: $c_main_light;

  // Style pour le container avec seulement les informations mise en avant
  .container-focus {
    position: relative;
    padding: 50px 50px 50px 160px;

    @media (max-width: 1140px) {
      padding-left: 50px;
    }

    @include mobile {
      padding-left: 20px;
      padding-right: 20px;
      text-align: center;

      h2, .h2 {
        &:after {
          left: 50%;
          transform: translate(-50%, 0);
          right: auto;
        }
      }
    }


    [class^="icon-"], [class*=" icon-"] {
      font-size: 100px;
      color: $c_sub;
      position: absolute;
      top: 63px;
      left: 34px;

      @media (max-width: 1140px) {
        position: initial;
        font-size: 80px;
      }
    }
  }
}