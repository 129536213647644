// Styles pour l'affichage des cards en format horizontale
// ==========================================================================
.cards-horizontales {
  .card {
    @include card-horizontale();

    .image {
      .info-desktop {
        bottom: auto;
        top: 10px;
        display: none;
      }

      .info-mobile {
        display: block;
      }
    }
  }

  // On fait un exception pour le bloc agenda :
  // dans ce bloc vu que les cards ont des images plus grandes on peut se permettre d'avoir un font-size plus grand
  // et aussi d'afficher les metas à côté. Dans le reste du site les cards sont trop petites, donc il faut cacher les metas
  // et réduire le font size des dates

  &:not(.bloc-agenda) {
    .meta-wrapper {
      display: none;
    }

    .date-event {
      .day, .month {
        font-size: 14px;
        line-height: 1;
      }
    }
  }
}

// Card - Default Styles
// ==========================================================================
.card {
  position: relative;
  display: block;
  width: 100%;

  @include mobile {
    display: flex;
    align-items: center;
  }

  @include can-hover {
    .title-card {
      color: $c_main;
      @include addTransition();
    }
  }

  .image {
    --aspect-ratio: 280/280;
    position: relative;
    margin-bottom: 15px;
    flex-shrink: 0;
    @include aspect-ratio(var(--aspect-ratio), '> figure, > .no-photo');
    @include addTransition();

    @include mobile {
      width: 115px;
      height: 115px;
      margin-right: 20px;
      margin-bottom: 0;
    }

    > figure {
      figcaption {
        display: none;
      }
    }

    .no-photo {
      border-radius: $border-radius-img;
    }

    .credits {
      display: none;
    }

    [class^="icon-"]:not(.btn-favorite), [class*=" icon-"]:not(.btn-favorite) {
      width: 24px;
      height: 24px;
      background-color: $c_main;
      font-size: 12px;
      color: $c_white;
      position: absolute;
      top: 0;
      left: 0;

      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }


  .badge-price {
    //  position: absolute;
    color: $c_txt;
    background-color: $c_light;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 1.25;
    font-weight: 600;
    margin-bottom: 5px;

    &.--desktop {
      padding: 4px 10px;
    }

    &.--mobile {
      padding: 3px 5px;
    }
  }


  .container {
    display: flex;
    flex-wrap: wrap;
    text-transform: uppercase;
    @include ft($f_txt, 12px, 600, $c_main, 1.25);
    margin-bottom: 5px;

    .classement {
      display: inline-block;
      vertical-align: top;
      margin-right: 5px;

      &:first-of-type {
        margin-left: 7px;
      }

      span {
        font-size: 12px;
        line-height: 1;
        color: $c_main;

        @include mobile {
          font-size: 10px;
        }

        &.icon-cle, &.icon-epis {
          position: relative;
          top: 1px;
        }

        &.icon-stars {
          & + .icon-epis, & + .icon-cle {
            margin-left: 5px;
          }
        }

        &.icon-epis {
          @include mobile {
            top: 0;
            margin-left: -3px;
          }
        }
      }

      &.classement-epis {
        @include mobile {
          margin-left: 2px;
        }
      }
    }
  }

  .caption {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .title-card {
    @include ft($f_hx, 24px, bold, $c_txt, 1);
    display: block;
    margin: 0 0 10px 0;
    @include addTransition();
    position: relative;

    @include mobile {
      font-size: 18px;
      line-height: 1;
      margin: 0 0 6px 0;
    }
  }

  .surtitre {
    margin-bottom: 5px;
  }

  .meta-wrapper {
    justify-content: flex-start;

    .meta {
      font-weight: 300;
      max-height: 24px;
      padding: 4px 10px;

      &.with-icon {
        padding: 4px;
      }
    }

    &.meta-heure {
      background-color: $c_main;
      font-weight: 700;
    }

    & + .location {
      @include min-mobile {
        margin-top: 7px;
      }
    }

    &:not(.meta-itineraire) {
      @include mobile {
        display: none;
      }
    }
  }

  .location {
    color: $c_grey;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;

    &:before {
      color: $c_grey;
    }
  }

  .location + .badge-reservation {
    margin-top: 10px;
  }

  .badge-reservation {
    padding: 3px 8px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    color: $c_white;
    border-radius: 3px;
    background-color: $c_main;

    @include mobile {
      margin-left: initial;
      padding: 3px 7px;
      font-size: 12px;
    }
  }


  // Card SIT
  &.card-sit {
    .location + * {
      margin-top: 10px;

      @include mobile {
        margin-top: 5px;
      }
    }
  }


  // Card sur le listing agenda au format horizontal
  &.card-agenda {
    @include card-horizontale();
    padding: 0;

    .image {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $c_main_light;

      @include min-mobile {
        width: 160px;
        height: 160px;
      }
    }

    .title-card {
      @include min-mobile {
        font-size: 20px;
        line-height: 22px;
      }
    }

    .date-event {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 10;
      transform: translate(-50%, -50%);
      width: 100%;
      padding: 30px;
      @include addTransition();
      @include ft($f_hx, 22px, 300, $c_txt, 24px);

      @include mobile {
        padding: 15px;
        font-size: 18px;
      }

      .year {
        display: none;
      }

      .info-date {
        display: inline-block;
        margin-top: 12px;
        padding-top: 8px;
        position: relative;
        @include ft($f_hx, 20px, 300, $c_main, 22px);

        &:before {
          position: absolute;
          top: 0;
          content: "";
          height: 3px;
          width: 50px;
          background-color: $c_main;
        }
      }
    }


    @include can-hover {
      .title-card {
        &:after {
          content: none;
        }
      }

      .location {
        @include addTransition();
        color: rgba($c_main, 0.60);

        &:before {
          @include addTransition();
          color: rgba($c_main, 0.60);
        }
      }

      .date-event {
        color: $c_main;
        @include addTransition();
      }
    }
  }

}
