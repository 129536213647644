// Composant image + un wysiwyg optionnel (un positionnement centré du texte à été effectué de base,
// mais il peut être changé ici ou surchargé au niveau du bloc)
// ==========================================================================

.image-wysiwyg {
  position: relative;
  width: 100%;


  &:not(.full-image) {
    margin-right: $small-margin;
  }

  --border-gutter-image-wysiwyg: 50px;

  .caption {
    position: absolute;
    bottom: var(--border-gutter-image-wysiwyg);
    left: var(--border-gutter-image-wysiwyg);
    right: var(--border-gutter-image-wysiwyg);
    z-index: 10;

    @include tablet-portrait {
      left: calc(var(--border-gutter-image-wysiwyg) / 2);
      right: calc(var(--border-gutter-image-wysiwyg) / 2);
      bottom: calc(var(--border-gutter-image-wysiwyg) / 2);
    }
    @include mobile {
      left: calc($border-gutter / 4);
      right: calc($border-gutter / 4);
      bottom: calc($border-gutter / 4);
    }

    > * {
      max-width: 60%;

      @media (max-width: 750px) {
        max-width: 100%;
      }
    }
  }

  figure {
    height: 100%;
    border-radius: $border-radius-img;

    img {
      height: 100%;
      border-radius: $border-radius-img;
    }

    figcaption {
      @include min-mobile {
        right: 0;
      }
    }
  }


  // Positionnement des différents gradients et du texte dans le wysiwyg
  &.alignleft {

    figure {
      &:after {
        content: "";
        display: block;
        width: 80%;
        height: 100%;
        border-radius: $border-radius-img;
        background: linear-gradient(-90deg, rgba(27, 37, 51, 0), rgba(27, 37, 51, 1));
        opacity: 0.50;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        pointer-events: none;
      }
    }
  }

  // Positionnement en bas du texte bloc mise en avant
  &.alignbottom {
    figure {
      &:after {
        content: "";
        border-radius: $border-radius-img;
        display: block;
        width: 100%;
        height: 50%;
        background: linear-gradient(-180deg, rgba(27, 37, 51, 0), rgba(27, 37, 51, 1));
        opacity: 0.80;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        pointer-events: none;
      }
    }
  }

  &.alignright {
    .caption {
      text-align: right;

      h2, .h2 {
        &:after {
          right: 0;
        }
      }

      > * {
        margin-left: auto;
        margin-right: 0;
      }
    }

    // Si on a la classe alignright ça veut dire que le texte est vers la gauche, donc il faut avoir un gradient vers la droite
    figure {
      &:after {
        content: "";
        display: block;
        width: 50%;
        height: 100%;
        border-radius: $border-radius-img;
        background: linear-gradient(90deg, rgba(27, 37, 51, 0), rgba(27, 37, 51, 1));
        opacity: 0.50;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        pointer-events: none;
      }
    }
  }

  &.aligncenter {
    .caption {
      text-align: center;

      h2, .h2 {
        &:after {
          left: 50%;
          transform: translate(-50%, 0);
        }
      }

      > * {
        margin-left: auto;
        margin-right: auto;
      }
    }

    figure {
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        border-radius: $border-radius-img;
        background: linear-gradient(180deg, rgba(27, 37, 51, 0), rgba(27, 37, 51, 1));
        opacity: 0.50;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        pointer-events: none;
      }
    }
  }

  // Positionnement spécial pour la version fullscreen

  &.full-image {
    figure {
      position: relative;

      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 15%;
        border-radius: $border-radius-img;
        background: linear-gradient(180deg, rgba(27, 37, 51, 0), rgba(27, 37, 51, 1));
        opacity: 0.50;
        z-index: 1;
        position: absolute;
        bottom: 0;
        pointer-events: none;
      }
    }

    .caption {
      position: absolute;
      width: auto;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: auto;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      z-index: 5;

      > * {
        max-width: 50%;
        @include tablet-landscape {
          max-width: 80%;
        }
        @include mobile {
          max-width: 100%;
        }
      }

      p {
        font-weight: 400;
      }
    }

    // Positionnement des différents gradients et du texte dans le wysiwyg
    &.alignleft {
      figure {
        &:after {
          content: "";
          display: block;
          width: 80%;
          height: 100%;
          border-radius: $border-radius-img;
          background: linear-gradient(-90deg, rgba(27, 37, 51, 0), rgba(27, 37, 51, 1));
          opacity: 0.50;
          position: absolute;
          top: 0;
          left: -20%;
          z-index: 2;
          pointer-events: none;
        }
      }
    }

    &.alignright {
      .caption {
        text-align: right;

        > * {
          margin-left: auto;
          margin-right: 0;
        }
      }

      // Si on a la classe alignright ça veut dire que le texte est vers la gauche, donc il faut avoir un gradient vers la droite
      figure {
        &:after {
          content: "";
          display: block;
          width: 50%;
          height: 100%;
          border-radius: $border-radius-img;
          background: linear-gradient(90deg, rgba(27, 37, 51, 0), rgba(27, 37, 51, 1));
          opacity: 0.50;
          position: absolute;
          top: 0;
          right: 0;
          z-index: 2;
          pointer-events: none;
        }
      }
    }

    &.aligncenter {
      .caption {
        text-align: center;

        > * {
          margin-left: auto;
          margin-right: auto;
        }
      }

      figure {
        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          border-radius: $border-radius-img;
          background: linear-gradient(180deg, rgba(27, 37, 51, 0), rgba(27, 37, 51, 1));
          opacity: 0.50;
          position: absolute;
          top: 0;
          right: 0;
          z-index: 2;
          pointer-events: none;
        }
      }
    }
  }
}

