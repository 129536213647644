.bloc-push-produit {
  background-color: $c_sub;
  color: $c_white;
  border-radius: $border-radius-img;

  .push-produit {
    display: flex;
    @include tablet-small {
      flex-direction: column;
    }
  }

  .image {
    margin-right: 0;
    --aspect-ratio: 380/380;
    @include mobile {
      --aspect-ratio: 335/230;
    }
    width: 380px;
    min-height: 380px;

    position: relative;
    flex-shrink: 0;
    @include aspect-ratio(var(--aspect-ratio), '> figure, > .no-photo');

    @include tablet-small {
      width: 100%;
      height: 230px;
      min-height: initial;
    }
  }


  .caption {
    padding: 50px 50px 50px 0;
    width: 100%;

    @include tablet-small {
      padding: 50px;
    }

    @include mobile {
      padding: 0 30px 30px 30px;
    }
  }

  .caption {
    .location {
      color: $c_white;

      &:before {
        color: $c_white;
      }
    }

    .h3 {
      @include ft("", 36px, 400, "", 45px);
      @include tablet-small {
        @include ft("", 26px, "", "", 30px);
      }
    }

    .info-suppl {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .price {
      color: $c_main;
      margin-top: 15px;
      @include ft($f_hx, 36px, 300, "", 26px);

      span {
        @include ft($f_txt, 14px, 400, "", 26px);
      }
    }

  }


  .image-wysiwyg {
    &:after {
      @include absolute-stretch();
      z-index: 1;
      content: "";
      border-radius: 0;
      pointer-events: none;
    }

    @include min-tablet-small {
      &:after {
        background-image: linear-gradient(to left, rgba($c_sub, 1), rgba($c_sub, 0), rgba($c_sub, 0));
      }
    }
    @include tablet-small {
      &:after {
        background-image: linear-gradient(to top, rgba($c_sub, 1), rgba($c_sub, 0), rgba($c_sub, 0));
      }
    }
  }
}

