@use "sass:math"; // notamment pour remplacer les divisions avec /, par math.div(a,b) : 16/9 -> math.div(16,9)

// Styles communes aux images + styles pour la classe image qui utilisé comme container pour les images afin de bien positionner les crédits en position absolute
// ==========================================================================

// Images
// ==========================================================================

.no-photo {
  height: 100%;
  background: $c_sub;
  border-radius: $border-radius-img;
}


// Opacity
// ==========================================================================
.o10 img {
  opacity: 0.1;
}

.o20 img {
  opacity: 0.2;
}

.o25 img {
  opacity: 0.25;
}

.o30 img {
  opacity: 0.3;
}

.o40 img {
  opacity: 0.4;
}

.o50 img {
  opacity: 0.5;
}

.o60 img {
  opacity: 0.6;
}

.o70 img {
  opacity: 0.7;
}

.o75 img {
  opacity: 0.75;
}

.o80 img {
  opacity: 0.8;
}

.o90 img {
  opacity: 0.9;
}



// Si image assombrie et figcaption, fond sombre sur le container de l'image pas sur le figure
.o10, .o20, .o30, .o40, .o50, .o60, .o70, .o75, .o80, .o90 {
  .img-wrapper {
    background-color: $c_black;
  }
}


// Styles pour le figcaption
figcaption {
  @include ft('', 12px, 300, '', 1.25);
  font-style: italic;
  margin-top: 10px;
}


// Container utilisé dans les blocs avec des colonnes pour contenir l'image et avoir le position relative pour bien positionner les crédits
.image,
.img-wrapper {
  position: relative;
  display: block;

  img {
    border-radius: $border-radius-img;
  }
}


.credits {
  --size: 25px; // largeur et hauteur du bouton crédits
  --spacing: 20px; // distance entre le bouton et les bords de l'image
  --font-size: 11px; // font-size quand hover
  @include ft('', '', '', $c_white, 1);
  position: absolute;
  top: var(--spacing); // positionnement haut droit par défaut
  right: var(--spacing);
  z-index: z-index('credits');
  display: inline-flex;
  align-items: center;
  max-width: var(--size);
  height: var(--size);
  padding: 0 calc(var(--size) / 2);
  font-size: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: var(--size);
  background-color: $c_sub;
  @include addTransition(all, 0.25s);

  &:after {
    @include icomoon($icon-credits);
    position: absolute;
    left: 50%;
    font-size: 12px;
    transform: translateX(-50%);
    @include addTransition(all, 0.25s);
  }

  &:hover,
  &:focus {
    max-width: calc(100% - var(--spacing) * 2);
    font-size: var(--font-size);

    &:after {
      opacity: 0;
    }
  }

  // --modifiers
  &.--top-left {
    right: initial;
    left: var(--spacing);
  }

  &.--bottom-left {
    right: initial;
    top: initial;
    left: var(--spacing);
    bottom: var(--spacing);
  }
}


// Ratios
// ==========================================================================
/*.ratio-16-9 {
	--aspect-ratio: 16/9;
	@include aspect-ratio(var(--aspect-ratio));
}

.ratio-16-10 {
	--aspect-ratio: 16/10;
	@include aspect-ratio(var(--aspect-ratio));
}

.ratio-4-3 {
	--aspect-ratio: 4/3;
	@include aspect-ratio(var(--aspect-ratio));
}

.ratio-16-9 {
	> img {
		@include absolute-stretch();
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}*/
