.barre-navigation {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: z-index('barre-navigation');
	@include addTransition();

	@include tablet-portrait {
		position: absolute;
	}

	@mixin withbackground() {
		background-color: $c_white;
		@include shadow();

		.logo {
			&.--logo-light {
				display: none !important;
			}

			&.--logo-dark {
				display: block !important;
			}
		}

		// Menu primary
		.nav-primary {
			.nav-primary__menu {
				li {
					a, label {
						color: $c_txt !important;
						@include addTransition();
						@include can-hover {
							@include addTransition();
							color: $c_main !important;
						}
					}
				}
			}
		}

		// styles communs des boutons
		.btn-picto {
			color: $c_txt !important;

			@include can-hover {
				color: $c_main !important;
			}
		}

		// Bouton pour ouvrir le menu de navigation fullscreen
		.btn-menu {
			color: $c_txt !important;

			.btn-menu-titre {
				color: $c_txt !important;
			}
		}

		.lang-select-mobile {
			&:after {
				color: $c_txt !important;
			}
		}

		.lang-dropdown-desktop {
			> a {
				color: $c_txt !important;

				@include can-hover {
					color: $c_main !important;
				}
			}

		}
	}

	.is-scrolled & {
		@include withbackground();
	}

	// quand il n'y a pas de couverture
	&.--withbackground {
		@include withbackground();
	}

	// quand il y a une couverture
	&.--transparent {

		.logo {
			&.--logo-light {
				display: block;
			}

			&.--logo-dark {
				display: none;
			}
		}

		// Menu primary
		.nav-primary {
			.nav-primary__menu {
				li {
					a, label {
						color: $c_white;

						@include can-hover {
							color: $c_main;
						}
					}
				}
			}
		}

		// styles communs des boutons
		.btn-picto {
			color: $c_white;

			@include can-hover {
				color: $c_main;
			}

		}

		// Bouton pour ouvrir le menu de navigation fullscreen
		.btn-menu {
			color: $c_white;

			.btn-menu-titre {
				color: $c_main;
			}
		}

		.lang-select-mobile {
			&:after {
				color: $c_white;
			}
		}

		// Dropdown en css (donc pas un select field) des langues du site
		.lang-dropdown-desktop {

			a {
				@include can-hover {
					color: $c_main;
				}
			}

			> a {
				color: $c_white;
			}
		}

	}

	.wrapper {
		display: flex;
		height: var(--header-height);

		@include tablet-small {
			.logo {
				margin: 0 auto;
			}
		}

	}

	// Logo du site
	.logo {
		figure {
			position: relative;
			top: 50%;
			transform: translateY(-50%);

			img {
				height: 100%;
			}
		}
	}

	// Menu primary
	.nav-primary {
		margin-left: auto;
		display: flex;
		padding-left: 15px;
		align-items: center;

		.nav-primary__menu {
			display: inline-flex;

			li {
				@include ft("", 12px, 500, "", 15px);
				text-transform: uppercase;

				&:not(:first-child) {
					margin-left: 40px;

					@include desktop {
						margin-left: 20px;
					}
					@include tablet-landscape {
						margin-left: 10px;
					}
				}

				label {
					@include addTransition();
					@include can-hover {
						cursor: pointer;
						@include addTransition();
					}
				}
			}
		}
	}

	.btn-reserver {
		font-size: 14px;
		margin-left: 40px !important;
		min-width: initial;
		padding: 8px 30px 8px 40px;

		#widget-panier {
			padding-left: 15px;
			pointer-events: none;

			.entete {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23fff'%3E%3Cpath d='M87.08 28.327H74.4v-.32c0-11.2-9.08-20.28-20.279-20.28H45.88c-11.2 0-20.28 9.08-20.28 20.28v.32H12.92A7.92 7.92 0 005 36.247v32.96c0 13.476 10.924 24.4 24.4 24.4h41.201C84.076 93.607 95 82.683 95 69.207v-32.96a7.92 7.92 0 00-7.92-7.92zm-53.88-.32c0-7.003 5.677-12.68 12.681-12.68h8.241c7.002 0 12.68 5.677 12.68 12.68v.32H33.2v-.32zm54.2 41.2c0 9.278-7.521 16.801-16.799 16.801H29.4c-9.279 0-16.801-7.522-16.801-16.801v-32.96a.32.32 0 01.32-.32H25.6v4.44a3.8 3.8 0 107.599 0v-4.44H66.8v4.44a3.8 3.8 0 107.6 0v-4.44h12.68a.32.32 0 01.32.32v32.96z'/%3E%3C/svg%3E");
			}
		}

		@include desktop {
			margin-left: 20px;
			padding: 10px 25px;
		}
		@include tablet-landscape {
			margin-left: 10px;
		}
		@include tablet-portrait {
			display: none;
		}
	}

	// styles communs des boutons
	.btn-picto {
		@include flex-center;
		padding: 0 20px;
		@include addTransition();

		[class^="icon-"], [class*=" icon-"] {
			position: relative;
			font-size: 20px;
		}
	}

	// Bouton pour ouvrir le menu de navigation fullscreen
	.btn-menu {
		@include flex-center;
		@include addTransition();
		padding-right: 0;

		.btn-menu-titre {
			text-transform: uppercase;
			font-size: 12px;
			font-weight: 600;
			margin-left: 5px;
		}
	}

	// Dropdown en css (donc pas un select field) des langues du site
	.lang-dropdown-desktop {
		position: relative;
		display: flex;
		align-items: center;
		margin: 0 20px 0 40px;

		&:hover {
			cursor: pointer;
		}

		a {
			@include ft($f_txt, 10px, 600, $c_white, "");
			text-align: center;
			text-transform: uppercase;
			padding: 0 10px;
			display: block;

			@include can-hover {
				color: $c_sub;
			}
		}

		> a {
			font-weight: bold;
			border-bottom: 2px solid $c_main;
			padding: 0 0 5px 0;

			&:after {
				@include icomoon($icon-arrow-down);
				display: inline-block;
				margin-left: 6px;
			}
		}

		ul {
			display: flex;
			align-items: center;
			width: 100%;
			padding: 0 0;
			background-color: $c_white;
			transform-origin: top;
			@include addTransition();

			position: absolute;
			left: 0;
			top: 52px;
			flex-direction: column;
			transform: scaleY(0);
			opacity: 0;

			li {
				display: flex;
				align-items: center;
				width: 100%;
				background-color: $c_sub;

				a {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					padding: 5px 0;

					@include can-hover {
						color: $c_main;
					}
				}
			}

			li + li a:before {
				@include pseudo-block(16px, 1px, rgba($c_white, 0.2));
				top: 0;
				left: 50%;
				transform: translateX(-50%);
			}

			&.is-open {
				opacity: 1;
				transform: scaleY(1);
			}
		}
	}
}

