.bloc-guides {

  @include mobile {
    display: flex;
    flex-direction: column-reverse;
  }

  .splide__list {
    margin-top: var(--medium-margin);

    .item-guide {
      margin-right: 70px;

      @include mobile {
        margin-right: 30px;
      }
    }
  }

  .item-guide {
    .container {
      position: relative;
      width: 110px;
      height: 190px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:before {
        content: "";
        background-color: rgba($c_main, 0.20);
        width: 80px;
        height: 80px;
        position: absolute;
        left: -10px;
        top: 20px;
        border-radius: 50%;
        z-index: -1;
        @include addTransition();
      }
    }

    [class^="icon-"], [class*=" icon-"] {
      display: block;
      font-size: 80px;
      color: $c_sub;

      & + .title {
        margin-top: 45px;
      }
    }

    .title {
      @include ft($f_hx, $s_p, bold, $c_txt, 1);
      text-transform: uppercase;
      text-align: center;
      @include addTransition();

      @media (max-width: 1100px) {
        font-size: $s_link;
        line-height: 1;
      }
    }

    @include can-hover {
      .container {
        &:before {
          background-color: $c_main;
          @include addTransition();
        }
      }

      .title {
        color: $c_main;
      }

      [class^="icon-"], [class*=" icon-"], .title {
        //	color: $c_white;
      }
    }
  }

  .container-nav {
    margin-top: 30px;
  }
}