// =============================================
// Share button
// =============================================

// Share Button Wrapper
.social-share {
  position: relative;
  z-index: z-index('social-share');
  @include addTransition();

  input {
    display: none;
  }

  // Toggle Button
  .toggle {
    position: relative;
    @include inline-flex-center();
    width: 40px;
    height: 40px;
    font-size: 20px;
    cursor: pointer;
    @include addTransition();

    @include can-hover {
      span {
        color: $c_sub;
      }
    }
  }


  // =============================================
  // Network List
  // =============================================

  .network-list {
    position: absolute;
    top: 0;
    pointer-events: none;

    li {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      opacity: 0;
      transition: all 0.25s cubic-bezier(0.43, 1.17, 0.69, 1.24);
    }
  }


  // =============================================
  // Open Network List

  input:checked {
    & ~ .toggle {
      color: $c_main;
    }

    & ~ .network-list {
      pointer-events: initial;

      li {
        opacity: 1;
      }

      li:nth-child(1) {
        top: -70px;
      }

      li:nth-child(2) {
        top: -120px;
      }

      li:nth-child(3) {
        top: -170px;
      }

      li:nth-child(4) {
        top: -220px;
      }

      li:nth-child(5) {
        top: -270px;
      }

      li:nth-child(6) {
        top: -320px;
      }
    }
  }
}
