// Le positionnement des éléments est fait avec la propriété ordre parce que
// pour l'accessibilité il faut toujours avoir les dots comme premiers éléments dans la structure html,
// donc on est obligé à jouer avec les ordres en css

.bloc-mosaique {
  position: relative;
  display: block;

  .mosaique-wrapper {
    display: block;
    column-count: 3;
    column-gap: var(--small-margin);
    @include mobile {
      column-count: 2;
    }

    &:after {
      content: '';
      display: block;
      clear: both;
    }

    .item {
      position: relative;
      display: block;
      margin-bottom: var(--small-margin);

      @include can-hover {
        &:before {
          opacity: 1;
          border-radius: $border-radius-img;
        }

        &:after {
          opacity: 1;
          transform: translate(-50%, -50%) scale(1);
        }
      }

      &:before {
        @include pseudo-block-stretch(rgba($c_txt, 0.25));
        z-index: 1;
        opacity: 0;
        border-radius: $border-radius-img;
        @include addTransition();
      }

      &:after {
        @include icomoon($icon-plus);
        border: 1px solid $c_main;
        @include preserve-px-unit(40px); // font-size fixe pour les icônes
        color: $c_white;
        display: flex;
        align-items: center;
        background-color: $c_main;
        justify-content: center;
        width: 50px;
        border-radius: 50%;
        height: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1.2);
        opacity: 0;
        z-index: 2;
        @include addTransition();

        @include mobile {
          width: 40px;
          height: 40px;
          @include preserve-px-unit(30px); // font-size fixe pour les icônes
        }
      }
    }
  }
}
