.bloc-auteur-citation {
	blockquote {
		position: relative;
		padding: 45px 50px 45px 130px;
		background-color: $c_light;

		@include mobile {
			padding: 30px 30px 30px 90px;
		}

		&:before {
			@include icomoon($icon-quote);
			font-size: 45px;
			color: $c_sub;
			position: absolute;
			top: 38px;
			left: 50px;

			@include mobile {
				font-size: 35px;
				top: 20px;
				left: 35px;
			}
		}

		p {
			@include ft($f_txt, 28px, 300, $c_txt, 30px);
			font-style: italic;

			@include mobile {
				font-size: 16px;
				line-height: 18px;
			}
		}

		& + span {
			margin-top: 20px;
		}
	}

	> span {
		display: block;
		width: 100%;
		text-align: left;
	}


	blockquote, .author, .info {
		& + .container-auteur {
			margin-top: 20px;
		}
	}

	.container-auteur {
		display: flex;
		align-items: center;

		@include tablet-portrait {
			flex-wrap: wrap;
		}

		.auteur {
			display: flex;
			align-items: center;
			flex-grow: 1;

			@include mobile {
				align-items: start;
				flex-direction: column;
			}

			.credits{
				display: none;
			}

			figure {
				flex-shrink: 0;

				img {
					border-radius: 50%;
				}

				& + .col-right {
					margin-top: $small-margin;

					@include min-mobile {
						margin-left: $small-margin;
						margin-top: 0;
					}
				}
			}

			.col-right {
				flex-grow: 1;
			}

			.social-links {
				margin-top: 0;
				margin-left: auto;

				@include tablet-portrait {
					margin-left: 0;
				}

				a {
					margin-top: 5px;
				}
			}

			p {
				margin-top: 14px;
				@include ft($f_txt, 14px, 300, $c_txt, 1.43);
			}

			& + .auteur {
				@include tablet-portrait {
					margin-top: $small-margin;
				}
				@include min-tablet-portrait {
					padding-left: $grid-gap;
				}
			}
		}

		.top {
			display: flex;
			justify-content: space-between;
			// flex-wrap: wrap;
			// Pour s'assurer que les liens sociaux ne soient pas attachés au texte lorsqu'ils vont automatiquement à la ligne (s'il y en a beaucoup)
			margin-top: -22px;
			@include tablet-portrait {
				flex-direction: column;
			}

			> * {
				margin-top: 20px;
			}

			.text-wrapper {
				> * {
					display: block;
				}

				.author {
					@include ft($f_hx, 25px, 700, $c_txt, 1.1);

					@include mobile {
						font-size: $s_small_title;
					}

					& + .surtitre {
						margin-top: 5px;
					}
				}

				.surtitre {
					@include ft($f_txt, $s_link, 600, $c_main, $s_link);
					text-transform: uppercase;
					margin-top: 0;
					@include mobile {
						font-size: $s_link_small;
					}
				}
			}

			& + p {
				margin-top: 20px;
			}

			.social-links {
				a {
					@include mobile {
						&:nth-child(n+8) {
							margin-top: 5px;
						}
					}
				}
			}
		}

		&.container-multi-auteurs {
			flex-wrap: wrap;
			align-items: flex-start;
			margin-top: 36px;
			margin-left: -10px;
			margin-right: -10px;
			@include tablet-portrait {
				flex-direction: column;
				margin-left: 0;
				margin-right: 0;
			}

			.auteur {
				flex-basis: calc(50% - 20px);
				margin-top: 20px;
				margin-right: 10px;
				margin-left: 10px;
				@include tablet-portrait {
					flex-basis: 100%;
					width: 100%;
					margin-left: 0;
					margin-right: 0;
				}

				& + .auteur {
					padding-left: 0;
				}

				.top, .text-wrapper {
					margin-top: 0;
				}

				.col-right {
					@include tablet-portrait {
						width: 100%;
					}
				}
			}

			.social-links {
				width: 100%;
				margin-top: 12px;
			}
		}
	}
}