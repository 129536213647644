.bloc-cartographie {
  position: relative;

  // gradient pour le slider
  &:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: block;
    width: 80px;
    height: 180px;
    background-image: linear-gradient(90deg, rgba(white, 0), rgba(white, 1));
    pointer-events: none;

    @include mobile {
      width: 40px;
      height: 110px;
    }
  }

  .maps {
    position: relative;
    z-index: 1;
    height: 480px;

    @include mobile {
      height: 370px;
    }

    .leaflet-popup-content {
      display: flex;
      align-items: center;
      width: auto;

      .image {
        margin-right: 20px;
        margin-bottom: 0;
        flex-shrink: 0;
      }
    }
  }

  .maps-popup {
    display: none;
  }

  .nav-arrows {
    @include absolute-stretch();

    .btn-arrow {
      position: absolute;
      top: 70px;

      &.--prev {
        left: 0;
        transform: translate(-50%, -50%);
      }

      &.--next {
        right: 0;
        transform: translate(50%, -50%);
      }
    }
  }

  .results {
    position: relative;
    padding: 10px 0 40px;

    .splide__track {
      position: relative;

      &:after {
        content: "";
        height: 100%;
        width: 100px;
        position: absolute;
        top: 0;
      }

      &:after {
        right: 0;
        background-image: linear-gradient(to right, rgba($c_white, 0), rgba($c_white, 1),);
      }
    }

    @include tablet-portrait {
      padding: 10px 0 0;
    }

    .splide__slide {
      --slide-gap: 10px;
      --slide-width: 360px;
      width: var(--slide-width);
      margin-right: var(--slide-gap);

      @include mobile {
        --slide-width: 320px;
      }

      @media screen and (max-width: 420px) {
        --slide-width: calc(100% - 40px);
      }
    }
  }
}
