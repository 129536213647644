.strate-footer-contact {
	.title {
		& + .container {
			margin-top: 25px;
		}
	}

	.container {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		flex-wrap: wrap;

		p {
			font-weight: 400;
			@include min-mobile {
				margin-bottom: 0;
			}
		}

		.text-styles {
			@include min-mobile {
				width: 50%;
				padding-right: 20px;
			}
		}

		.left-column {
			display: flex;
			flex-wrap: wrap;
			@include min-mobile {
				width: 50%;
				justify-content: flex-end;
				margin-top: -10px;
			}
			@include mobile {
				flex-direction: column;
			}

			> a {
				margin-top: 10px;
				@include mobile {
					width: auto;
				}

				&:last-of-type {
					margin-left: 10px;
					@include mobile {
						margin-left: 0;
						margin-top: 10px;
					}
				}
			}
		}
	}
}