.overlay-reservation {
	max-width: 520px;
	border-radius: 6px;
	transform: translate(-50%, -100%) !important;
	left: 50%;
	height: 540px;

	&.open {
		top: 50%;
		transform: translate(-50%, -50%) !important;
		left: 50%;
	}

	.scroller {
		max-width: 520px;
		padding: 45px 50px;
		@include mobile {
			padding: 30px 20px;
		}
	}

	#widget-panier {
		position: absolute;
		top: 40px;
		right: 80px;
		@include tablet-portrait{
			top: 20px;
			right: 60px;
		}
	}

	.panel {
		display: none;
	}

	.radio {
		display: none;
	}

	@for $i from 1 through 2 {
		#radio-#{$i}:checked ~ #panel-#{$i} {
			display: block
		}

		#radio-#{$i}:checked ~ .onglets #tab-#{$i} {
			border-bottom: 3px solid rgba($c_txt, 1);
		}
	}


	form {
		> * {
			margin-top: 10px;
		}

		// select
		.selectric-wrapper {
			.selectric {
				border: 1px solid $c_main;

				.label {
					padding: 16px 20px;
					@include ft($f_txt, 14px, bold, $c_txt, 18px);
				}
			}
		}

		// les dates
		.group-date {
			position: relative;
			width: 100%;
			max-width: 380px;
			flex-wrap: wrap;

			.wrapper-date {
				width: 50%;

				@include mobile {
					width: 100%;
				}
			}

			label {
				font-weight: bold;
				font-size: 14px;
			}

			input {
				width: 100%;
				font-weight: 400;
			}
		}

		.date-picker-wrapper {
			margin-top: 50px;
			position: absolute;
			transform: scale(0.55);
			//  padding: 0;
		}

		//nom de personne
		.opensystem_nbpers {
			display: flex;
			padding: 11px 20px;
			justify-content: center;
			align-items: center;
			border-radius: 6px;
			border: 1px solid $c_main;
			width: 100%;
			position: relative;

			input[type="number"] {
				-webkit-appearance: textfield;
				-moz-appearance: textfield;
				appearance: textfield;
				border: 0;
				text-align: center;
				font-weight: bold;
				width: 100px;
			}

			.before {
				position: absolute;
				top: 50%;
				left: 20px;
				font-weight: bold;
				transform: translateY(-50%);
				@include mobile {
					font-size: 14px;
				}
			}

			.after {
				position: absolute;
				top: 50%;
				right: 20px;
				transform: translateY(-50%);
				@include mobile {
					font-size: 10px;
				}
			}

			.number-modify {
				position: absolute;
				font-weight: bold;
				top: 50%;
				left: 42%;
				display: flex;
				align-items: center;
				justify-content: center;
				transform: translateY(-50%);
				cursor: pointer;

				&:before {
					font-size: 10px;
				}

				&.plus {
					left: auto;
					right: 42%;
				}
			}

			input[type=number]::-webkit-inner-spin-button,
			input[type=number]::-webkit-outer-spin-button {
				-webkit-appearance: none;
			}
		}
	}
}