// Overlay - Pop-in
// ==========================================================================
.overlay-popin {
  width: 100vw;
  max-width: none;
  background: transparent;
  display: flex !important;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;


  &.open {
    visibility: visible;
    opacity: 1;
    overflow: visible;
  }

  .inner-container {
    width: 960px;
    max-width: calc(100vw - 160px);
    position: relative;
    height: auto;
    padding: 50px;
    border-radius: 6px;
    background-color: $c_white;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);

    @media screen and (max-width: 800px) {
      width: calc(100% - 80px);
      margin: 0 40px;
    }
    @include tablet_portrait {
      max-width: 559px;
    }
    @include mobile {
      width: calc(100% - 40px);
      margin: 0 20px;
      padding: 25px;
    }
  }

  .--no-picture {
    .btn-close {
      @include tablet-portrait {
        top: 20px;
        right: 20px;
      }
    }

    .content {
      height: auto;
    }
  }

  .content {
    display: flex;
    min-height: 480px;
    height: 480px;
    max-height: calc(100vh - 60px);
    @include tablet_portrait {
      flex-direction: column;
      min-height: auto;
      height: auto;
    }
  }

  .col-left {
    flex-shrink: 0;
    max-width: 50%;
    @include tablet-portrait {
      max-width: 100%;
    }
    @include mobile {
      display: none;
    }

    .image {
      height: 100%;
      width: auto;
      @include tablet_portrait {
        --aspect-ratio: 410/480;
        @include aspect-ratio(var(--aspect-ratio), null);
      }

      figure {
        height: 100%;

        @include tablet_portrait {
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }

  .col-right {
    padding: 50px 0 60px 60px;
    max-height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }

    @include tablet-landscape {
      padding: 40px;
    }
    @include tablet_portrait {
      padding: 40px;
    }
    @include mobile {
      padding: 60px 20px;
    }

    .btn {
      align-self: flex-start;
    }

    h2 {
      @include min-tablet-landscape {
        font-size: 40px;
        line-height: 40px;
      }
      @include tablet-landscape {
        font-size: 35px;
        line-height: 30px;
      }
    }

    .form-container {
      margin-top: 30px;

      input[type="email"] {
        border-color: $c_sub;
      }

      .form-field {
        margin-bottom: 20px;
      }

      .form-field .frm_message, .frm_forms .frm_message p {
        color: $c_txt;
      }

      .form-field .frm_error {
        text-align: left;

        &:before {
          display: none !important;
        }
      }

      .frm_forms .frm_error_style {
        color: $c_txt;
      }

      .form-field .frm_primary_label {
        top: 25px;
      }

      .form-field .frm_opt_container .frm_checkbox label {
        font-size: 11px;
        line-height: 14px;

        input {
          pointer-events: auto;
          width: 100%;
          height: 100%;
        }

        input:checked {
          & ~ span {
            &:after {
              opacity: 1;
            }
          }
        }

        a {
          color: $c_txt;
          text-decoration: underline;
          font-weight: 700;

          @include can-hover {
            color: $c_main;
          }
        }
      }

      button {
        width: 100%;
      }
    }

  }
}
