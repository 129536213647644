.toolbar {
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: z-index('toolbar');
	display: flex;
	justify-content: center;
	height: var(--toolbar-height);
	border-radius: 8px 0 0 0;
	width: fit-content;
	margin-left: auto;
	background-color: $c_sub;

	@include mobile {
		justify-content: space-between;
	}
	@include mobile-small {
		// padding: 0 10px;
	}

	li {
		display: flex;
	}

	// styles communs des boutons
	.btn-toolbar {
		@include flex-center;
		padding: 0 30px 0 0;
		color: $c_white;

		@include mobile {
			padding: 0 20px 0 0;
		}
		@include mobile-small {
			padding: 0 10px 0 0;
		}

		[class^="icon-"], [class*=" icon-"] {
			font-size: 20px;
		}
	}

	.btn-reserver-mobile {
		min-width: auto;
		width: 50px;
		height: 50px;
		padding: 0;
		margin: 10px 0 10px 10px !important;

		.single-sit & {
			background-color: transparent;
			border-color: white;
		}

		#widget-panier{
			.entete{
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23fff'%3E%3Cpath d='M87.08 28.327H74.4v-.32c0-11.2-9.08-20.28-20.279-20.28H45.88c-11.2 0-20.28 9.08-20.28 20.28v.32H12.92A7.92 7.92 0 005 36.247v32.96c0 13.476 10.924 24.4 24.4 24.4h41.201C84.076 93.607 95 82.683 95 69.207v-32.96a7.92 7.92 0 00-7.92-7.92zm-53.88-.32c0-7.003 5.677-12.68 12.681-12.68h8.241c7.002 0 12.68 5.677 12.68 12.68v.32H33.2v-.32zm54.2 41.2c0 9.278-7.521 16.801-16.799 16.801H29.4c-9.279 0-16.801-7.522-16.801-16.801v-32.96a.32.32 0 01.32-.32H25.6v4.44a3.8 3.8 0 107.599 0v-4.44H66.8v4.44a3.8 3.8 0 107.6 0v-4.44h12.68a.32.32 0 01.32.32v32.96z'/%3E%3C/svg%3E");
			}
		}
	}

	.btn-search{
		width: 70px;
		display: flex;
		justify-content: center;
		align-items: center;
		span{
			font-size: 18px;
			&:before{
				color: white;
			}
		}
	}

	// Bouton pour ouvrir le menu de navigation fullscreen
	.btn-menu {
		@include shadow();
		@include addTransition();

		[class^="icon-"], [class*=" icon-"] {
			font-size: 15px;
		}

		.btn-menu-titre {
			text-transform: uppercase;
			margin-left: 10px;
			@include ft("", 10px, 600, $c_main, "");
		}
	}


	#widget-panier {
		pointer-events: none;
	}
}