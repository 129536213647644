// Styles pour la classe btn, btn et btn-primary.
.btn {
  @include inline-flex-center;
  max-width: 100%;
  min-width: 150px;
  min-height: 50px;
  padding: 15px 38px;
  @include ft($f_txt, $s_link, bold, $c_txt, 18px);
  text-align: center;
  text-decoration: none;
  border-radius: 6px;
  border: 1px solid $c_main;
  @include addTransition();

  @include mobile {
    padding: 15px;
    min-height: 40px;
    font-size: 14px;
  }
  @include mobile-small {
    padding: 12px 10px;
    min-height: 40px;
  }

  @include can-hover {
    color: $c_white;
    background-color: $c_main;
    border-color: $c_main;
  }

  & + .btn {
    margin-top: 0;
  }

  // -- modifiers
  &.btn-primary {
    color: $c_white;
    background-color: $c_main;
    border: 1px solid transparent;

    @include can-hover {
      color: $c_white;
      background-color: lighten($c_main, 5%);
      border-color: lighten($c_main, 5%);
    }
  }


  // bouton avec icone à gauche (Voir la carte, filtrer...)
  &.--with-icon {
    &:before {
      @include icomoon();
      position: relative;
      top: -1px;
      font-size: 18px;
      color: $c_sub;
      @include addTransition();

      @include min-tablet-portrait {
        margin-right: 10px;
      }
    }

    @include can-hover {
      &:before {
        color: $c_white;
      }
      .counter {
        color: $c_main;
        background-color: $c_white;
        outline: 1px solid $c_main;
      }
    }

    &[data-icon="location"] {
      &:before {
        content: $icon-location;
      }
    }

    &[data-icon="filtres"] {
      position: relative;

      &:before {
        content: $icon-filters;

        @include mobile {
          content: none;
        }
      }

      .counter {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(50%, -50%);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        min-width: 12px;
        height: 12px;
        font-size: 8px;
        border-radius: 50%;
        text-align: center;
        color: $c_white;
        background-color: $c_main;
        border: 1px solid transparent;
        @include addTransition();

        &:empty {
          display: none;
        }
      }
    }
  }
}

.btn-underline {
  text-transform: uppercase;
  @include ft($f_hx, $s_p, bold, $c_txt, 28px);
  position: relative;
  padding-bottom: 10px;

  &:after {
    content: "";
    background-color: $c_main;
    height: 3px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: none;
  }

  [class^="icon-"], [class*=" icon-"] {
    transition: none;
  }

  @include can-hover {
    color: $c_main;
    [class^="icon-"], [class*=" icon-"] {
      transition: none;
    }
  }
}

// Styles pour les boutons et les dots utilisés dans les sliders et dans la pagination
.btn-arrow {
  position: relative;
  z-index: z-index('btn-slider');
  @include inline-flex-center;
  width: 60px;
  height: 60px;
  font-size: 0;
  border-radius: 50%;
  border: 1px solid $c_sub;
  @include addTransition();

  @include mobile {
    width: 50px;
    height: 50px;
  }

  &:before {
    @include icomoon();
    font-size: 16px;
    @include addTransition();

    @include mobile {
      font-size: 10px;
    }
  }

  @include can-hover {
    &:not([disabled]) {
      cursor: pointer;
      border: 1px solid $c_main;
      background-color: $c_main;

      &:before {
        color: $c_white;
      }
    }
  }

  &[disabled] {
    opacity: 0.25;

    &:before {
      color: $c_sub;
    }
  }

  &.--white {
    border-color: $c_white;

    &[disabled] {
      &:before {
        color: $c_white;
      }
    }

    &:before, &:after {
      color: $c_white;
    }
  }


  &:first-of-type {
    margin-right: 15px;
  }

  // -- modifiers
  &.--prev:before {
    content: $icon-arrow-left;
  }

  &.--next:before {
    content: $icon-arrow-right;
  }
}

.container-nav {
  margin-bottom: var(--medium-margin);
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  flex-wrap: wrap;

  .nav-buttons {
    margin-left: auto;
  }
}

.pagination_counter {
  @include ft($f_hx, 20px, 300, "", 65px);
  display: flex;
  align-items: center;
  @include mobile {
    line-height: 38px;
  }

  & + .nav-dots {
    margin-left: var(--small-margin);
  }

  &.--white {
    color: $c_white;
  }

}

.pagination__total {
  @include ft("", 20px, "", "", "");

  @include mobile {
    font-size: 12px;
  }
}

.pagination__current {
  @include ft("", 50px, "", "", "");
  @include mobile {
    font-size: 30px;
  }
}


.nav-dots {
  display: flex;
  align-items: center;

  button {
    position: relative;
    width: 50px;
    height: 50px;

    & + button {
      margin-left: 10px;
    }

    @include mobile {
      width: 20px;
      height: 20px;
    }

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 4px;
      background-color: rgba($c_white, 0.30);
      border-radius: 0;
      left: 0;
      margin-left: 0;
      margin-right: 0;
      transition-delay: 0ms;
    }

    @include can-hover {
      &:not([aria-selected=true]) {
        cursor: pointer;
        background-color: transparent;

        &:after {
          background-color: rgba($c_main, 0.25);
        }
      }
    }

    &[aria-selected=true] {
      &:after {
        height: 6px;
        background-color: $c_main;
      }
    }
  }

  &.dots-v2 {
    button {
      &:after {
        background-color: rgba($c_main, 0.30);
      }

      &[aria-selected=true] {
        &:after {
          height: 6px;
          background-color: $c_main;
        }
      }
    }
  }
}


.btn-view-more {
  @include inline-flex-center;
  width: 30px;
  height: 30px;
  font-size: 12px;
  background-color: $c_main;
  @include addTransition();
  margin: 0 auto;
  display: none;
  border-radius: 50%;

  &:before {
    @include icomoon($icon-plus);
    @include addTransition();
    color: $c_white;
  }

  @include can-hover {
  }
}

// Boutons avec icône seule et au format rond (réseaux sociaux, liens sur la barre single sit...)
.btn-icon {
  position: relative;
  @include inline-flex-center;
  width: 50px;
  height: 50px;
  font-size: 15px;
  border-radius: 50%;
  background-color: $c_white;
  border: 1px solid $c_main;
  @include addTransition();

  @include mobile {
    width: 40px;
    height: 40px;
    font-size: 15px;
  }

  &:before,
  span {
    color: $c_txt;
  }

  @include can-hover {
    background-color: $c_main;

    &:before,
    span {
      color: $c_white;
    }

    .tooltip {
      opacity: 1;
      visibility: visible;
      transform: translate(-50%);
    }
  }

  &:before {
    @include icomoon();
    @include addTransition();
  }


  // -- modifiers
  &.--small {
    width: 40px;
    height: 40px;
    font-size: 15px;
  }

  &.--icon-big {
    font-size: 20px;
  }

  &.--cursor-external {
    @include can-hover {
      cursor: alias;
    }
  }

  // Réseaux sociaux
  &[data-icon="facebook"]:before {
    content: $icon-facebook;
  }

  &[data-icon="twitter"]:before {
    content: $icon-twitter;
  }

  &[data-icon="instagram"]:before {
    content: $icon-instagram;
  }

  &[data-icon="pinterest"]:before {
    content: $icon-pinterest;
  }

  &[data-icon="youtube"]:before {
    content: $icon-youtube;
  }

  &[data-icon="tripadvisor"]:before {
    content: $icon-tripadvisor;
  }

  // SIT
  &[data-icon="location"]:before {
    content: $icon-location;
  }

  &[data-icon="phone"]:before {
    content: $icon-phone;
  }

  &[data-icon="email"]:before {
    content: $icon-email;
  }

  &[data-icon="link"]:before {
    content: $icon-link;
  }

  &[data-icon="linkedin"]:before {
    content: $icon-linkedin;
  }
}


.btn-close {
  @include reset-appearance;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 14px;
  color: $c_sub;
  @include addTransition();

  &:before {
    @include icomoon($icon-close);
    @include addTransition();
  }

  @include can-hover {
    &:before {
      color: $c_main;
    }
  }

  &.--background {
    background-color: $c_white;
    border-radius: 50%;
    border: 1px solid $c_black;
  }
}

.btn-commentaire {
  position: relative;
  line-height: 1;
  margin: 0 10px;
  font-size: 19px;

  .comment-count {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
  }
}