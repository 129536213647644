// Tooltip : utilisé lorsqu'on est obligé d'imbriquer le texte dans un lien, parce que
// ==========================================================================
.tooltip {
  position: absolute;
  left: 50%;
  bottom: calc(100% + 4px);
  z-index: 20;
  padding: 4px 6px;
  @include ft('', 12px, 300, $c_white, 1.25);
  text-align: center;
  white-space: nowrap;
  background-color: $c_black;
  border-radius: 2px;
  box-shadow: 0 0 20px rgba($c_black, 0.15);
  transform: translate(-50%, 6px);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  @include addTransition();

  .no-hover & {
    display: none;
  }

  // Flèche de l'infobulle
  &:after {
    // @include triangle('down', $c_black, 12px, 6px);
    content: '';
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
  }

  // --modifiers
  &.--theme-light {
    color: $c_black;
    background-color: $c_white;

    &:after {
      border-top-color: $c_white;
    }
  }

  // Tooltip en-dessous, flèche vers le haut
  &.--position-bottom {
    bottom: initial;
    top: calc(100% - 4px);
    transform: translate(-50%, -6px);

    &:after {
      top: initial;
      bottom: 100%;
      border-top-color: transparent;
      border-bottom: 6px solid $c_black;
    }
  }
}
