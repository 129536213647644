@keyframes shine {
	to {
		background-position-x: -200%;
	}
}

.page-listing {
	margin-top: var(--header-height);

	&.listing-fma {
		margin-top: 0;

		// style de la couv sur les listing fma
		.general-container {
			padding-top: var(--header-height) !important;
		}

		.link-agenda-month {
			margin-top: 30px;
		}
	}

	header {
		margin-bottom: 0;

		& + * {
			margin-top: 20px;
		}

		.breadcrumbs {
			justify-content: end;
		}
	}

	.barre-listing-sit {
		.container {
			padding: 20px 100px 20px 40px;

			@include mobile {
				padding-right: 40px;
				margin-left: 0;
				margin-right: 0;
			}
		}
	}

	.listing-sans-carte {
		.listing-no-results {
			grid-column: 1 / -1; // stretch sur tout le wrapper
		}
	}

	.listing-avec-carte {
		.wrapper-content {
			position: relative;
			display: grid;
			grid-template-columns: minmax(350px, 40%) minmax(0, 1fr);
			grid-template-areas: "map cards";
			gap: 40px;
			padding-right: var(--border-gutter);

			@include tablet-landscape {
				grid-template-columns: 1fr;
				grid-template-areas: "cards";
				padding-left: var(--border-gutter);
			}

			.wrapper-sticky {
				grid-area: map;
				position: sticky;
				bottom: 0;
				top: 0;
				height: calc(100vh);

				.maps {
					height: 100%;
				}
			}

			.wrapper-cards {
				grid-area: cards;
				min-height: calc(100vh);

				@include mobile {
					min-height: auto;
				}

				.card {
					@include min-mobile {
						margin-bottom: 20px;
					}

					.title-card {
						@include min-mobile {
							font-size: 20px;
						}
					}
				}
			}
		}
	}

	.listing-agenda {
		.container-day-date {
			& + .container-day-date {
				margin-top: 70px;

				@include tablet-portrait {
					margin-top: 50px;
				}
				@include mobile {
					margin-top: 30px;
				}
			}

			.day-date {
				@include ft($f_hx, $s_h3, 400, $c_txt, 1);
				position: relative;
				top: 0;
				z-index: 50;
				margin-bottom: 40px;
				display: block;
				background-color: $c_white;

				@include mobile {
					font-size: 25px;
				}

				&:after {
					content: "";
					height: 3px;
					width: 100px;
					position: absolute;
					bottom: -20px;
					left: 0;
					background-color: $c_main;
				}
			}

			.wrapper-cards {
				margin-top: 60px;

				@include mobile {
					margin-top: 30px;
				}
			}
		}
	}

	.agenda-inputs {
		margin-top: 40px;
		@include mobile {
			margin-top: 20px;
		}

		input {
			display: none;
		}

		.choix-date {
			display: flex;
			overflow-x: auto;
			align-items: center;
			scroll-snap-type: x mandatory;
			overflow-y: hidden;

			> * {
				white-space: nowrap;
				scroll-snap-align: center;
			}

			> * + * {
				margin-left: 15px;
			}
		}

		.choix-date-label {
			cursor: pointer;
			margin-top: 0;
		}

		@for $i from 0 through 3 {
			input#today-#{$i} {
				&:checked {
					~ .choix-date label[for="today-#{$i}"] {
						opacity: 0.7;
					}
				}
			}

			input#ce_week_end-#{$i} {
				&:checked {
					~ .choix-date label[for="ce_week_end-#{$i}"] {
						opacity: 0.7;
					}
				}
			}

			input#cette_semaine_j7-#{$i} {
				&:checked {
					~ .choix-date label[for="cette_semaine_j7-#{$i}"] {
						opacity: 0.7;
					}
				}
			}

		}
	}

	.js-listing-card-container.is-loading {
		.card {
			pointer-events: none;

			.btn-favorite {
				display: none;
			}

			.image {
				figure, .no-photo, .date-event {
					display: none;
				}

				background: $c_grey;
				border-radius: 2px;
				background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
				background-size: 200% 100%;
				animation: 1s shine linear infinite;
			}

			.caption {
				> * {
					background: $c_grey;
					border-radius: 2px;
					background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
					background-size: 200% 100%;
					animation: 1s shine linear infinite;
					color: transparent;

					&:before, &:after {
						visibility: hidden;
					}

					* {
						visibility: hidden;
						color: transparent;

						&:before, &:after {
							visibility: hidden;
						}
					}
				}
			}
		}
	}
}