// BLOC VIDEO
// ==========================================================================
.bloc-video {
  z-index: 1;

  .container {
    position: relative;
    cursor: pointer;

    .mask-video {
      @include absolute-stretch();
      z-index: 1;
      opacity: 1;
      transition: all 0.7s ease-in-out;

      @include mobile {
        height: 100%;
      }

      @include can-hover {
        .caption {
          [class^="icon-"], [class*=" icon-"] {
            transform: scale(1.1);
            @include addTransition();
            background-color: $c_main;
            border: 1px solid $c_main;
          }
        }
      }

      .credits {
        display: none;
      }

      figure {
        opacity: 1;
        transition: all 0.7s ease-in-out;
        border-radius: $border-radius-img;

        @include mobile {
          height: 100%;
        }

        img {
          border-radius: $border-radius-img;
          height: 100%;
        }

        &:after {
          border-radius: $border-radius-img 0 0 $border-radius-img;
        }
      }

      .caption {
        [class^="icon-"], [class*=" icon-"] {
          font-size: 20px;
          border: 1px solid $c_white;
          color: $c_white;
          height: 60px;
          width: 60px;
          display: flex;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          opacity: 1;

          &:before {
            margin-left: 4px;
          }

          @include mobile {
            width: 40px;
            height: 40px;
            font-size: 15px;
          }

          & + .surtitre,
          & + h2, & + .h2,
          & + h3, & + .h3 {
            margin-top: 25px;

            @include mobile {
              margin-top: 10px;
            }
          }
        }

        h2, .h2,
        h3, .h3 {
          @include mobile {
            font-size: 20px;
            line-height: 0.9;
          }
        }
      }


      & ~ .embed-container {
        opacity: 0;
      }

      &.hide-mask {
        figure {
          opacity: 0;

          @media screen and (max-width: 750px) {
            opacity: 1;
          }
        }

        & + .embed-container {
          opacity: 1;
          z-index: 100;
          pointer-events: auto;
        }
      }
    }

    .embed-container {
      --aspect-ratio: 16/9;
      @include aspect-ratio(var(--aspect-ratio));
      min-height: 200px;
      transition: all 0.7s ease-in-out;
    }
  }

}