.bloc-micro-contenu {
  .image {
    --aspect-ratio: 960/520;
    @include mobile {
      --aspect-ratio: 380/252;
    }
    position: relative;
    flex-shrink: 0;
    @include aspect-ratio(var(--aspect-ratio), '> figure, > .no-photo');
  }


  .btn-icon {
    padding: 0;
    position: absolute;
    top: 20%;
    right: 35%;
    background-color: $c_main;
    z-index: 2;
    //  border: 6px solid darken($c_main, 0.30);

    &:before,
    span {
      color: $c_white;
    }

    &:after {
      content: "";
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: rgba($c_main, 0.30);
      position: absolute;
      z-index: 1;
      @include addTransition();
    }

    @include can-hover {
      transform: scale(1.2, 1.2);
      @include addTransition();
    }


    // Alignement
    // ligne 1
    &.top-1-1 {
      left: calc((1 / 5 * 100%) - 50px);
      top: calc((1 / 4 * 100%) - 50px);
      @include mobile {
        left: calc((1 / 5 * 100%) - 30px);
        top: calc((1 / 4 * 100%) - 30px);
      }
    }

    &.top-2-1 {
      left: calc((2 / 5 * 100%) - 50px);
      top: calc((1 / 4 * 100%) - 50px);
      @include mobile {
        left: calc((2 / 5 * 100%) - 30px);
        top: calc((1 / 4 * 100%) - 30px);
      }

    }

    &.top-3-1 {
      left: calc((3 / 5 * 100%) - 50px);
      top: calc((1 / 4 * 100%) - 50px);
      @include mobile {
        left: calc((3 / 5 * 100%) - 30px);
        top: calc((1 / 4 * 100%) - 30px);
      }
    }

    &.top-4-1 {
      left: calc((4 / 5 * 100%) - 50px);
      top: calc((1 / 4 * 100%) - 50px);
      @include mobile {
        left: calc((4 / 5 * 100%) - 30px);
        top: calc((1 / 4 * 100%) - 30px);
      }
    }

    // ligne 2
    &.top-1-2 {
      left: calc((1 / 5 * 100%) - 50px);
      top: calc((2 / 4 * 100%) - 50px);
      @include mobile {
        left: calc((1 / 5 * 100%) - 30px);
        top: calc((2 / 4 * 100%) - 30px);
      }
    }

    &.top-2-2 {
      left: calc((2 / 5 * 100%) - 50px);
      top: calc((2 / 4 * 100%) - 50px);
      @include mobile {
        left: calc((2 / 5 * 100%) - 30px);
        top: calc((2 / 4 * 100%) - 30px);
      }
    }

    &.top-3-2 {
      left: calc((3 / 5 * 100%) - 50px);
      top: calc((2 / 4 * 100%) - 50px);
      @include mobile {
        left: calc((3 / 5 * 100%) - 30px);
        top: calc((2 / 4 * 100%) - 30px);
      }
    }

    &.top-4-2 {
      left: calc((4 / 5 * 100%) - 50px);
      top: calc((2 / 4 * 100%) - 50px);
      @include mobile {
        left: calc((4 / 5 * 100%) - 30px);
        top: calc((2 / 4 * 100%) - 30px);
      }
    }

    // ligne 3
    &.top-1-3 {
      left: calc((1 / 5 * 100%) - 50px);
      top: calc((3 / 4 * 100%) - 50px);
      @include mobile {
        left: calc((1 / 5 * 100%) - 30px);
        top: calc((3 / 4 * 100%) - 30px);
      }
    }

    &.top-2-3 {
      left: calc((2 / 5 * 100%) - 50px);
      top: calc((3 / 4 * 100%) - 50px);
      @include mobile {
        left: calc((2 / 5 * 100%) - 30px);
        top: calc((3 / 4 * 100%) - 30px);
      }
    }

    &.top-3-3 {
      left: calc((3 / 5 * 100%) - 50px);
      top: calc((3 / 4 * 100%) - 50px);
      @include mobile {
        left: calc((3 / 5 * 100%) - 30px);
        top: calc((3 / 4 * 100%) - 30px);
      }
    }

    &.top-4-3 {
      left: calc((4 / 5 * 100%) - 50px);
      top: calc((3 / 4 * 100%) - 50px);
      @include mobile {
        left: calc((4 / 5 * 100%) - 30px);
        top: calc((3 / 4 * 100%) - 30px);
      }
    }
  }
}