.social-links {
	display: flex;
	
	@include mobile {
		flex-wrap: wrap;
	}

	a + a {
		margin-left: 5px;
	}
}