.bloc-relation {
  .bloc-relation__tabs {
    display: flex;
    align-items: center;

    @include min-tablet-portrait {
      flex-wrap: wrap;
      --col-gap: 35px;
      --row-gap: 5px;
      margin-left: calc(var(--col-gap) * -1);
      margin-top: calc(var(--row-gap) * -1);

      > * {
        margin-left: var(--col-gap);
        margin-top: var(--row-gap);
      }
    }

    @include tablet-portrait {
      overflow-x: auto;
      margin-left: calc(var(--border-gutter) * -1);
      margin-right: calc(var(--border-gutter) * -1);
      padding-left: var(--border-gutter);
      padding-right: var(--border-gutter);
      scroll-padding: var(--border-gutter);
      scroll-snap-type: x proximity;
      white-space: nowrap;

      > * + * {
        margin-left: 40px;
      }
    }

    button {
      @include reset-appearance();
      display: inline-flex;
      @include ft($f_hx, $s_p, 700, $c_txt, 1.5);
      border-bottom: 3px solid rgba($c_txt, 0);
      padding-bottom: 5px;
      text-transform: uppercase;

      @include mobile {
        font-size: 14px;
      }

      @include tablet-portrait {
        scroll-snap-align: start;
      }

      @include can-hover {
        &:not([aria-selected="true"]) {
          border-color: rgba($c_txt, 1);
        }
      }

      &[aria-selected="true"] {
        border-color: rgba($c_txt, 1);
      }
    }
  }

  * + .bloc-relation__panels {
    margin-top: 30px;
  }

  .bloc-relation__panels {
    position: relative;

    @include mobile {
      display: flex;
      flex-direction: column-reverse;
    }

    // bouton CTA commun à tous les sliders
    .btn-cta {
      @include min-mobile {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }

      @include mobile {
        align-self: flex-end;
      }
    }
  }

  .panel {
    &.is-hidden {
      display: none;
    }
  }

  .container-nav {

  }

  .nav-arrows {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .splide__track {
    margin-top: 20px;
    margin-bottom: var(--bloc-spacing);

    @include mobile {
      margin-top: 0;
    }
  }

  .splide__list {
    // hauteur temporaire pour demo preprod parce que les cards choisies ont un titre de 5 lignes
    height: 560px;
  }

  .splide__slide {
    --slide-width: 280px;
    width: var(--slide-width);
    max-width: 100%;

    &.is-active {
      .card {
        @include cardHoverActive();
      }
    }
  }

  .card {
    @include can-hover {
      @include cardHoverActive();
    }

    .btn-decouvrir {
      opacity: 0;
      @include addTransition();
      border-color: $c_white;
      color: $c_white;
      left: 30px;
      position: absolute;
      z-index: 4;
      right: 30px;
      bottom: 30px;
      top: auto;
    }

    .image {
      &:before {
        @include addTransition();
      }
    }

    // Force le format portrait des cards qui passe en horizontal par défaut en mobile
    @include mobile {
      flex-direction: column;

      .image {
        width: 100%;
        height: auto;
        margin-right: initial;
      }

      .caption {
        width: 100%;
        margin-top: 10px;
      }
    }
  }


  // --modifiers
  &.--direction-rtl {
    // garde ces éléments en ltr
    .nav-arrows,
    .card {
      direction: ltr;
    }

    @include min-mobile {
      .nav-buttons {
        margin-right: auto;
        margin-left: initial;
        display: flex;
        flex-direction: row-reverse;
      }

      .pagination_counter {
        display: flex;
        flex-direction: row-reverse;

        & + .nav-dots {
          margin-right: var(--small-margin);
        }
      }
    }
  }
}
