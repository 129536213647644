
.bloc-top {
  display: grid;
  grid-template-areas: "text image";
  grid-template-columns: 1fr 1fr;
  gap: var(--grid-gap);
  align-items: start;

  // -- modifiers
  // Texte à droite et photo à gauche -> inversion par rapport au DOM
  &.--text-right {
    grid-template-areas: "image text";
  }

  // Texte à gauche et photo à droite -> texte aligné à droite (seulement en tablet et >)
  &.--text-left {
    //  @include min-mobile {
    .col-text {
      text-align: right;
      // alignement à droite spécial pour les listes à puces
      ul:not(.meta-wrapper) {
        margin-left: 0;
        margin-right: 0;

        li {
          padding-left: initial;
          padding-right: 1.6em;

          &:before {
            left: initial;
            right: 4px;
          }
        }
      }

      // decoration du h2
      h3, .h3 {
        &:after {
          left: auto;
          right: 0;
        }
      }


      .container,
      .meta-wrapper,
      .location {
        justify-content: flex-end;
      }


      .meta-wrapper .meta {
        margin-right: 0;
        margin-left: 5px;

        &:first-of-type {
          margin-left: 0;
        }
      }
    }

    //  }
  }

  // Toujours image en premier et texte aligné à gauche sur mobile
  &.--text-right,
  &.--text-left {
    @include mobile {
      grid-template-areas: "text"
                                 "image";
      grid-template-columns: 1fr;
    }
  }

  .col-text {
    grid-area: text;
    margin-top: auto; // texte centré verticalement seulement si plus petit que l'image
    margin-bottom: auto;

    @include mobile {
      padding-bottom: var(--grid-gap);
    }
  }

  .col-image {
    grid-area: image;
  }

  .image {
    figcaption {
      @include mobile {
        display: none;
      }
    }
  }


  // Champs de la colonne de texte

  .number {
    @include ft($f_hx, 100px, 300, $c_main, 1);
    z-index: 2;
    display: block;
    margin-bottom: -15px;

    @include tablet-portrait {
      font-size: 60px;
    }

    & + .container {
      margin-top: 20px;
    }
  }


  .container {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 10px;

    .surtitre {
      margin: 0;
      padding: 0;

      & + .classement {
        margin-left: 5px;
        color: $c_main;
      }
    }

    .classement {
      display: flex;
      align-items: center;
    }

    & + h3 {
      margin-top: -6px;

      @include mobile {
        margin-top: 0;
      }
    }
  }

  .bloc-top-title {
    margin-top: 35px;
    margin-bottom: 40px;
  }

  .--with-pattern + .surtitre {
    margin-top: 0;
  }

  .meta-wrapper {
    margin-top: 12px;
    margin-bottom: 25px;
    margin-left: 0;
    margin-right: 0;

    & + .location {
      margin-top: -10px;
    }

    .meta {
      display: flex;
      justify-content: center;
      align-items: center;

      [class^="icon-"], [class*=" icon-"] {
        & + span {
          margin-left: 10px;
        }
      }
    }
  }

  .location {
    display: flex;
    align-items: center;
    padding: 0;

    &:before {
      position: relative;
      margin-right: 10px;
      line-height: 1.25;
    }

    & + p {
      margin-top: 20px;
    }
  }
}
