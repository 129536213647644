// Le positionnement des éléments est fait avec la propriété order parce que
// pour l'accessibilité il faut toujours avoir les dots comme premiers éléments dans la structure html,
// donc on est obligé à jouer avec les order en css

.bloc-galerie {
  --image-height: 500px;

  @include mobile {
    --image-height: 280px;
  }
  
  .splide__slide {
    max-width: 100%; // empèche qu'une image trés large déborde du viewport
    padding-bottom: var(--medium-margin);
  }

  .slide-galerie {
    position: relative;

    .img-wrapper {
      position: relative;

      @include can-hover {
        &:before {
          opacity: 1;
          border-radius: $border-radius-img;
        }

        &:after {
          opacity: 1;
          transform: translate(-50%, -50%) scale(1);
        }
      }

      &:before {
        @include pseudo-block-stretch(rgba($c_txt, 0.25));
        z-index: 1;
        opacity: 0;
        border-radius: $border-radius-img;
        @include addTransition(opacity, 0.4s);
      }

      &:after {
        @include icomoon($icon-plus);
        border: 1px solid $c_main;
        @include preserve-px-unit(40px); // font-size fixe pour les icônes
        color: $c_white;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $c_main;
        width: 50px;
        border-radius: 50%;
        height: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1.2);
        opacity: 0;
        z-index: 2;
        @include addTransition();
        @include mobile {
          width: 40px;
          height: 40px;
          @include preserve-px-unit(30px); // font-size fixe pour les icônes
        }
      }

    }

    // Figure en table pour contraindre la légende à la largeur de l'image
    figure {
      display: table;

      img {
        height: var(--image-height);
        width: auto;
      }
    }

    figcaption {
      display: table-caption;
      caption-side: bottom;
      cursor: auto;
    }
  }


  // --modifiers
  &.--direction-rtl {
    // Slider forcé en mode normal en mobile quelque soit l'option choisie
    @include min-mobile {
      .nav-buttons {
        margin-right: auto;
        margin-left: initial;
        display: flex;
        flex-direction: row-reverse;
      }

      .pagination_counter {
        display: flex;
        flex-direction: row-reverse;

        & + .nav-dots {
          margin-right: var(--small-margin);
        }
      }
    }
  }
}
