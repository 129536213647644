.bloc-form {

	.frm_hidden,
	.frm_add_form_row.frm_hidden,
	.frm_remove_form_row.frm_hidden,
	 .frm_button.frm_hidden {
		display: none !important;
	}

	form input.frm_verify {
		display: none !important;
	}

	 fieldset {
		min-width: 0;
	}

	 fieldset fieldset {
		border: none;
		margin: 0;
		padding: 0;
		background-color: transparent;
	}

	legend.frm_hidden {
		display: none !important;
	}

	 .frm_form_fields {
		opacity: 1;
		transition: opacity 0.1s linear;
	}

	 .frm_doing_ajax {
		opacity: .5;
	}

	.frm_transparent {
		color: transparent;
	}

	.input[type=file].frm_transparent:focus,  input[type=file] {
		background-color: transparent;
		border: none;
		outline: none;
		box-shadow: none;
	}

	 input[type=file] {
		display: initial;
	}

	.frm_preview_page:before {
		content: normal !important;
	}

	.frm_preview_page {
		padding: 25px;
	}

	 .frm_primary_label {
		max-width: 100%;
	}

	 .frm_top_container .frm_primary_label,
	 .frm_hidden_container .frm_primary_label,
	 .frm_pos_top {
		display: block;
		float: none;
		width: auto;
	}

	 .frm_inline_container .frm_primary_label {
		margin-right: 10px;
	}

	 .frm_right_container .frm_primary_label,
	 .frm_pos_right {
		display: inline;
		float: right;
		margin-left: 10px;
	}

	 .frm_none_container .frm_primary_label,
	 .frm_pos_none,
	.frm_pos_none,
	.frm_none_container .frm_primary_label {
		display: none;
	}

	 .frm_section_heading.frm_hide_section {
		margin-top: 0 !important;
	}

	 .frm_hidden_container .frm_primary_label,
	 .frm_pos_hidden,
	.frm_hidden_container .frm_primary_label {
		visibility: hidden;
		white-space: nowrap;
	}

	 .frm_inside_container .frm_primary_label {
		//opacity: 0;
		//transition: opacity 0.1s linear;
		display: none !important;
	}

	 .frm_inside_container label.frm_visible,
	.frm_visible {
		opacity: 1;
	}

	 .frm_description {
		clear: both;
	}

	 input[type=number][readonly] {
		-moz-appearance: textfield;
	}

	 select[multiple="multiple"] {
		height: auto;
		line-height: normal;
	}

	 .frm_catlevel_2,
	 .frm_catlevel_3,
	 .frm_catlevel_4,
	 .frm_catlevel_5 {
		margin-left: 18px;
	}

	 .wp-editor-container {
		border: 1px solid #e5e5e5;
	}

	 .quicktags-toolbar input {
		font-size: 12px !important;
	}

	 .wp-editor-container textarea {
		border: none;
	}

	 textarea {
		height: auto;
	}

	 .auto_width #loginform input,
	 .auto_width input,
	 input.auto_width,
	 select.auto_width,
	 textarea.auto_width {
		width: auto;
	}

	 .frm_repeat_buttons {
		white-space: nowrap;
	}

	 .frm_button {
		text-decoration: none;
		border: 1px solid #eee;
		padding: 5px;
		display: inline;
	}

	 .frm_submit {
		clear: both;
	}

	.frm_inline_form .frm_form_field,
	.frm_inline_form .frm_submit {
		grid-column: span 1 / span 1;
	}

	.frm_inline_form .frm_submit {
		margin: 0;
	}

	.frm_submit.frm_inline_submit input[type=submit],
	.frm_submit.frm_inline_submit button,
	.frm_inline_form .frm_submit input[type=submit],
	.frm_inline_form .frm_submit button {
		margin-top: 0;
	}

	.frm_center_submit .frm_submit {
		text-align: center;
	}

	.frm_center_submit .frm_submit input[type=submit],
	.frm_center_submit .frm_submit input[type=button],
	.frm_center_submit .frm_submit button {
		margin-bottom: 8px !important;
	}

	 .frm_submit input[type=submit],
	 .frm_submit input[type=button],
	 .frm_submit button {
		-webkit-appearance: none;
		cursor: pointer;
	}

	.frm_center_submit .frm_submit .frm_ajax_loading {
		display: block;
		margin: 0 auto;
	}

	 .frm_loading_form .frm_ajax_loading {
		/* keep this for reverse compatibility for old HTML */
		visibility: visible !important;
	}

	 .frm_loading_form .frm_button_submit {
		position: relative;
		opacity: .8;
		color: transparent !important;
		text-shadow: none !important;
	}

	 .frm_loading_form .frm_button_submit:hover,
	 .frm_loading_form .frm_button_submit:active,
	 .frm_loading_form .frm_button_submit:focus {
		cursor: not-allowed;
		color: transparent;
		outline: none !important;
		box-shadow: none;
	}

	 .frm_loading_form .frm_button_submit:before {
		content: '';
		display: inline-block;
		position: absolute;
		background: transparent;
		border: 1px solid #fff;
		border-top-color: transparent;
		border-left-color: transparent;
		border-radius: 50%;
		box-sizing: border-box;
		-webkit-animation: spin 2s linear infinite;
		-moz-animation: spin 2s linear infinite;
		-o-animation: spin 2s linear infinite;
		animation: spin 2s linear infinite;
	}

	.frm_ajax_loading {
		visibility: hidden;
		width: auto;
	}

	.frm_form_submit_style {
		height: auto;
	}

	a.frm_save_draft {
		cursor: pointer;
	}

	.horizontal_radio .frm_radio {
		margin: 0 30px 0 0;
	}

	.horizontal_radio .frm_checkbox {
		margin: 0;
		margin-right: 30px;
	}

	.vertical_radio .frm_checkbox,
	.vertical_radio .frm_radio,
	.vertical_radio .frm_catlevel_1 {
		display: block;
	}

	.horizontal_radio .frm_checkbox,
	.horizontal_radio .frm_radio,
	.horizontal_radio .frm_catlevel_1 {
		display: inline-block;
	}

	 .frm_checkbox label,
	 .frm_radio label {
		display: inline;
		white-space: normal;
	}

	 .vertical_radio .frm_checkbox label,
	 .vertical_radio .frm_radio label {
		display: block;
		padding-left: 20px;
		text-indent: -20px;
	}

	.frm_file_container .frm_file_link,
	 .frm_radio label .frm_file_container,
	 .frm_checkbox label .frm_file_container {
		display: inline-block;
		margin: 5px;
		vertical-align: middle;
	}

	 .frm_radio input[type=radio] {
		-webkit-appearance: radio;
		border-radius: 50%;
	}

	 .frm_checkbox input[type=checkbox] {
		-webkit-appearance: checkbox;
		border-radius: 0;
	}

	 .frm_radio input[type=radio],
	 .frm_checkbox input[type=checkbox] {
		flex: none;
		display: inline-block;
		margin: 4px 5px 0 0;
		width: auto;
		border: none;
		vertical-align: baseline;
		position: initial; /* override Bootstrap */
	}

	 :invalid,
	 :-moz-submit-invalid,
	 :-moz-ui-invalid {
		box-shadow: none;
	}

	 .frm_error_style img {
		padding-right: 10px;
		vertical-align: middle;
		border: none;
	}

	 .frm_trigger {
		cursor: pointer;
	}

	 .frm_error_style,
	 .frm_message,
	.frm_success_style {
		-moz-border-radius: 4px;
		-webkit-border-radius: 4px;
		border-radius: 4px;
		padding: 15px;
	}

	 .frm_message p {
		margin-bottom: 5px;
	}

	.frm_form_fields_style,
	.frm_form_fields_active_style,
	.frm_form_fields_error_style,
	.frm_form_submit_style {
		width: auto;
	}

	 .frm_trigger span {
		float: left;
	}

	 table.frm-grid,
	#content  table.frm-grid {
		border-collapse: collapse;
		border: none;
	}

	.frm-grid td,
	.frm-grid th {
		padding: 5px;
		border-width: 1px;
		border-style: solid;
		border-color: $c_sub;
		border-top: none;
		border-left: none;
		border-right: none;
	}

	.frm-alt-table {
		width: 100%;
		border-collapse: collapse;
		margin-top: 0.5em;
		font-size: 15px;
	}

	.frm-alt-table th {
		width: 200px;
	}

	.frm-alt-table tr {
		background-color: transparent;
	}

	.frm-alt-table th,
	.frm-alt-table td {
		background-color: transparent;
		vertical-align: top;
		text-align: left;
		padding: 20px;
	}

	table.form_results {
		border: 1px solid #ccc;
	}

	table.form_results tr td {
		text-align: left;
		color: $c_txt;
		padding: 7px 9px;
		border-top: 1px solid $c_sub;
	}

	table.form_results tr.frm_even,
	.frm-grid .frm_even {
		background-color: #fff;
	}

	//table.form_results tr.frm_odd,
	//.frm-grid .frm_odd {
	//	background-color: < ? php echo esc_html(FrmStylesHelper:: adjust_brightness($defaults [ 'border_color' ], 45));
	//	? >;
	//}

	.frm_collapse .ui-icon {
		display: inline-block;
	}

	.frm_toggle_container {
		/* Prevent the slide and bounce */
		border: 1px solid transparent;
	}

	.frm_toggle_container ul {
		margin: 5px 0;
		padding-left: 0;
		list-style-type: none;
	}

	.frm_toggle_container .frm_month_heading {
		text-indent: 15px;
	}

	.frm_toggle_container .frm_month_listing {
		margin-left: 40px;
	}

	#frm_loading {
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 99999;
	}

	#frm_loading h3 {
		font-weight: 500;
		padding-bottom: 15px;
		color: #fff;
		font-size: 24px;
	}

	#frm_loading_content {
		position: fixed;
		top: 20%;
		left: 33%;
		width: 33%;
		text-align: center;
		padding-top: 30px;
		font-weight: bold;
		z-index: 9999999;
	}

	#frm_loading img {
		max-width: 100%;
	}

	#frm_loading .progress {
		border-radius: 4px;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
		height: 20px;
		margin-bottom: 20px;
		overflow: hidden;
	}

	//#frm_loading .progress.active .progress-bar {
	//	animation: 2s linear 0s normal none infinite progress-bar-stripes;
	//}

	#frm_loading .progress-bar {
		background-color: $c_sub;
		box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.15) inset;
		float: left;
		height: 100%;
		line-height: 20px;
		text-align: center;
		transition: width 0.6s ease 0s;
		width: 100%;
	}

	.frm_image_from_url {
		height: 50px;
	}

	.frm-loading-img {
		background: url(../images/ajax_loader.gif) no-repeat center center;
		padding: 6px 12px;
	}

	select.frm_loading_lookup {
		background-image: url(../images/ajax_loader.gif) !important;
		background-position: 10px;
		background-repeat: no-repeat;
		color: transparent !important;
	}

	.frm_conf_field.frm_left_container .frm_primary_label {
		display: none;
	}

	.wp-editor-wrap *,
	.wp-editor-wrap *:after,
	.wp-editor-wrap *:before {
		-webkit-box-sizing: content-box;
		-moz-box-sizing: content-box;
		box-sizing: content-box;
	}

	 .frm_grid,
	 .frm_grid_first,
	 .frm_grid_odd {
		clear: both;
		margin-bottom: 0 !important;
		padding: 5px;
		border-width: 1px;
		border-style: solid;
		border-color: $c_sub;
		border-left: none;
		border-right: none;
	}

	 .frm_grid,
	 .frm_grid_odd {
		border-top: none;
	}

	.frm_grid .frm_error,
	.frm_grid_first .frm_error,
	.frm_grid_odd .frm_error {
		display: none;
	}

	.frm_grid:after,
	.frm_grid_first:after,
	.frm_grid_odd:after {
		visibility: hidden;
		display: block;
		font-size: 0;
		content: " ";
		clear: both;
		height: 0;
	}

	.frm_grid_first {
		margin-top: 20px;
	}

	//.frm_grid_first,
	//.frm_grid_odd {
	//	background-color: < ? php echo esc_html($defaults [ 'bg_color' ]);
	//	? >;
	//}
	//
	//.frm_grid {
	//	background-color: < ? php echo esc_html($defaults [ 'bg_color_active' ]);
	//	? >;
	//}

	.frm_grid .frm_primary_label,
	.frm_grid_first .frm_primary_label,
	.frm_grid_odd .frm_primary_label,
	.frm_grid .frm_radio,
	.frm_grid_first .frm_radio,
	.frm_grid_odd .frm_radio,
	.frm_grid .frm_checkbox,
	.frm_grid_first .frm_checkbox,
	.frm_grid_odd .frm_checkbox {
		float: left !important;
		display: block;
		margin-top: 0;
		margin-left: 0 !important;
	}

	.frm_grid_first .frm_radio label,
	.frm_grid .frm_radio label,
	.frm_grid_odd .frm_radio label,
	.frm_grid_first .frm_checkbox label,
	.frm_grid .frm_checkbox label,
	.frm_grid_odd .frm_checkbox label {
		visibility: hidden;
		white-space: nowrap;
		text-align: left;
	}

	.frm_grid_first .frm_radio label input,
	.frm_grid .frm_radio label input,
	.frm_grid_odd .frm_radio label input,
	.frm_grid_first .frm_checkbox label input,
	.frm_grid .frm_checkbox label input,
	.frm_grid_odd .frm_checkbox label input {
		visibility: visible;
		margin: 2px 0 0;
		float: right;
	}

	.frm_grid .frm_radio,
	.frm_grid_first .frm_radio,
	.frm_grid_odd .frm_radio,
	.frm_grid .frm_checkbox,
	.frm_grid_first .frm_checkbox,
	.frm_grid_odd .frm_checkbox {
		display: inline;
	}

	.frm_grid_2 .frm_radio,
	.frm_grid_2 .frm_checkbox,
	.frm_grid_2 .frm_primary_label {
		width: 48% !important;
	}

	.frm_grid_2 .frm_radio,
	.frm_grid_2 .frm_checkbox {
		margin-right: 4%;
	}

	.frm_grid_3 .frm_radio,
	.frm_grid_3 .frm_checkbox,
	.frm_grid_3 .frm_primary_label {
		width: 30% !important;
	}

	.frm_grid_3 .frm_radio,
	.frm_grid_3 .frm_checkbox {
		margin-right: 3%;
	}

	.frm_grid_4 .frm_radio,
	.frm_grid_4 .frm_checkbox {
		width: 20% !important;
	}

	.frm_grid_4 .frm_primary_label {
		width: 28% !important;
	}

	.frm_grid_4 .frm_radio,
	.frm_grid_4 .frm_checkbox {
		margin-right: 4%;
	}

	.frm_grid_5 .frm_primary_label,
	.frm_grid_7 .frm_primary_label {
		width: 24% !important;
	}

	.frm_grid_5 .frm_radio,
	.frm_grid_5 .frm_checkbox {
		width: 17% !important;
		margin-right: 2%;
	}

	.frm_grid_6 .frm_primary_label {
		width: 25% !important;
	}

	.frm_grid_6 .frm_radio,
	.frm_grid_6 .frm_checkbox {
		width: 14% !important;
		margin-right: 1%;
	}

	.frm_grid_7 .frm_primary_label {
		width: 22% !important;
	}

	.frm_grid_7 .frm_radio,
	.frm_grid_7 .frm_checkbox {
		width: 12% !important;
		margin-right: 1%;
	}

	.frm_grid_8 .frm_primary_label {
		width: 23% !important;
	}

	.frm_grid_8 .frm_radio,
	.frm_grid_8 .frm_checkbox {
		width: 10% !important;
		margin-right: 1%;
	}

	.frm_grid_9 .frm_primary_label {
		width: 20% !important;
	}

	.frm_grid_9 .frm_radio,
	.frm_grid_9 .frm_checkbox {
		width: 9% !important;
		margin-right: 1%;
	}

	.frm_grid_10 .frm_primary_label {
		width: 19% !important;
	}

	.frm_grid_10 .frm_radio,
	.frm_grid_10 .frm_checkbox {
		width: 8% !important;
		margin-right: 1%;
	}

	.frm_form_field.frm_inline_container .frm_opt_container,
	.frm_form_field.frm_right_container .frm_opt_container,
	.frm_form_field.frm_left_container .frm_opt_container {
		padding-top: 4px;
	}

	 .frm_inline_container.frm_grid_first .frm_primary_label,
	 .frm_inline_container.frm_grid .frm_primary_label,
	 .frm_inline_container.frm_grid_odd .frm_primary_label,
	 .frm_inline_container.frm_grid_first .frm_opt_container,
	 .frm_inline_container.frm_grid .frm_opt_container,
	 .frm_inline_container.frm_grid_odd .frm_opt_container {
		margin-right: 0;
	}

	.frm_form_field.frm_two_col .frm_opt_container,
	.frm_form_field.frm_three_col .frm_opt_container,
	.frm_form_field.frm_four_col .frm_opt_container {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-auto-rows: max-content;
		grid-gap: 0 2.5%;
	}

	.frm_form_field.frm_three_col .frm_opt_container {
		grid-template-columns: repeat(3, 1fr);
	}

	.frm_form_field.frm_four_col .frm_opt_container {
		grid-template-columns: repeat(4, 1fr);
	}

	.frm_form_field.frm_two_col .frm_radio,
	.frm_form_field.frm_two_col .frm_checkbox,
	.frm_form_field.frm_three_col .frm_radio,
	.frm_form_field.frm_three_col .frm_checkbox,
	.frm_form_field.frm_four_col .frm_radio,
	.frm_form_field.frm_four_col .frm_checkbox {
		grid-column-end: span 1;
	}

	.frm_form_field .frm_checkbox,
	.frm_form_field .frm_checkbox + .frm_checkbox,
	.frm_form_field .frm_radio,
	.frm_form_field .frm_radio + .frm_radio {
		margin-top: 0;
		margin-bottom: 0;
	}

	.frm_form_field.frm_scroll_box .frm_opt_container {
		height: 100px;
		overflow: auto;
	}

	.frm_html_container.frm_scroll_box,
	.frm_form_field.frm_html_scroll_box {
		height: 100px;
		overflow: auto;
		max-width: 100%;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		outline: none;
		font-weight: normal;
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
	}

	.frm_form_field.frm_total input,
	.frm_form_field.frm_total textarea {
		opacity: 1;
		background-color: transparent !important;
		border: none !important;
		font-weight: bold;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		width: auto !important;
		box-shadow: none !important;
		display: inline;
		-moz-appearance: textfield;
		padding: 0;
	}

	.frm_form_field.frm_total input::-webkit-outer-spin-button,
	.frm_form_field.frm_total input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	.frm_form_field.frm_total input:focus,
	.frm_form_field.frm_total textarea:focus {
		background-color: transparent;
		border: none;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.frm_form_field.frm_label_justify .frm_primary_label {
		text-align: justify !important;
	}

	.frm_form_field.frm_capitalize input,
	.frm_form_field.frm_capitalize select,
	.frm_form_field.frm_capitalize .frm_opt_container label {
		text-transform: capitalize;
	}

	.frm_clearfix:after {
		content: ".";
		display: block;
		clear: both;
		visibility: hidden;
		line-height: 0;
		height: 0;
	}

	.frm_clearfix {
		display: block;
	}

	/* Responsive */
	@media only screen and (max-width: 900px) {
		.frm_form_field .frm_repeat_grid .frm_form_field.frm_sixth .frm_primary_label,
		.frm_form_field .frm_repeat_grid .frm_form_field.frm_seventh .frm_primary_label,
		.frm_form_field .frm_repeat_grid .frm_form_field.frm_eighth .frm_primary_label {
			display: block !important;
		}
	}

	@include mobile {
		.frm_form_field.frm_four_col .frm_opt_container {
			grid-template-columns: repeat(2, 1fr);
		}

		.frm_form_field.frm_two_col .frm_radio,
		.frm_form_field.frm_two_col .frm_checkbox,
		.frm_form_field.frm_three_col .frm_radio,
		.frm_form_field.frm_three_col .frm_checkbox {
			width: auto;
			margin-right: 0;
			float: none;
			display: block;
		}

		.frm_form_field input[type=file] {
			max-width: 220px;
		}

		 .frm-g-recaptcha > div > div,
		 .g-recaptcha > div > div {
			width: inherit !important;
			display: block;
			overflow: hidden;
			max-width: 302px;
			border-right: 1px solid #d3d3d3;
			border-radius: 4px;
			box-shadow: 2px 0px 4px -1px rgba(0, 0, 0, .08);
			-moz-box-shadow: 2px 0px 4px -1px rgba(0, 0, 0, .08);
		}

		 .g-recaptcha iframe,
		 .frm-g-recaptcha iframe {
			width: 100%;
		}
	}
}