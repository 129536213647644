.barre-sticky {
	--barre-height: 80px;
	--border-spacing: 80px;
	position: sticky;
	bottom: 20px;
	z-index: z-index('barre-sticky');
	display: flex;
	width: 100%;
	max-width: 845px;
	margin-left: auto;
	margin-right: var(--border-spacing);
	margin-top: 40px; // espace quand non sticky

	@include tablet_landscape {
		--border-spacing: 40px;
	}

	@include tablet_portrait {
		--barre-height: 70px;
		--border-spacing: 20px;

		max-width: initial;
		margin-right: 0;
		top: 0;
		right: 0;
		bottom: auto;
		position: fixed;
		visibility: hidden;
		margin-top: 0;
		opacity: 0;
		@include addTransition();

		.is-scrolled & {
			visibility: visible;
			opacity: 1;
			@include addTransition();
		}
	}

	@include mobile {
		--barre-height: 60px;
	}

	.barre-sticky__inner {
		display: flex;
		width: 100%;
		min-height: var(--barre-height);
		border-radius: 6px;
		padding: 0 calc(var(--border-spacing) / 2) 0 0;
		background-color: $c_white;
		@include shadow();
	}


	//  Ancres
	// -----------------------------------------------

	.slider-ancres {
		display: flex;
		height: 100%;
		min-width: 0;
		margin-right: 40px;
	}

	.splide__slide {
		display: flex;
		margin-right: var(--grid-gap);
	}

	.link-ancre {
		display: inline-flex;
		align-items: center;
		padding: 12px 0;
		@include ft('', 16px, 300, rgba($c_txt, 0.75), 1.25);
		transition: initial;
		position: relative;
		@include tablet-portrait {
			scroll-snap-align: start;
		}

		&:after {
			content: "";
			width: 100%;
			height: 3px;
			background-color: $c_main;
			position: absolute;
			bottom: 0;
			opacity: 0;
			transform: translate(0, -50%);
		}

		@include can-hover {
			&:not(.is-active) {
				color: $c_txt;
			}
		}

		&.is-active {
			font-weight: 700;
			color: $c_txt;

			&:after {
				opacity: 1;
			}
		}
	}

	.nav-ancres {
		@include absolute-stretch();
		pointer-events: none;

		.btn-arrow-ancre {
			pointer-events: initial;
			position: absolute;
			z-index: 1;
			height: 100%;

			&.--prev {
				left: 0;
				border-radius: 6px;
			}

			&.--next {
				right: 0;
				border-radius: 6px;
			}
		}
	}

	.btn-arrow-ancre {
		position: relative;
		@include inline-flex-center;
		width: 40px;
		@include addTransition();

		&:before {
			@include icomoon();
			color: $c_txt;
			font-size: 12px;
		}

		&[disabled] {
			display: none;
		}

		// -- modifiers
		&.--prev {
			background-image: linear-gradient(to right, rgba($c_white, 100%) 20%, rgba($c_white, 0));

			&:before {
				content: $icon-arrow-left;
			}
		}

		&.--next {
			background-image: linear-gradient(to left, rgba($c_white, 100%) 20%, rgba($c_white, 0));

			&:before {
				content: $icon-arrow-right;
			}
		}
	}

	//  Zone à gauche (sur le listing des SIT)
	// -----------------------------------------------

	.barre-sticky__left {
		display: flex;
		align-items: center;
		flex-grow: 1;

		.resultats {
			flex-grow: 1;
		}
		
		
		.btn-show-map {
			@include min-tablet-portrait {
				display: none;
			}
		}
	}


	//  Pagination
	// -----------------------------------------------

	.resultats {
		@include ft('', 16px, 400, '', 1.1);
		padding-left: 30px;

		@include tablet-portrait {
			display: none;
		}
	}


	//  Zone à droite
	// -----------------------------------------------

	.barre-sticky__right {
		display: flex;
		align-items: center;
		flex-shrink: 0;
		margin-left: auto;

		> * {
			flex-shrink: 0;
		}
	}


	.btn-cta-sticky {
		min-height: initial;

		@include tablet-portrait {
			height: calc(100% - 20px);
		}
		@include mobile {
			height: calc(100% - 10px);
		}

		& + * {
			margin-left: 20px;

			@include tablet-portrait {
				margin-left: 10px;
			}
		}
	}

	.social-share {
		& + * {
			margin-left: 20px;

			@include tablet-portrait {
				margin-left: 10px;
			}
		}

		@include tablet-portrait {
			display: none;
		}
	}

	.btn-map {
		@include desktop {
			span {
				display: none;
			}
		}
	}

	.btn-map,
	.btn-filters {
		// mode icon seulement en tablet
		@include desktop {
			min-width: initial;

			&:before {
				margin-right: 10px;
			}
		}

		@include tablet-portrait {
			min-height: initial;
			height: 50px;
		}
	}

	.btn-filters.btn-filters {
		&:before {
			font-size: 13px;

			@include tablet-small {
				display: none;
			}
		}

		@include mobile {
			padding: 15px 30px;
		}
	}

	.btn-filters + .social-share {
		margin-left: 30px;
	}

	.date-picker-wrapper {
		top: -570% !important;
		transform: scale(0.90);
		left: 0 !important;

		.month-wrapper {
			display: flex;

			table {
				width: calc(20%);
				float: left;
			}

			.gap {
				visibility: hidden !important;
			}
		}
	}

	.wrapper-date {
		max-width: 200px;
		min-width: 150px;
		max-height: 50px;
		text-align: center;

		@media (max-width: 1130px) {
			padding: 5px;
		}

		&:before {
			content: none;
		}

		input {
			width: 135px;

			&::-webkit-inner-spin-button,
			&::-webkit-calendar-picker-indicator {
				display: none;
				-webkit-appearance: none;
			}
		}
	}

	.container-dates {
		display: flex;
		align-items: center;

		@include mobile {
		}

		.btn {
			@include mobile {
				margin-right: 2px;
				min-width: auto;
			}
		}

		.date-detail {
			display: none;
			/*@include tablet_portrait {
				display: flex;
				flex-direction: column;
				margin-left: 10px;
			}*/
		}

		.start-date, .end-date {
			@include ft($f_txt, 10px, 400, $c_white, 14px);
			@include tablet_portrait {
				color: $c_main;
			}

			.date {
				font-weight: 700;
			}
		}
	}


	.facettes-wrapper {
		& + * {
			margin-left: 10px;

			@include mobile {
				margin-left: 5px;
			}
		}
		
		.group-filtres.group-select {
			.selectric-wrapper .selectric {
				min-height: initial;
				min-width: 260px;
				height: 50px;
				padding: 0 40px 0 20px;
			}
		}
	}


	// *** barre d'ancres : mode réduit et calé à droite en tablet et <
	&.--ancres {
		@include tablet-landscape {
			justify-content: flex-end;

			.barre-sticky__inner {
				width: auto;
				padding-left: 10px;
			}
		}
		@include mobile {
			.barre-sticky__inner {
				width: 100%;
			}
		}
	}


	// *** seulement boutons de favoris et partage -> docké en bas à droite
	&.--compact {
		justify-content: flex-end;

		.barre-sticky__inner {
			width: auto;
			padding-left: 30px;
			padding-right: 30px;
		}

		@include mobile {
			.barre-sticky__inner {
				width: 100%;
			}
		}
	}


	// *** sur les listings des SIT
	&.--listing-sit {

		@include tablet-portrait {
			opacity: 0;
			top: 0;
			position: fixed;
			bottom: auto;
			margin-top: 0;
			@include addTransition();

			.is-scrolled & {
				opacity: 1;
				@include addTransition();
			}
		}

		.barre-sticky__inner {
			padding-left: 10px;
		}
	}

	// sur les listing avec carte on cache le btn carte
	&.--listing-fma {
		.btn-map {
			display: none;
		}
	}

	&.--listing-with-carte {
		max-width: 770px;

		.btn-map {
			@include min-mobile {
				display: none;
			}
		}
	}

	&:not(.--listing-with-carte){
		.btn-show-map{
			display: none;
		}
	}
	&.--no-filters:not(.--listing-with-carte){
		display: none;
	}


	// *** sur les single SIT
	&.--single-sit {
		.barre-sticky__inner {
			padding-left: 20px;
		}

		.barre-sticky__left {
			display: flex;
			align-items: center;
			padding-right: 40px;


			.content {
				display: flex;
				flex-direction: column;
			}

			.container {
				display: flex;
				align-items: center;
				margin-right: 10px;
				line-height: 1;
			}

			.surtitre {
				font-size: 10px;
				text-transform: uppercase;
			}

			.classement {
				margin-left: 8px;
				font-size: 12px;
				color: $c_main;
			}

			.title {
				@include ft($f_hx, 14px, 700, $c_txt, 1.3);
			}

			.location {
				font-size: 12px;
			}
		}

		.barre-sticky__right {
			@include tablet-portrait {
				width: 100%;
			}

			@include mobile {
				width: auto;
				margin-left: auto;
			}
		}

		.date-event {
			color: $c_main;
			display: flex;
			font-size: 12px;
			font-weight: 500;
		}

		.contact-links {
			display: flex;
			align-items: center;

			@include tablet-portrait {
				margin-right: auto;
			}

			li + li {
				margin-left: 10px;
			}
		}

		.btn-booking {
			min-width: initial;

			& + * {
				margin-left: 20px;

				@include mobile {
					margin-left: 10px;
				}
			}

			@include mobile {
				min-width: auto;
				padding: 13px 30px;
			}
		}

		.contact-links {
			& + * {
				margin-left: 30px;

				@include mobile {
					margin-left: 24px;
				}
			}
		}
	}

}
