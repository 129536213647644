// Custom Windows Map
// ==========================================================================
.custom-popup-thuria {
	width: 410px;
	margin-bottom: 50px;

	@include mobile {
		width: 340px;
	}

	.leaflet-popup-wrapper {
		padding: 0;
		border-radius: 0;
		box-shadow: 0 10px 20px rgba($c_sub, 0.1);

		.leaflet-popup-content {
			margin: 0;
			width: 100% !important;

			.card-map {
				position: relative;
				top: auto;
				right: auto;
				transform: none;
				box-shadow: none;
				display: block;
			}

			.card-map-mobile {
				display: none;
				@include mobile {
					display: block;
				}
			}


		}
	}

	.leaflet-popup-tip-container {
		width: 20px;
		height: 20px;
		transform: rotate(45deg);
		position: absolute;
		left: 50%;
		margin-left: -10px;
		bottom: -10px;
		overflow: hidden;
		pointer-events: none;

		.leaflet-popup-tip {
			padding: 0;
			box-shadow: none;
			margin: 0 auto;
			width: 26px;
			height: 26px;
			border-radius: 0;
			transform: none;
		}
	}

	a.leaflet-popup-close-button {
		position: absolute;
		top: -15px;
		right: -15px;
		background: $c_main;
		color: $c_white;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 15px;
		width: 30px;
		height: 30px;
		line-height: 27px;

		&:after {
			content: none;
		}

		&:hover {
			body:not(.no-hover) & {
				cursor: pointer;
				background: lighten($c_main, 5%);
				color: $c_white;
			}
		}
	}

	.card {
		@include card-horizontale();
		padding: 10px !important;

		.title-card {
			margin-bottom: 5px;
		}
	}
}