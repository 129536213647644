// Srcolly itinéraires
// =================================================
.page-scrolly {
  position: relative;
  width: 100%;
  margin-top: var(--header-height);

  &:after {
    display: block;
    content: "";
    clear: both;
  }

  //WRAPPER MAP
  .wrapper-map {
    background-color: white;
    --height-map: 100vh;
    @include tablet_portrait {
      --height-map: 240px;
    }
    height: var(--height-map);
    position: sticky;
    float: left;
    top: 0;
    margin-left: 0;
    width: calc(100% - 640px);
    z-index: 10;
    @include addTransition();

    .is-scrolled & {
      height: var(--height-map);
    }

    .is-scrolled.scrolldir-top & {
      height: var(--height-map);
    }

    @media screen and (max-width: 1280px) {
      width: 50%;
    }

    @include tablet_portrait {
      position: sticky;
      width: 100%;
      pointer-events: visible;
      cursor: pointer;
      z-index: 50;
    }

    .leaflet-container {
      overflow: hidden;
    }

    .maps {
      width: 100%;
      height: 100%;

      .index-key {
        position: relative;
        right: 0;
        top: 0;
        height: 25px;
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 0;
        @include ft($f_txt, 12px, 700, $c_white, 12px);

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          border-radius: 50%;
          height: 100%;
          background-color: $c_main;
          color: $c_white;
          background-position: 50% 50%;
          z-index: -1;
          @include addTransition();
        }

        &.sliderFlash {
          &::after {
            background-color: $c_sub;
          }
        }
      }
    }
  }

  .content-right {
    position: relative;
    float: right;
    overflow-x: hidden;
    width: 50%;
    max-width: 640px;
    background-color: $c_white;
    z-index: 10;
    padding: 0 var(--border-gutter);
    @include tablet_portrait {
      margin-top: var(--height-map);
    }

    @media screen and (max-width: 1280px) {
      max-width: 100%;
      width: 50%;
    }

    @include tablet_landscape {
      width: 50%;
    }

    @include tablet_portrait {
      width: 100%;
    }
  }

  .section {
    .bloc {
      margin: $bloc-spacing-mobile 0 0 0;
    }

    .bloc-infos-cles {
      .container-elements {
        justify-content: flex-start;

        & > * {
          width: calc(50% - var(--col-gap));
        }
      }

      .inner-container {
        .caption {
          margin-left: 10px;

          .title {
            font-size: 18px;
            @include mobile {
              font-size: 16px;
            }
          }
        }
      }
    }

    // style des blocs pour l'overlay
    .bloc-focus {
      .container-focus {
        display: flex;
        flex-direction: column;
        @include min-mobile {
          padding: 50px;
        }

        [class^="icon-"], [class*=" icon-"] {
          position: initial;
          margin-bottom: 20px;
          font-size: 60px;
          transform: initial;
        }

      }
    }

    .bloc-relation-card-horizontales {
      .card {
        .caption {
          &::before {
            display: flex;
          }
        }
      }
    }

    .text-styles {
      p {
        font-size: 14px;
      }

      h2, .h2 {
        @include min-tablet-landscape {
          font-size: 30px;
        }
      }

      h3, .h3 {
        @include min-tablet-landscape {
          font-size: 26px;
        }
      }
    }
  }
}


