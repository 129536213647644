.cover-scrolly {
  padding: 60px 0;
  @include mobile {
    padding: 20px 0;
  }

  .coverImage {
    position: relative;
    margin-top: 30px;
  }

  .btn-gallery {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
}
