@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?qvgw7a') format('woff2'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?qvgw7a') format('truetype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?qvgw7a') format('woff'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?qvgw7a##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-tiktok {
  &:before {
    content: $icon-tiktok;
  }
}
.icon-document-v {
  &:before {
    content: $icon-document-v;
  }
}

.icon-ico-fleur {
  &:before {
    content: $icon-ico-fleur;
  }
}

.icon-linkedin {
  &:before {
    content: $icon-linkedin;
  }
}

.icon-location {
  &:before {
    content: $icon-location;
  }
}

.icon-arrow-down-alt {
  &:before {
    content: $icon-arrow-down-alt;
  }
}

.icon-arrow-up {
  &:before {
    content: $icon-arrow-up;
  }
}

.icon-puce {
  &:before {
    content: $icon-puce;
  }
}

.icon-commentaire {
  &:before {
    content: $icon-commentaire;
  }
}

.icon-carte {
  &:before {
    content: $icon-carte;
  }
}

.icon-grille-chateau {
  &:before {
    content: $icon-grille-chateau;
  }
}

.icon-grille-coeur {
  &:before {
    content: $icon-grille-coeur;
  }
}

.icon-cadeau {
  &:before {
    content: $icon-cadeau;
  }
}

.icon-visite-musee {
  &:before {
    content: $icon-visite-musee;
  }
}

.icon-terroir-vin {
  &:before {
    content: $icon-terroir-vin;
  }
}

.icon-bien-etre-spa {
  &:before {
    content: $icon-bien-etre-spa;
  }
}

.icon-spot-photo {
  &:before {
    content: $icon-spot-photo;
  }
}

.icon-quizz {
  &:before {
    content: $icon-quizz;
  }
}

.icon-point-de-depart {
  &:before {
    content: $icon-point-de-depart;
  }
}

.icon-patrimoine {
  &:before {
    content: $icon-patrimoine;
  }
}

.icon-noel {
  &:before {
    content: $icon-noel;
  }
}

.icon-niveau {
  &:before {
    content: $icon-niveau;
  }
}

.icon-nature-faune {
  &:before {
    content: $icon-nature-faune;
  }
}

.icon-ludique {
  &:before {
    content: $icon-ludique;
  }
}

.icon-montgolfiere {
  &:before {
    content: $icon-montgolfiere;
  }
}

.icon-transport-acces {
  &:before {
    content: $icon-transport-acces;
  }
}

.icon-grille-itineraire {
  &:before {
    content: $icon-grille-itineraire;
  }
}

.icon-activites-nature {
  &:before {
    content: $icon-activites-nature;
  }
}

.icon-grille-budget {
  &:before {
    content: $icon-grille-budget;
  }
}

.icon-grille-sablier {
  &:before {
    content: $icon-grille-sablier;
  }
}

.icon-grille-calendrier {
  &:before {
    content: $icon-grille-calendrier;
  }
}

.icon-grille-animaux {
  &:before {
    content: $icon-grille-animaux;
  }
}

.icon-grille-fromage {
  &:before {
    content: $icon-grille-fromage;
  }
}

.icon-grille-girafe {
  &:before {
    content: $icon-grille-girafe;
  }
}

.icon-grille-lune {
  &:before {
    content: $icon-grille-lune;
  }
}

.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
  }
}

.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}

.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}

.icon-search {
  &:before {
    content: $icon-search;
  }
}

.icon-close {
  &:before {
    content: $icon-close;
  }
}

.icon-minus {
  &:before {
    content: $icon-minus;
  }
}

.icon-plus {
  &:before {
    content: $icon-plus;
  }
}

.icon-download {
  &:before {
    content: $icon-download;
  }
}

.icon-play {
  &:before {
    content: $icon-play;
  }
}

.icon-filters {
  &:before {
    content: $icon-filters;
  }
}

.icon-refresh {
  &:before {
    content: $icon-refresh;
  }
}

.icon-credits {
  &:before {
    content: $icon-credits;
  }
}

.icon-menu {
  &:before {
    content: $icon-menu;
  }
}

.icon-checkmark {
  &:before {
    content: $icon-checkmark;
  }
}

.icon-document {
  &:before {
    content: $icon-document;
  }
}

.icon-quote {
  &:before {
    content: $icon-quote;
  }
}

.icon-agenda {
  &:before {
    content: $icon-agenda;
  }
}

.icon-pin {
  &:before {
    content: $icon-pin;
  }
}

.icon-email {
  &:before {
    content: $icon-email;
  }
}

.icon-phone {
  &:before {
    content: $icon-phone;
  }
}

.icon-link {
  &:before {
    content: $icon-link;
  }
}

.icon-social-share {
  &:before {
    content: $icon-social-share;
  }
}

.icon-tripadvisor {
  &:before {
    content: $icon-tripadvisor;
  }
}

.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}

.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}

.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}

.icon-pinterest {
  &:before {
    content: $icon-pinterest;
  }
}

.icon-youtube {
  &:before {
    content: $icon-youtube;
  }
}

.icon-etoile {
  &:before {
    content: $icon-etoile;
  }
}

.icon-cle {
  &:before {
    content: $icon-cle;
  }
}

.icon-epis {
  &:before {
    content: $icon-epis;
  }
}

.icon-toque {
  &:before {
    content: $icon-toque;
  }
}

.icon-langues {
  &:before {
    content: $icon-langues;
  }
}

.icon-paiement {
  &:before {
    content: $icon-paiement;
  }
}

.icon-animal {
  &:before {
    content: $icon-animal;
  }
}

.icon-chef-hat {
  &:before {
    content: $icon-chef-hat;
  }
}

.icon-velo {
  &:before {
    content: $icon-velo;
  }
}

.icon-grille-famille {
  &:before {
    content: $icon-grille-famille;
  }
}

.icon-grille-couple {
  &:before {
    content: $icon-grille-couple;
  }
}

.icon-grille-bateau {
  &:before {
    content: $icon-grille-bateau;
  }
}

.icon-grille-rando {
  &:before {
    content: $icon-grille-rando;
  }
}

.icon-grille-velo {
  &:before {
    content: $icon-grille-velo;
  }
}

.icon-grille-dejeuner {
  &:before {
    content: $icon-grille-dejeuner;
  }
}

.icon-grille-logement {
  &:before {
    content: $icon-grille-logement;
  }
}

