// Overlay - Search
// ==========================================================================
.overlay-search {
	left: 0;
	width: initial;
	max-width: initial;
	background: none;

	&.is-active {
		@include addTransition();

		.form-search {
			@include addTransition();
			border-radius: $border-radius-img $border-radius-img 0 0;
			border-bottom: 1px solid rgba($c_sub, 0.10);
		}

		.overlay__body {
			@include addTransition();
			background-color: $c_white;
		}
	}

	--padding-overlay-search: 40px;
	@include mobile {
		--padding-overlay-search: 20px;
	}

	.wrapper-form {
		margin-top: var(--padding-overlay-search);
	}

	--height-form-search: 85px;

	.form-search {
		flex-grow: 1;
		display: flex;
		position: relative;
		border-radius: $border-radius-img;
		height: var(--height-form-search);
		background-color: $c_white;
		@include addTransition();

		input {
			flex-grow: 1;
			padding-right: 40px;
			@include ft($f_hx, 20px, 400, $c_txt, 1);
			border: none;
			outline: none;
			border-radius: $border-radius-img;
			text-overflow: ellipsis;
			min-width: 0;

			@include mobile {
				padding-right: 15px;
				font-size: 16px;
			}
		}
	}

	.nbHitsWrapper {
		align-items: center;
		padding: 20px var(--padding-overlay-search);
		font-size: 14px;
		display: none;

		&.is-active {
			display: flex;
		}

		.nbHits {
			margin-right: 3px;
			font-weight: bold;
		}

		.nbHitsText {
			display: none;

			&.is-display {
				display: block;
			}
		}
	}

	.btn-search {
		@include flex-center;
		padding: 0 20px 0 40px;
		font-size: 18px;

		@include mobile {
			padding: 0 10px;
		}

		&:before {
			@include icomoon($icon-search);
			color: rgba($c_sub, 0.28);
		}
	}

	.btn-reset {
		@include ft('', $s_p, 300, $c_txt, 1.25);
		display: inline-flex;
		align-items: center;
		cursor: pointer;
		margin-right: 90px;
		justify-content: center;
		padding: 10px 0;
		@include addTransition();

		@include mobile {
			font-size: 0;
			margin-right: 58px;
		}

		@include can-hover {
			color: $c_main;
			&:before {
				color: $c_main;
				transform: rotate(180deg);
			}
		}

		&:before {
			@include icomoon($icon-refresh);
			display: block;
			margin-right: 10px;
			font-size: 12px;
			color: $c_sub;
			@include addTransition();
		}
	}

	.btn-close {
		right: 40px;
		top: 50%;
		transform: translate(0, -50%);
		@include mobile {
			right: 20px;
		}
	}

	.overlay__body {
		flex-grow: 1;
		overflow: auto;
		max-height: calc(100% - var(--height-form-search));
		position: relative;
		@include addTransition();
		border-radius: 0 0 $border-radius-img $border-radius-img;
	}

	.wrapper-cards {
		padding: 0 var(--padding-overlay-search) 80px var(--padding-overlay-search);
		@include addTransition();
	}

	p[role='alert'] {
		margin-top: 25px;
	}

	.alert-instruction {
		grid-column: 1 / -1; // stretch sur tout le wrapper
		align-self: center;
		@include ft($f_txt, 65px, 700, $c_txt, 1);
		opacity: 0.15;

		@include tablet-portrait {
			font-size: 40px;
		}
		@include mobile {
			font-size: 30px;
		}
	}
}