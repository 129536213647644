.bloc-carte-interactive {
	position: relative;
	padding-top: var(--section-spacing);
	padding-bottom: var(--section-spacing);

	&:before {
		content: "";
		background-color: $c_main_light;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		bottom: 0;
		z-index: -1;
		width: 70%;

		@include tablet-portrait {
			width: 100%;
			height: 70%;
		}
	}

	.bloc-carte-interactive__inner {
		display: flex;
		align-items: flex-start;
		max-width: 1120px;
		margin-left: auto;
		margin-right: auto;
		margin-top: var(--bloc-spacing);

		@include tablet-portrait {
			flex-direction: column;
			align-items: center;
			padding-left: initial;
			margin-left: var(--border-gutter);
			margin-right: var(--border-gutter);
		}

		@include mobile {
			margin-bottom: 20px;
		}
	}

	//  Slider
	// -----------------------------------------------

	.slider-carte-interactive {
		flex-shrink: 0;
		width: 380px;
		max-width: 100%;
		margin-top: 40px;

		@include tablet-portrait {
			margin-top: 30px;
		}

		@include mobile {
			margin-top: 40px;
		}
	}

	.splide__track {
		border-radius: $border-radius-img;
	}

	.nav-arrows {
		margin-top: 30px;
		//  position: absolute;
		display: flex;
		justify-content: flex-end;
	}

	.slide-carte-interactive {
		--aspect-ratio: 380/250;
		display: flex;
		flex-direction: column;
		width: 100%;

		.image {
			&:after {
				@include absolute-stretch();
				z-index: 1;
				content: "";
				border-radius: 0;
				bottom: 0;
				top: auto;
				height: 20%;
				pointer-events: none;
				background-image: linear-gradient(to top, rgba($c_sub, 1), rgba($c_sub, 0));
			}

			figure,
			.no-photo {
				@include aspect-ratio(var(--aspect-ratio));
				background-color: #fdd69f;
				border-radius: 0;
			}

			img {
				border-radius: 0;
			}
		}

		.caption {
			padding: 40px;
			margin-top: -30px;
			background-color: $c_sub;
			flex-grow: 1;

			@include mobile {
				padding: 30px;
			}
		}
	}


	//  Carte SVG
	// -----------------------------------------------

	.svg-canvas {
		width: 100%;

		@include min-tablet-portrait {
			margin-left: -75px;
			margin-top: 40px;
		}
	}

	// pour que les titres des villes n'empeche pas le click
	#static-2 {
		pointer-events: none;
	}

	//le svg
	svg {
		display: block;
		max-width: 100%;
		height: auto;

		.zone {
			stroke-width: 1px;
			cursor: pointer;
			outline: none;

			path,
			text,
			#dot {
				@include addTransition();
			}

			@include can-hover {
				&:not(.is-active) {
					path {
						fill: #FDD69F;
					}
				}
			}

			&.is-active {
				path {
					fill: #FDD69F;
				}

				text {
					fill: $c_white;
				}
			}
		}

		.ville {
			cursor: pointer;
			outline: none;
			pointer-events: bounding-box;

			g,
			text {
				@include addTransition();
			}

			@include can-hover {
				text {
					fill: $c_main;
				}
				g {
					fill: $c_main;
				}
			}

			&.is-active {
				text {
					fill: $c_main;
				}

				g {
					fill: $c_main;
				}
			}
		}

		.poi {
			cursor: pointer;
			outline: none;
			pointer-events: bounding-box;

			g,
			text {
				@include addTransition();
			}

			@include can-hover {
				&:not(.is-active) {
					text {
						fill: $c_black;
						opacity: 1;
					}

					g {
						opacity: 1;
					}
				}
			}

			&.is-active {
				path {
					fill: $c_txt;
				}

				text {
					opacity: 1;
				}

				g {
					opacity: 1;
				}
			}

			@include can-hover {
				path {
					fill: $c_txt;
				}
			}
		}
	}
}
