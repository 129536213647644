.bloc-form {

	.frm_forms {

		form {

			.frm_form_fields {

				.frm_rootline_group {
					margin: 20px 0 30px 0;
					ul.frm_page_bar {
						list-style-type: none;
						margin: 0 0 5px 0;
						padding: 0;
						width: 100%;
						float: left;
						display: flex;
						flex-wrap: wrap;
						box-sizing: border-box;
						li {
							display: inline-block;
							-ms-flex: 1;
							flex: 1;
							&.frm_current_page{
								input{
									background: #1A1A1A;
									color: $c_white;
									@include addTransition();
									&:hover, &:focus{
										body:not(.no-hover) & {
											background: #F1F1F1;
											color: $c_sub;
										}
									}
								}
							}
							&:first-child{
								input{
									border-top-left-radius: 13px;
									border-bottom-left-radius: 13px;
								}
							}
							&:last-child{
								input{
									border-top-right-radius: 13px;
									border-bottom-right-radius: 13px;
								}
							}
							input {
								padding: 0;
								border: none;
								background: #F1F1F1;
								box-shadow: inset 0 2px 4px rgba($c_sub,0.15);
								@include addTransition();
								&.frm_page_back{
									background: #1A1A1A;
									color: $c_white;
								}
								&:hover, &:focus{
									body:not(.no-hover) & {
										cursor: pointer;
										border-color: $c_sub;
										background: darken(#F1F1F1, 10%);
										color: $c_sub;
									}
								}
							}
						}
					}

					.frm_forms .frm_page_bar input,
					.frm_forms .frm_page_bar input:disabled {
						transition: background-color 0.1s ease;
						color: $c_white;
						background-color: $c_sub;
						font-size: 18px;
						border-width: 2px;
						border-style: solid;
						border-color: $c_sub;
					}

					.frm_forms .frm_page_bar input:focus {
						outline: none;
					}

					.frm_forms .frm_progress_line input.frm_page_back {
						background-color: $c_sub;
					}

					.frm_forms .frm_page_bar .frm_current_page input[type="button"] {
						background-color: $c_sub;
						border-color: $c_sub;
					}

					.frm_rootline_single {
						text-align: center;
						margin: 0;
						padding: 0;
					}

					.frm_current_page .frm_rootline_title {
						color: $c_sub;
					}

					.frm_rootline_title,
					.frm_pages_complete,
					.frm_percent_complete {
						font-size: 14px;
						padding: 4px 0;
					}

					.frm_pages_complete {
						float: right;
					}

					.frm_percent_complete {
						float: left;
					}

					.frm_forms .frm_progress_line input,
					.frm_forms .frm_progress_line input:disabled {
						width: 100%;
						border: none;
						border-top: 1px solid $c_sub;
						border-bottom: 1px solid $c_sub;
						box-shadow: inset 0 10px 20px -15px #aaa;
						margin: 5px 0;
						padding: 6px 0;
						border-radius: 0;
						font-size: 0;
						line-height: 15px;
					}

					.frm_forms .frm_progress_line.frm_show_lines input {
						border-left: 1px solid $c_sub;
						border-right: 1px solid $c_sub;
					}

					.frm_progress_line .frm_rootline_single {
						display: flex;
						flex-direction: column;
						justify-content: flex-end;
					}

					.frm_forms .frm_progress_line li:first-of-type input {
						border-left: 1px solid $c_sub;
					}

					.frm_forms .frm_progress_line li:last-of-type input {
						border-right: 1px solid $c_sub;
					}

					.frm_forms .frm_progress_line li:last-of-type input.frm_page_skip {
						border-right: 1px solid $c_sub;
					}

					.frm_forms .frm_progress_line .frm_current_page input[type="button"] {
						border-left: 1px solid $c_sub;
					}

					.frm_forms .frm_progress_line.frm_show_lines .frm_current_page input[type="button"] {
						border-right: 1px solid $c_sub;
					}

					.frm_forms .frm_progress_line input.frm_page_back {
						border-color: $c_sub;
					}

					.frm_forms .frm_progress_line.frm_show_lines input.frm_page_back {
						border-left-color: $c_sub;
						border-right-color: $c_sub;
					}

					/* Start RTL */
					.frm_rtl.frm_forms .frm_progress_line li:first-of-type input {
						border-right: 1px solid $c_sub;
					}

					.frm_rtl.frm_forms .frm_progress_line li:last-of-type input {
						border-left: 1px solid $c_sub;
					}

					.frm_rtl.frm_forms .frm_progress_line li:last-of-type input.frm_page_skip {
						border-left: 1px solid $c_sub;
						border-right: none;
					}

					.frm_rtl.frm_forms .frm_progress_line .frm_current_page input[type="button"] {
						border-right: 1px solid $c_sub;
						border-left: none;
					}

					.frm_rtl.frm_forms .frm_progress_line.frm_show_lines .frm_current_page input[type="button"] {
						border-left: 1px solid $c_sub;
						border-right: none;
					}

					/* End RTL */

					.frm_rootline.frm_show_lines:before {
						border-top-width: 5px;
						border-top-style: solid;
						border-top-color: $c_sub;
						content: "";
						margin: 0 auto;
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						width: 100%;
						z-index: -1;
					}

					.frm_rootline.frm_show_lines {
						position: relative;
						z-index: 1;
					}

					.frm_rootline.frm_show_lines span {
						display: block;
					}

					.frm_forms .frm_rootline input {
						width: 100%;
						height: auto;
					}

					.frm_forms .frm_rootline input:focus {
						border-color: $c_sub;
					}

					.frm_forms .frm_rootline .frm_current_page input[type="button"] {
						border-color: $c_sub;
						background-color: $c_sub;
						background-color: $c_sub;
						color: $c_white;
					}

					.frm_forms .frm_progress_line input,
					.frm_forms .frm_progress_line input:disabled,
					.frm_forms .frm_progress_line .frm_current_page input[type="button"],
					.frm_forms .frm_rootline.frm_no_numbers input,
					.frm_forms .frm_rootline.frm_no_numbers .frm_current_page input[type="button"] {
						color: transparent !important;
					}

					@include mobile {
						.frm_progress span.frm_rootline_title,
						.frm_rootline.frm_rootline_10 span.frm_rootline_title,
						.frm_rootline.frm_rootline_9 span.frm_rootline_title,
						.frm_rootline.frm_rootline_8 span.frm_rootline_title,
						.frm_rootline.frm_rootline_7 span.frm_rootline_title,
						.frm_rootline.frm_rootline_6 span.frm_rootline_title,
						.frm_rootline.frm_rootline_5 span.frm_rootline_title {
							display: none;
						}

						.frm_rootline.frm_rootline_4 span.frm_rootline_title,
						.frm_rootline.frm_rootline_3 span.frm_rootline_title {
							display: none;
						}
					}
				}
			}
		}
	}
}