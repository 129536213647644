.strate-footer-mentions {
  background-color: $c_sub;
  @include tablet-portrait{
    padding-bottom: 70px;
  }

  > * {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 40px 0;
    border-top: 1px solid $c_white;
    min-height: 100px;

    @include mobile {
      justify-content: center;
      text-align: center;
      border-top: 1px solid rgba($c_white, 0.10);
    }
  }

  span {
    @include ft($f_txt, $s_link, 400, $c_white, 1.71);

  }

  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;

    @include mobile {
      flex-direction: column;
      margin-top: 15px;
      justify-content: center;
    }

    li {
      flex-shrink: 0;
      height: 100%;

      a {
        @include ft($f_txt, $s_link, 500, $c_white, 1.71);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding-right: 30px;
        
        @include tablet-portrait {
          padding-right: 18px;
        }

        @include can-hover {
          color: $c_main;
        }

        @include mobile {
          padding-right: 0;
          margin-bottom: 15px;
        }
      }

      &:last-of-type {
        a {
          padding-right: 0;
        }
      }
    }
  }
}