.strate-footer-social {
  width: auto;

  @include mobile {
    width: 100%;
    overflow: hidden;
  }

  .text-wrapper {
    margin-bottom: 50px;
    @include mobile {
      margin-bottom: 20px;
    }
  }

  .social-links {
    margin-bottom: 20px;

    a {
      // Lorsqu'on a plus de 5 liens sociaux, on ajoute au margin-top aux éléments qui vont aller à la ligne pour éviter d'avoir tout collé.
      // Le numéro 6 est à changer selon vos besoins
      @include mobile {
        &:nth-child(n+6) {
          margin-top: 5px;
        }
      }
      @include mobile-small {
        &:nth-child(n+5) {
          margin-top: 5px;
        }
      }
    }
  }

  .nav-buttons {
    position: absolute;
    bottom: 0;
    right: 0;
    height: auto;

    @include mobile {
      position: static;
      margin-bottom: 20px;
    }
  }

  .item-instagram {
    position: relative;
    width: 240px;
    height: 240px;
    border-radius: 50px;
    margin-right: var(--small-margin);

    &:nth-child(even) {
      margin-top: var(--small-margin);
    }

    @include tablet-portrait {
      width: 160px;
      height: 160px;
    }

    &:before {
      @include icomoon($icon-instagram);
      display: block;
      font-size: 20px;
      color: $c_white;
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 1;
      @include addTransition();
    }

    img {
      border-radius: $border-radius-img;
    }

    @include can-hover {
      &:before {
        color: $c_main;
        transform: scale(1.1);
      }
    }
  }
}
