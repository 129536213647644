// ==============================================

//        MIXINS / FUNCTIONS INDEX

// ==============================================

@import "mixins/colors";
@import "mixins/layout";
@import "mixins/typography";
@import "mixins/quantity-queries";
@import "mixins/others";
@import "mixins/debug";


//  Mixins theme specifics
// -----------------------------------------------


// Card à l'Horizontale
// ==========================================================================
@mixin card-horizontale() {
  --border-radius: 8px;
  width: 100%;
  min-height: 115px;
  display: flex;
  align-items: center;
  padding: 10px 20px 10px 10px;
  @include addTransition();

  @include mobile {
    min-height: 90px;
    padding: 0;
  }

  .title-card {
    font-size: 18px;
    line-height: 20px;
  }

  .image {
    --aspect-ratio: 1/1;
    position: relative;
    width: 115px;
    height: 115px;
    margin-right: 20px;
    margin-bottom: 0;
    flex-shrink: 0;
    border-radius: var(--border-radius);
    overflow: hidden;

    @include mobile {
      width: 115px;
      height: 115px;
    }

    figure, .no-photo {
      border-radius: var(--border-radius);

      &:before, &:after {
        content: none;
      }
    }

    img {
      border-radius: var(--border-radius);
    }

    &:after {
      width: 100%;
      border-radius: var(--border-radius);
      height: 100%;
    }
  }
}


@mixin shadow() {
  box-shadow: 0 10px 40px rgba($c_sub, 0.10);
}

@mixin cardHoverActive() {
  @include addTransition();
  .image {
    --aspect-ratio: 280/380;

    &:before {
      @include addTransition();
    }

    &:after {
      @include absolute-stretch();
      z-index: 1;
      content: "";
      height: 40%;
      bottom: 0;
      top: auto;
      border-radius: $border-radius-img;
      background-image: linear-gradient(to top, rgba($c_sub, 1), rgba($c_sub, 0));
      opacity: 0.5;
      pointer-events: none;
      @include addTransition();
    }
  }

  .btn-decouvrir {
    @include addTransition();
    opacity: 1;
  }

  .title-card {
    font-size: 30px;

    @include mobile {
      font-size: 22px;
    }
  }
}
