select {
  @include reset-appearance;
  @include ft($f_txt, 14px, 400, $c_txt, 16px);
  background: none;
  padding: 10px 20px;
  display: block;
  box-sizing: border-box;
}

.selectric-wrapper {
  .selectric {
    border: 1px solid rgba($c_sub, 0.10);
    background: none;
    border-radius: 6px;
    @include addTransition();

    &:hover {
      body:not(.no-hover) & {
        border-color: $c_sub;
      }
    }

    .label {
      @include ft($f_txt, 14px, 400, $c_txt, 16px);
      box-sizing: border-box;
      display: block;
      height: auto;
      margin: 0;
      padding: 20px 30px;

      &:after {
        @include icomoon($icon-arrow-down-alt);
        font-size: 10px;
        line-height: 12px;
        margin-left: 52px;
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
      }

      select {
        text-overflow: ellipsis;
      }
    }

    .button {
      display: none;
    }
  }

  .selectric-items {
    width: 100% !important;
    border: none;
    background: $c_white;
    box-shadow: 0 5px 20px rgba($c_sub, 0.15);

    .selectric-scroll {
      ul {
        padding: 0;

        li {
          @include ft($f_txt, 14px, 400, $c_txt, 25px);
          display: block;
          padding: 8px 15px;
          margin-bottom: 0;
          @include addTransition();

          &:before {
            display: none;
          }

          &:hover {
            background: #F5F5F5;
          }

          &.selected {
            background: #EEE;
          }
        }
      }
    }
  }
}
