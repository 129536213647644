.bloc-relation-liste {

  .card {
    @include min-mobile {
      margin-bottom: 20px;
    }

    @include mobile {
      @include card-horizontale();
    }
  }

  .btn {
    text-align: center;
  }

  [data-view-more-trigger] {
    display: none;
  }

  .view-more-active {
    &.is-expanded {
      [data-view-more-content] {
        max-height: none;

        &:before {
          opacity: 0;
          visibility: hidden;
        }
      }

      [data-view-more-trigger] {
        display: none;

        &:before {
          //  @include icomoon($icon-minus);
          //  transform: rotate(180deg);
        }
      }
    }

    [data-view-more-content] {
      position: relative;
      max-height: 750px;
      overflow-y: hidden;
      @include addTransition();

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        display: block;
        width: 100%;
        height: 30%;
        background: linear-gradient(to top, rgba($c_white, 1), rgba($c_white, 0));
        opacity: 1;
      }
    }

    [data-view-more-trigger] {
      display: flex;
      margin: 0 auto;
    }
  }

}
