.bloc-infos-cles {
  .container-elements {
    // Déclaration des variables contenant la largeur du gap entre les éléments
    --col-gap: 40px;
    --row-gap: 30px;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: calc(var(--col-gap) * -1 / 2);
    margin-right: calc(var(--col-gap) * -1 / 2);
    margin-top: calc(var(--row-gap) * -1);

    @include desktop {
      --col-gap: 20px;
    }

    @include tablet-portrait {
      margin-left: 0;
      margin-right: 0;
      justify-content: start;
      //  flex-direction: column;
    }

    @include mobile_small {
      flex-direction: column;
    }

    > * {
      margin-left: calc(var(--col-gap) / 2);
      margin-right: calc(var(--col-gap) / 2);
      margin-top: var(--row-gap);
      width: calc(33.33% - var(--col-gap));
      @include tablet-portrait {
        width: 50%;
        margin-left: 0;
        margin-right: 0;
      }
      @include mobile_small {
        width: 100%;
      }
    }
  }

  .inner-container {
    max-width: 270px;
    position: relative;
    display: flex;
    align-items: center;


    [class^="icon-"], [class*=" icon-"] {
      display: block;
      font-size: 80px;
      color: $c_sub;
      margin-left: 20px;
      margin-top: -5px;

      @include mobile {
        font-size: 50px;
      }

      &:after {
        content: "";
        background-color: rgba($c_main, 0.20);
        width: 80px;
        height: 80px;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 50%;
        z-index: -1;
        @include addTransition();

        @include mobile {
          width: 50px;
          height: 50px;
        }
      }
    }

    .caption {
      margin-left: 28px;

      @include tablet-portrait {
        margin-left: 15px;
      }

      .title {
        @include ft($f_hx, 22px, bold, $c_txt, 1);
        display: block;

        @include tablet-portrait {
          font-size: 20px;
        }

        @include mobile {
          font-size: 16px;
        }
      }
    }
  }
}
