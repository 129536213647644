.bloc-trombinoscope{
	&__list{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 20px;
		@include mobile{
			gap: 20px 10px;
		}
	}
	&__element{
		width: calc(33% - 20px);
		height: auto;
		display: flex;
		flex-flow: column;
		text-align: left;
		gap: 10px;
		@include can-hover{
			.titre{
				color: $c_main;
			}
		}
		@include tablet-small{
			width: calc(50% - 10px);
		}

		.credits{
			display: none;
		}

		.image{
			&.--picto{
				aspect-ratio: 1/1;
				width: 100%;
				background-color: $c_main_light;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 80px;
				color: $c_txt;
				border-radius: 15px;
			}
			&.--img{

			}
		}

		figure{
			background-color: transparent;
			border-radius: 15px;
			overflow: hidden;
			figcaption{
				display: none;
			}
		}

		.titre{
			font-family: $f_hx;
			color: $c_txt;
			font-size: 24px;
			line-height: 1.12;
			font-weight: 700;
			@include addTransition();
			@include mobile{
				font-size: 18px;
				line-height: 1;
			}
		}

		.surtitre{
			color: $c_main;
			font-size: 12px;
			line-height: 1.12;
			font-weight: 600;
			text-transform: uppercase;
		}
	}

	*.--overlay-only{
		display: none;
	}
}

.overlay-trombinoscope{
	.bloc-trombinoscope__element{
		width: 100%;
		gap: 30px;
		@include can-hover{
			.titre{
				color: $c_txt;
			}
		}

		.titre{
			order: 1;
			font-weight: 300;
			font-size: 40px;
			line-height: 1;
			@include mobile{
				font-size: 30px;
			}
			+.surtitre{
				margin-top: -20px;
			}
		}
		.surtitre{
			position: relative;
			order: 2;
			padding-bottom: 20px;
			&:before{
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				height: 3px;
				width: 100px;
				background-color: $c_main;
			}
		}
		.image{
			order: 3;
		}

		.btn{
			margin-right: auto;
		}

		*{
			order: 4;
		}
	}
	.scroller{
		*.--overlay-only{
			display: block;
		}
	}
}